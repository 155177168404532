import React from 'react';
import { NavLink } from 'react-router-dom';
import auth from './auth';

function Sidebar() {
  const storeAuth = auth.get();
  if (!storeAuth) {
    return;
  }
  return (
    <div
      style={{
        display: 'flex',
        position: 'fixed',
        top: '10em',
        left: '3em'
      }}
    >
      <ol
        style={{
          padding: 0,
          margin: 0
        }}
      >
        <li>
          <a href={`https://${storeAuth.storeAlias}.mysapo.net/admin`} target="_blank" rel="noreferrer">
            Quản trị Website
          </a>
        </li>
        <li>
          <NavLink to="/dashboard" activeStyle={{ fontWeight: 'bold' }}>
            Báo cáo đồng bộ
          </NavLink>
        </li>
        <li>
          <NavLink to="/products" activeStyle={{ fontWeight: 'bold' }}>
            Sản phẩm
          </NavLink>
        </li>
        <li>
          <NavLink to="/orders" activeStyle={{ fontWeight: 'bold' }}>
            Đơn hàng
          </NavLink>
        </li>
        <li>
          <NavLink to="/config" activeStyle={{ fontWeight: 'bold' }}>
            Cấu hình
          </NavLink>
        </li>
        <li>
          <NavLink to="/errors" activeStyle={{ fontWeight: 'bold' }}>
            Danh sách lỗi
          </NavLink>
        </li>
      </ol>
    </div>
  );
}

export default function (props) {
  const embbed = window.self !== window.top;
  return (
    <div id="sw-wapper">
      {!embbed && DEBUG.enable ? (
        <React.Fragment>
          <Sidebar />
          <div style={{ marginLeft: '10em' }}>{props.message ? props.message : props.children}</div>
        </React.Fragment>
      ) : (
        <div>{props.message ? props.message : props.children}</div>
      )}
    </div>
  );
}
