import { faCheck, faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import CaretDownIcon from '../../../assets/img/icons/caret_down_icon.svg';
import ChevronDuoRight from '../../../assets/img/icons/chevron_duo_right.svg';
import LinkIcon from '../../../assets/img/icons/link_icon.svg';
import DotConnect from '../../../assets/img/icons/dot-connect.svg';
import DotStopped from '../../../assets/img/icons/dot-stopped.svg';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import UnlinkIcon from '../../../assets/img/icons/unlink_icon.svg';
import PowerIcon from '../../../assets/img/icons/power-icon.svg';

import SWebPagination from '../../../shared/sw-pagination/sweb-pagination';
import { IConditionOrder, itemPerPageModel } from '../../order/data';
import { navModelActionProduct } from '../data';
import './table.scss';
import SWButton from '../../../shared/sw-button/SWButton';
import { useDebouncedEffect, useOnClickOutside } from '../../../shared/util/function';
import NoData from '../../../shared/no-data/no-data';
import { IProduct } from '../../dashboard/data';
import { IItem, sortByWeb } from '../../../shared/model/base-data';
import ProductService from '../../../services/product-services/product-services';
import { formatCurrency } from '../../../shared/util/number-format';
import ReactTooltip from 'react-tooltip';
import _ from 'lodash';
import { sortByPos } from './../../../shared/model/base-data';
import Toast from 'components/Toast';
import urlUtils from '../../../core/urlUtils';

interface TableProductProps {
  condition?: IConditionOrder;
  checkCallApi: boolean;
  setCheckCallApi?: any;
}

export default function TableProducts(props: TableProductProps) {
  const alias = localStorage.getItem('appStoreAlias');

  const { condition, checkCallApi, setCheckCallApi } = props;
  const status = condition.status;
  const keyword = condition.query;
  const source = condition.source;
  const [data, setData] = useState<IProduct[]>();
  const [productDetail, setProductDetail] = useState<IProduct[]>([]);

  const ref = useRef();
  const ref2 = useRef();

  const itemPerPages = itemPerPageModel;

  const [loadingDetail, setLoadingDetail] = useState<boolean>(true);
  const [openItemPerPage, setOpenItemPerPage] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [showBulkActionEvent, setShowBulkActionEvent] = useState<boolean>(false);
  const [modalAction, setModalAction] = useState<boolean>(false);
  const [unLinkOrSync, setUnLinkOrSync] = useState<boolean>(false);

  //pagination
  const [page, setPage] = useState<number>(1);
  const [totalPage, setToltalPage] = useState<number>(0);
  const [itemPerPage, setItemPerPage] = useState<number>(20);
  const [totalElements, settotalElements] = useState<number>(0);

  const [listChecked, setListChecked] = useState<number[]>([]);
  const [synchronizedList, setSynchronizedList] = useState<number[]>([]);

  const [actionType, setActionType] = useState<IItem>({ key: '', value: '' });

  //close openItemPerPage
  useOnClickOutside(ref, () => setOpenItemPerPage(false), 'item-per-page');
  useOnClickOutside(ref2, () => setShowBulkActionEvent(false), 'box-action-btn');

  //setDefault
  const setDefault = () => {
    setProductDetail([]);
    setListChecked([]);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [page]);

  useEffect(() => {
    setPage(1);
  }, [status, source, itemPerPage]);

  useEffect(() => {
    ProductService.getListProduct(
      source,
      itemPerPage,
      page - 1,
      source === 'web' ? sortByWeb : sortByPos,
      keyword,
      status
    ).subscribe(data => {
      setToltalPage(data.total_pages);
      settotalElements(data.total_elements);
      setData(data.content);
      setLoading(false);
      setDefault();
    });
  }, [itemPerPage, page, status, source]);

  useDebouncedEffect(
    () => {
      ProductService.getListProduct(
        source,
        itemPerPage,
        page - 1,
        source === 'web' ? sortByWeb : sortByPos,
        keyword,
        status
      ).subscribe(data => {
        setToltalPage(data.total_pages);
        settotalElements(data.total_elements);
        setData(data.content);
        setLoading(false);
        setDefault();
        setPage(1);
        setCheckCallApi(false);
      });
    },
    300,
    [keyword]
  );

  const getOrderDetailLinked = (id: number) => {
    ProductService.getProductDetail(id).subscribe(data => {
      setProductDetail([...productDetail, data]);
      setLoadingDetail(false);
    });
  };

  const onClickShowDetail = (id: number, status: string) => {
    if (productDetail.find(product => product.id === id)) {
      const listOrder = [...productDetail];
      listOrder.splice(productDetail.indexOf(productDetail.find(order => order.id === id)), 1);
      setProductDetail(listOrder);
    } else {
      if (status === 'connected' || status === 'stopped') {
        getOrderDetailLinked(id);
      }
    }
  };

  const isSelected = (id: number) => listChecked.indexOf(id) !== -1;

  const handleClickChoosePage = (num: number) => {
    setItemPerPage(num);
    setOpenItemPerPage(false);
  };

  const onClickCheckBoxAll = (e: any) => {
    const li: number[] = [];
    if (e.target.checked) {
      data.forEach(l => {
        li.push(l.id);
      });
    }
    setListChecked(li);
  };

  const handleRowClick = (event: any, id: number) => {
    const selectedIndex = listChecked.indexOf(id);
    let newSelected: number[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(listChecked, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(listChecked.slice(1));
    } else if (selectedIndex === listChecked.length - 1) {
      newSelected = newSelected.concat(listChecked.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(listChecked.slice(0, selectedIndex), listChecked.slice(selectedIndex + 1));
    }
    setListChecked(newSelected);
  };

  const switchStatus = (status: string, title?: string, bol?: boolean, product?: any) => {
    switch (status) {
      case 'connected':
        if (bol) {
          return unLinkOrSync ? (
            <div>
              <span className="status-connected">
                <LinkIcon className="mb-2" /> Đang đồng bộ lại...
              </span>
            </div>
          ) : (
            <div>
              <span className="status-connected">
                <LinkIcon className="mb-2" /> Đang hủy đồng bộ...
              </span>
            </div>
          );
        } else {
          return (
            <div>
              <span className="status-connected">
                <LinkIcon className="mb-2" /> Liên kết với{' '}
              </span>
              {title}
            </div>
          );
        }

      case 'stopped':
        if (bol) {
          return (
            <div>
              <span className="status-connected">
                <LinkIcon className="mb-2" /> Đang bật đồng bộ...
              </span>
            </div>
          );
        } else {
          return (
            <div>
              <span className="status-connected">
                <LinkIcon className="mb-2" /> Liên kết với{' '}
              </span>
              {title}
            </div>
          );
        }

      case 'unlink':
        if (bol) {
          return (
            <div>
              <span className="status-connected">
                <LinkIcon className="mb-2" /> Đang liên kết lại...
              </span>
            </div>
          );
        } else {
          return (
            <div>
              <span className="status-error">
                <UnlinkIcon className="mb-2" /> Chưa được liên kết
              </span>
            </div>
          );
        }

      case 'error':
        if (bol) {
          return (
            <div>
              <span className="status-connected">
                <LinkIcon className="mb-2" /> Đang đồng bộ lại...
              </span>
            </div>
          );
        } else {
          return (
            <div>
              <span className="status-error" data-tip data-for={`error-${product.id}`}>
                <UnlinkIcon className="mb-2" /> Liên kết thất bại do lỗi
              </span>
              {product.error ? (
                <ReactTooltip
                  id={`error-${product.id}`}
                  className="tooltip-custom"
                  border
                  borderColor="#E8EAEB"
                  textColor="#FFF"
                  backgroundColor="#182537"
                  effect="solid"
                  delayShow={200}
                >
                  <span>{product.error}</span>
                </ReactTooltip>
              ) : (
                ''
              )}
            </div>
          );
        }

      default:
        return;
    }
  };

  const switchNoti = (
    type: 're_connect' | 'stop_connect' | 're_sync' | 'remove_connect' | string,
    bol?: boolean,
    isError?: boolean
  ) => {
    switch (type) {
      case 're_connect':
        if (isError) {
          return Toast.error('Bật đồng bộ sản phẩm thất bại');
        } else {
          return Toast.info('Bật đồng bộ sản phẩm thành công');
        }
      case 'stop_connect':
        if (isError) {
          return Toast.error('Ngắt đồng bộ sản phẩm thất bại');
        } else {
          return Toast.info('Ngắt đồng bộ sản phẩm thành công');
        }
      case 're_sync':
        if (bol) {
          if (isError) {
            return Toast.error('Đồng bộ dữ liệu thất bại');
          } else {
            return Toast.info('Đồng bộ dữ liệu thành công');
          }
        } else {
          if (isError) {
            return Toast.error('Liên kết sản phẩm thất bại');
          } else {
            return Toast.info('Liên kết sản phẩm thành công');
          }
        }
      case 'remove_connect':
        if (isError) {
          return Toast.error('Hủy liên kết sản phẩm thất bại');
        } else {
          return Toast.info('Hủy liên kết sản phẩm thành công');
        }
      default:
        break;
    }
  };

  const handleChangeStatus = (
    id: number[],
    type: 're_connect' | 'stop_connect' | 're_sync' | 'remove_connect' | string,
    time?: number,
    check?: boolean,
    statusCheck?: string
  ) => {
    if (id.length === 1) {
      setSynchronizedList([...synchronizedList, id[0]]);
    } else {
      setSynchronizedList(id);
      setProductDetail([]);
    }
    if (type === 'remove_connect') {
      const listOrder = [...productDetail];
      id.slice()
        .reverse()
        .forEach(i => {
          if (listOrder.find(product => product.id === i)) {
            listOrder.splice(productDetail.indexOf(productDetail.find(order => order.id === i)), 1);
          }
        });
      setProductDetail(listOrder);
    }

    ProductService.changeStatus(id, type).subscribe(re => {
      if (re.status > 200 && re.status < 300) {
        if (type === 're_sync') {
          setTimeout(
            () => {
              ProductService.getListProduct(
                source,
                itemPerPage,
                page - 1,
                source === 'web' ? sortByWeb : sortByPos,
                keyword,
                status
              ).subscribe(data => {
                setToltalPage(data.total_pages);
                settotalElements(data.total_elements);
                setData(data.content);

                if (id.length === 1) {
                  const listOrder = [...synchronizedList];
                  listOrder.splice(synchronizedList.indexOf(synchronizedList.find(order => order === id[0])), 1);
                  setSynchronizedList(listOrder);
                  const changeStatusError = data.content.find(d => d.id === id[0]);
                  const isError =
                    changeStatusError &&
                    changeStatusError.status !== 'connected' &&
                    changeStatusError.status === statusCheck;
                  switchNoti(type, check, isError);

                  if (productDetail.find(detail => detail.id === id[0])) {
                    ProductService.getProductDetail(id[0]).subscribe(dataDetail => {
                      const listOrderDetail = _.cloneDeep(productDetail);
                      listOrderDetail.splice(
                        productDetail.indexOf(productDetail.find(detail => detail.id === id[0])),
                        1,
                        dataDetail
                      );
                      setProductDetail(listOrderDetail);
                    });
                  }
                } else {
                  setSynchronizedList([]);
                  switchNoti(type, check, false);
                }
                setLoading(false);
              });
            },
            time ? time : 2000
          );
        } else {
          ProductService.getListProduct(
            source,
            itemPerPage,
            page - 1,
            source === 'web' ? sortByWeb : sortByPos,
            keyword,
            status
          ).subscribe(data => {
            setToltalPage(data.total_pages);
            settotalElements(data.total_elements);
            setData(data.content);
            setLoading(false);

            if (id.length === 1) {
              const listOrder = [...synchronizedList];
              listOrder.splice(synchronizedList.indexOf(synchronizedList.find(order => order === id[0])), 1);
              setSynchronizedList(listOrder);
              const changeStatusError = data.content.find(d => d.id === id[0]);
              const isError =
                changeStatusError &&
                changeStatusError.status !== 'connected' &&
                changeStatusError.status === statusCheck;
              switchNoti(type, check, isError);
            } else {
              setSynchronizedList([]);
              switchNoti(type, check, false);
            }
          });
        }
      }
    });
  };

  const openModalAction = (item: IItem) => {
    setActionType(item);
    setModalAction(true);
  };
  const closeModalAction = () => {
    // setShowBulkActionEvent(false);
    setTimeout(() => {
      setActionType({ key: '', value: '' });
    }, 500);
    setModalAction(false);
  };

  const onSubmitAction = (
    type: 're_connect' | 'stop_connect' | 're_sync' | 'remove_connect' | string,
    id: number[]
  ) => {
    setUnLinkOrSync(true);
    setListChecked([]);
    const time = id.length * 2000;
    if (type === 'remove_connect') {
      setListChecked([]);
    }
    if (type === 're_sync') {
      handleChangeStatus(id, type, time > 15 ? time / 1.6 : time, true);
    } else {
      handleChangeStatus(id, type, time > 15 ? time / 1.6 : time);
    }
    closeModalAction();
  };

  const switchAction = (product: IProduct, bol: boolean) => {
    switch (product.status) {
      case 'connected':
        return (
          <div className="action-status">
            <button
              className="action-error-link action-btn"
              data-tip
              disabled={bol}
              data-for={`btn-status-unlink-${product.id}`}
              onClick={e => {
                handleChangeStatus([product.id], 'remove_connect', null, null, product.status);
                setUnLinkOrSync(false);
                e.stopPropagation();
              }}
            >
              <UnlinkIcon />
            </button>
            {bol ? null : (
              <ReactTooltip
                id={`btn-status-unlink-${product.id}`}
                className="tooltip-custom"
                border
                borderColor="#E8EAEB"
                textColor="#FFF"
                backgroundColor="#182537"
                effect="solid"
                delayShow={200}
              >
                <span>Hủy liên kết</span>
              </ReactTooltip>
            )}
            <button
              className="action-error-btn action-btn"
              data-tip
              disabled={bol}
              data-for={`btn-status-off-sync-${product.id}`}
              onClick={e => {
                handleChangeStatus([product.id], 'stop_connect', null, null, product.status);
                e.stopPropagation();
              }}
            >
              <PowerIcon />
            </button>
            {bol ? null : (
              <ReactTooltip
                id={`btn-status-off-sync-${product.id}`}
                className="tooltip-custom"
                border
                borderColor="#E8EAEB"
                textColor="#FFF"
                backgroundColor="#182537"
                effect="solid"
                delayShow={200}
              >
                <span>Ngắt đồng bộ</span>
              </ReactTooltip>
            )}
          </div>
        );
      case 'unlink':
        return (
          <div className="action-status">
            <button
              disabled={bol}
              className="action-connect-link action-btn"
              data-tip
              data-for={`btn-status-connect-${product.id}`}
              onClick={e => {
                handleChangeStatus([product.id], 're_sync', null, null, product.status);
                e.stopPropagation();
              }}
            >
              <LinkIcon />
            </button>
            {bol ? null : (
              <ReactTooltip
                id={`btn-status-connect-${product.id}`}
                className="tooltip-custom"
                border
                borderColor="#E8EAEB"
                textColor="#FFF"
                backgroundColor="#182537"
                effect="solid"
                delayShow={200}
              >
                <span>Liên kết</span>
              </ReactTooltip>
            )}
          </div>
        );
      case 'stopped':
        return (
          <div className="action-status">
            <button
              disabled={bol}
              className="action-error-link action-btn"
              data-tip
              data-for={`btn-status-unlink-${product.id}`}
              onClick={e => {
                handleChangeStatus([product.id], 'remove_connect', null, null, product.status);
                e.stopPropagation();
              }}
            >
              <UnlinkIcon />
            </button>
            {bol ? null : (
              <ReactTooltip
                id={`btn-status-unlink-${product.id}`}
                className="tooltip-custom"
                border
                borderColor="#E8EAEB"
                textColor="#FFF"
                backgroundColor="#182537"
                effect="solid"
                delayShow={200}
              >
                <span>Hủy liên kết</span>
              </ReactTooltip>
            )}
            <button
              disabled={bol}
              className="action-connect-btn action-btn"
              data-tip
              data-for={`btn-status-off-sync-${product.id}`}
              onClick={e => {
                handleChangeStatus([product.id], 're_connect', null, null, product.status);
                e.stopPropagation();
              }}
            >
              <PowerIcon />
            </button>
            {bol ? null : (
              <ReactTooltip
                id={`btn-status-off-sync-${product.id}`}
                className="tooltip-custom"
                border
                borderColor="#E8EAEB"
                textColor="#FFF"
                backgroundColor="#182537"
                effect="solid"
                delayShow={200}
              >
                <span>Bật đồng bộ</span>
              </ReactTooltip>
            )}
          </div>
        );
      case 'error':
        return (
          <div className="action-status">
            <button
              disabled={bol}
              className="action-connect-link action-btn"
              data-tip
              data-for={`btn-status-connect-${product.id}`}
              onClick={e => {
                handleChangeStatus([product.id], 're_sync', null, null, product.status);
                e.stopPropagation();
              }}
            >
              <UnlinkIcon />
            </button>
            {bol ? null : (
              <ReactTooltip
                id={`btn-status-connect-${product.id}`}
                className="tooltip-custom"
                border
                borderColor="#E8EAEB"
                textColor="#FFF"
                backgroundColor="#182537"
                effect="solid"
                delayShow={200}
              >
                <span>Liên kết</span>
              </ReactTooltip>
            )}
          </div>
        );
      default:
        break;
    }
  };

  return (
    <>
      {loading ? (
        ''
      ) : (
        <>
          {data.length ? (
            <div id="sw-table-product">
              <div id="table-order">
                <table className="orders">
                  <thead className="thead-order">
                    <tr>
                      <th></th>
                      <th className="select">
                        <div className={`next-input-wrapper checkbox ${listChecked.length > 0 ? 'on-checked' : ''}`}>
                          <label className="checkbox-button" htmlFor={`chooseCategory`}>
                            <input
                              type="checkbox"
                              name={`chooseCategory`}
                              id={`chooseCategory`}
                              onChange={onClickCheckBoxAll}
                              checked={listChecked.length === data.length}
                            ></input>
                            <span
                              className={`checkmark ${
                                listChecked.length > 0 && listChecked.length < data.length ? 'children' : ''
                              }`}
                            >
                              <FontAwesomeIcon icon={faCheck} size="1x" color="#08f" />
                            </span>
                          </label>
                        </div>
                        <div className={`bulk-actions ${listChecked.length > 0 ? '' : 'd-none'}`}>
                          <ul>
                            <li className="select-count">
                              <span>Đã chọn {listChecked.length} sản phẩm</span>
                            </li>
                            <li className="box-action-btn">
                              <SWButton onClick={() => setShowBulkActionEvent(() => !showBulkActionEvent)}>
                                Chọn thao tác
                                <FontAwesomeIcon className="ml-3" icon={faCaretDown} color="#212b35" size="1x" />
                              </SWButton>
                              {showBulkActionEvent ? (
                                <div className="popover-bulk-action" ref={ref2}>
                                  <span className="triangle"></span>
                                  {navModelActionProduct.map((nav, index) => (
                                    <div key={index} className="action" onClick={() => openModalAction(nav)}>
                                      {nav.value}
                                    </div>
                                  ))}
                                </div>
                              ) : (
                                ''
                              )}
                            </li>
                          </ul>
                        </div>
                      </th>
                      <th style={{ minWidth: '100px', width: '15%' }}>Mã SKU</th>
                      <th style={{ minWidth: '161px', width: '20%' }}>
                        {source === 'web' ? 'Sản phẩm trên Web' : 'Sản phẩm trên Omni'}
                      </th>
                      <th style={{ minWidth: '115px', width: '12%' }}>Phiên bản</th>
                      <th style={{ minWidth: '260px', width: '25%' }}>
                        Tình trạng liên kết với Omni {source === 'web' ? '' : 'Web'}
                      </th>
                      <th style={{ minWidth: '155px' }}>Trạng thái đồng bộ</th>
                      <th style={{ width: '105px' }}>Thao tác</th>
                    </tr>
                  </thead>
                  <tbody className="tbody-order">
                    {data.map((product, index) => {
                      const isItemSelected = isSelected(product.id);
                      const productWP: IProduct = productDetail.find(o => o.id === product.id);
                      const sync = synchronizedList.find(s => s === product.id);
                      return (
                        <Fragment key={index}>
                          <tr
                            className={`row-custom ${isItemSelected ? 'row-active' : ''} ${sync ? 'row-syncing' : ''}`}
                            onClick={sync ? null : () => onClickShowDetail(product.id, product.status)}
                          >
                            <td>
                              {product.status === 'connected' || product.status === 'stopped' ? (
                                <>
                                  <ChevronDuoRight
                                    className={`icon-show-detail ${productWP ? 'active' : ''}`}
                                    data-tip
                                    data-for={`show-detail-${product.id}`}
                                  />
                                  {sync ? (
                                    <ReactTooltip
                                      id={`show-detail-${product.id}`}
                                      className="tooltip-custom"
                                      border
                                      borderColor="#E8EAEB"
                                      textColor="#FFF"
                                      backgroundColor="#182537"
                                      effect="solid"
                                      delayShow={200}
                                    >
                                      <span>Sản phẩm đang được đồng bộ</span>
                                    </ReactTooltip>
                                  ) : (
                                    ''
                                  )}
                                </>
                              ) : (
                                ''
                              )}
                            </td>

                            <td>
                              <div className={`next-input-wrapper checkbox`} onClick={e => e.stopPropagation()}>
                                <label className="checkbox-button" htmlFor={`chooseCategory-${product.id}`}>
                                  <input
                                    type="checkbox"
                                    checked={isItemSelected}
                                    onChange={e => handleRowClick(e, product.id)}
                                    name={`chooseCategory-${product.id}`}
                                    id={`chooseCategory-${product.id}`}
                                  ></input>
                                  <span className="checkmark">
                                    <FontAwesomeIcon icon={faCheck} size="1x" color="#08f" />
                                  </span>
                                </label>
                              </div>
                            </td>
                            <td>
                              <a
                                href={source === 'web' ? product.web_url : product.pos_url}
                                rel="noopener noreferrer"
                                target="_blank"
                                className="_08F"
                                onClick={e => e.stopPropagation()}
                              >
                                {source === 'web' ? product.web_sku : product.pos_sku}
                              </a>
                            </td>
                            <td>
                              <div className="two-row">
                                {source === 'web' ? product.web_product_name : product.pos_product_name}
                              </div>
                            </td>
                            <td>{source === 'web' ? product.web_attribute_title : product.pos_attribute_title}</td>
                            <td className="status">
                              {switchStatus(
                                product.status,
                                source === 'web' ? product.pos_product_name : product.web_product_name,
                                Boolean(sync),
                                product
                              )}
                            </td>
                            <td className="switch-custom">
                              {product.status === 'connected' ? (
                                <>
                                  <div className="status-sync" data-tip data-for={`show-status-sync-${product.id}`}>
                                    <DotConnect className="mr-2" /> Bật đồng bộ
                                  </div>
                                  {sync ? null : (
                                    <ReactTooltip
                                      id={`show-status-sync-${product.id}`}
                                      className="tooltip-custom"
                                      border
                                      borderColor="#E8EAEB"
                                      textColor="#FFF"
                                      backgroundColor="#182537"
                                      effect="solid"
                                      delayShow={200}
                                    >
                                      <span>
                                        Dữ liệu sản phẩm khi cập nhật sẽ được đồng bộ khi cấu hình chung được bật
                                      </span>
                                    </ReactTooltip>
                                  )}
                                </>
                              ) : (
                                <>
                                  <div className="status-sync" data-tip data-for={`show-status-sync-${product.id}`}>
                                    <DotStopped className="mr-2" /> Ngắt đồng bộ
                                  </div>
                                  {sync ? null : (
                                    <ReactTooltip
                                      id={`show-status-sync-${product.id}`}
                                      className="tooltip-custom"
                                      border
                                      borderColor="#E8EAEB"
                                      textColor="#FFF"
                                      backgroundColor="#182537"
                                      effect="solid"
                                      delayShow={200}
                                    >
                                      <span>Dữ liệu sản phẩm khi cập nhật sẽ không được đồng bộ</span>
                                    </ReactTooltip>
                                  )}
                                </>
                              )}
                            </td>
                            <td>{switchAction(product, Boolean(sync))}</td>
                          </tr>
                          <tr>
                            <td colSpan={9} className={`row-detail ${sync ? 'row-syncing' : ''}`}>
                              {productWP ? (
                                <>
                                  {loadingDetail ? (
                                    ''
                                  ) : product.status === 'connected' || product.status === 'stopped' ? (
                                    <div className="order-detail">
                                      <table className="order">
                                        <thead>
                                          <tr>
                                            <th>Chi tiết sản phẩm</th>
                                            <th style={{ minWidth: '200px', width: '15%' }}>Mã SKU</th>
                                            <th style={{ minWidth: '250px', width: '25%' }}>Tên sản phẩm</th>
                                            <th>Phiên bản</th>
                                            <th>Giá</th>
                                            <th>Tồn kho</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          <tr>
                                            <td>Web</td>
                                            <td>
                                              <a
                                                href={productWP.web_url}
                                                rel="noopener noreferrer"
                                                target="_blank"
                                                className="_08F"
                                              >
                                                {productWP.web_detail.sku}
                                              </a>
                                            </td>
                                            <td>
                                              <div className="two-row">{productWP.web_detail.product_name}</div>
                                            </td>
                                            <td>{productWP.web_detail.variant_name}</td>
                                            <td>{formatCurrency(productWP.web_detail.price)}</td>
                                            <td>{productWP.web_detail.stock}</td>
                                          </tr>
                                          <tr>
                                            <td>Omni</td>
                                            <td>
                                              <a
                                                href={productWP.pos_url}
                                                rel="noopener noreferrer"
                                                target="_blank"
                                                className="_08F"
                                              >
                                                {productWP.pos_detail.sku}
                                              </a>
                                            </td>
                                            <td>
                                              <div className="two-row">{productWP.pos_detail.product_name}</div>
                                            </td>
                                            <td>{productWP.pos_detail.variant_name}</td>
                                            {productWP.pos_detail.taxable && !productWP.pos_detail.tax_included && productWP.pos_detail.tax_output_rate ? (
                                              <td>{formatCurrency(productWP.pos_detail.price)} (tax: {productWP.pos_detail.tax_output_rate}%)</td>
                                            ) : (
                                              <td>{formatCurrency(productWP.pos_detail.price)}</td>
                                            )}
                                            <td>{productWP.pos_detail.stock}</td>
                                          </tr>
                                          <tr>
                                            <td colSpan={6}>
                                              <div className="btn-groups">
                                                <SWButton
                                                  color="destroy"
                                                  disabled={Boolean(sync)}
                                                  onClick={() =>
                                                    handleChangeStatus(
                                                      [product.id],
                                                      'remove_connect',
                                                      null,
                                                      null,
                                                      product.status
                                                    )
                                                  }
                                                >
                                                  Hủy liên kết
                                                </SWButton>
                                                {product.status === 'connected' ? (
                                                  <SWButton
                                                    color="primary"
                                                    disabled={Boolean(sync)}
                                                    className="m-0"
                                                    onClick={() => {
                                                      handleChangeStatus(
                                                        [product.id],
                                                        're_sync',
                                                        null,
                                                        true,
                                                        product.status
                                                      );
                                                      setUnLinkOrSync(true);
                                                    }}
                                                  >
                                                    Đồng bộ dữ liệu ngay
                                                  </SWButton>
                                                ) : (
                                                  <SWButton
                                                    color="primary"
                                                    disabled={Boolean(sync)}
                                                    className="m-0"
                                                    onClick={() =>
                                                      handleChangeStatus(
                                                        [product.id],
                                                        're_connect',
                                                        null,
                                                        null,
                                                        product.status
                                                      )
                                                    }
                                                  >
                                                    Bật đồng bộ
                                                  </SWButton>
                                                )}
                                              </div>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </div>
                                  ) : (
                                    ''
                                  )}
                                </>
                              ) : (
                                ''
                              )}
                            </td>
                          </tr>
                        </Fragment>
                      );
                    })}
                  </tbody>
                </table>
              </div>
              <div className="pagination">
                <div>
                  Từ {page > 1 ? (page - 1) * itemPerPage : 1} đến{' '}
                  {page * itemPerPage < totalElements ? page * itemPerPage : totalElements} trên tổng {totalElements}
                </div>
                <div className="d-flex align-items-center">
                  <div className="item-per-page">
                    <SWButton onClick={() => setOpenItemPerPage(() => !openItemPerPage)}>
                      {itemPerPage}
                      <CaretDownIcon className="icon-drop" />
                    </SWButton>
                    {openItemPerPage ? (
                      <div className="popover-filter filter-radio" ref={ref}>
                        {itemPerPages.map((itemPage, index) => (
                          <div
                            key={index}
                            onClick={e => handleClickChoosePage(itemPage)}
                            className={`${itemPerPage === itemPage ? 'active-item-per-page' : ''}`}
                          >
                            {itemPage}
                          </div>
                        ))}
                      </div>
                    ) : (
                      ''
                    )}
                  </div>
                  kết quả
                </div>
                <div>
                  <SWebPagination page={page} setPage={setPage} totalPage={totalPage} />
                </div>
              </div>
            </div>
          ) : status === '' && keyword === '' && !checkCallApi ? (
            <NoData
              title="Cửa hàng chưa có sản phẩm nào để hiển thị"
              content={
                source === 'pos' ? (
                  <div>
                    Vui lòng tạo sản phẩm trên Omni để liên kết và quản lý đồng bộ với Web{' '}
                    <a
                      href={`${urlUtils.resolvePosDomain(alias)}/admin/products`}
                      rel="noopener noreferrer"
                      target="_blank"
                      className="_08F"
                      onClick={e => e.stopPropagation()}
                    >
                      tại đây
                    </a>
                  </div>
                ) : (
                  <div>
                    Vui lòng tạo sản phẩm trên Web để liên kết và quản lý đồng bộ với Omni{' '}
                    <a
                      href={`https://${alias}.mysapo.net/admin/products`}
                      rel="noopener noreferrer"
                      target="_blank"
                      className="_08F"
                      onClick={e => e.stopPropagation()}
                    >
                      tại đây
                    </a>
                  </div>
                )
              }
            />
          ) : (
            <NoData
              title="Không tìm thấy sản phẩm phù hợp với điều kiện tìm kiếm"
              content="Thử thay đổi điều kiện lọc hoặc từ khóa tìm kiếm"
            />
          )}
        </>
      )}
      <Modal isOpen={modalAction} className="modal-confirm" id="modal-action" autoFocus={false}>
        <ModalHeader toggle={closeModalAction} className="modal--header">
          {actionType.value} sản phẩm?
        </ModalHeader>
        <ModalBody className="modal--body">
          Thao tác này sẽ {actionType.value.toLowerCase()} sản phẩm bạn đã chọn
        </ModalBody>
        <ModalFooter className="modal--footer">
          <SWButton onClick={closeModalAction}>Hủy</SWButton>
          <SWButton color="primary" onClick={() => onSubmitAction(actionType.key, listChecked)}>
            Đồng ý
          </SWButton>
        </ModalFooter>
      </Modal>
    </>
  );
}
