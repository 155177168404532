import React, { useEffect, useState } from 'react';
import './data-sync.scss';

export function Step4(props: any) {
  const [customerIdentifier, setCustomerIdentifier] = useState<string>('email');

  useEffect(() => {
    if (typeof props.customerIdentifier !== 'undefined') {
      setCustomerIdentifier(props.customerIdentifier);
    }
  }, [props]);

  const handleClick = (check: string) => {
    props.setCustomerIdentifier(check);
  };

  return (
    <React.Fragment>
      <div className="card-install-header pb-25 pt-25">
        <h3>{props.title}</h3>
      </div>
      <div className="card-install-body">
        <span className="mt-4 title-radio">
          Tiêu chí định danh để đồng bộ thông tin khách hàng trong đơn hàng từ Web về Omni
        </span>

        <div className="next-input-wrapper mt-3">
          <label className="radio-button" htmlFor="status1">
            <input
              type="radio"
              name="radioStatus1"
              id="status1"
              value={'email'}
              checked={customerIdentifier === 'email'}
              onChange={e => handleClick('email')}
            ></input>
          </label>
          <span onClick={e => handleClick('email')} className="switch-text cursor-text">
            Email
          </span>
        </div>
        <div className="next-input-wrapper mt-2">
          <label className="radio-button" htmlFor="status2">
            <input
              type="radio"
              name="radioStatus2"
              id="status2"
              value={'phone'}
              checked={customerIdentifier === 'phone'}
              onChange={e => handleClick('phone')}
            ></input>
          </label>
          <span onClick={e => handleClick('phone')} className="switch-text cursor-text">
            Số điện thoại
          </span>
        </div>
      </div>
    </React.Fragment>
  );
}

export default Step4;
