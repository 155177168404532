import React from 'react';
import './data-sync.scss';
import LogoOmni from '../../../assets/img/logo-omni.svg';
import LogoWeb from '../../../assets/img/logo-web.svg';
import CheckSquare from '../../../assets/img/icons/square_check_icon.svg';
import SyncSquare from '../../../assets/img/icons/sync_icon_square.svg';
import SWButton from '../../../shared/sw-button/SWButton';
export function Welcome(props: any) {
  return (
    <div className="welcome-page">
      <div className="logo d-flex align-items-center justify-content-center">
        <LogoOmni className="logo-omni" />
        <SyncSquare className="icon-sync" />
        <LogoWeb className="logo-web" />
      </div>
      <div className="welcome-wrapper">
        <div className="block-content">
          <h1>
            Kết nối kênh bán hàng Sapo Web và
            <br />
            quản lý đồng bộ hiệu quả
          </h1>
          <p>Với kênh đồng bộ Omni - Web sẽ giúp chủ cửa hàng:</p>
          <ul>
            <li>
              <CheckSquare />
              Quản lý đồng bộ sản phẩm, chính sách giá và tồn kho tự động giữa
              <br />
              Sapo Omnichannel và Sapo Web.
            </li>
            <li>
              <CheckSquare />
              Quản lý đồng bộ giao dịch phát sinh trên Sapo Web, đơn hàng sẽ
              <br />
              được đồng bộ về Sapo Omnichannel để xử lý
            </li>
          </ul>
          <SWButton color="primary" onClick={() => props.setIsWelcome(false)}>
            Cấu hình ngay
          </SWButton>
        </div>
        <div className="block-img">
          <img
            src="https://bizweb.dktcdn.net/100/319/535/files/quan-ly-ban-hang-da-kenh-1.png?v=1629779418623"
            alt=""
          />
        </div>
      </div>
    </div>
  );
}

export default Welcome;
