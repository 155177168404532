import { AxiosResponse } from 'axios';
import { from, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IOrder, IOrderError } from '../../components/order/data';
import confAxios from '../../core/config/conf-axios';
import { GridData } from '../../shared/model/grid-data';

const OrderService = {
  getOrderErrors: (id: number): Observable<IOrderError> => {
    const request = confAxios.get<IOrderError>(`/orders/${id}/errors/last`);
    return from(request).pipe(
      map((res: AxiosResponse<IOrderError>) => {
        return {
          ...res.data
        };
      })
    );
  },
  getListOrder: (
    sort: string,
    size: number,
    page: number,
    keyword?: string,
    status?: string
  ): Observable<GridData<IOrder>> => {
    const request = confAxios.get<GridData<IOrder>>(`/orders`, { params: { sort, size, page, keyword, status } });
    return from(request).pipe(
      map((res: AxiosResponse<GridData<IOrder>>) => {
        return {
          ...res.data
        };
      })
    );
  },
  getOrderDetail: (keyword: number): Observable<IOrder> => {
    const request = confAxios.get<IOrder>(`/orders/${keyword}`, { params: { keyword } });
    return from(request).pipe(
      map((res: AxiosResponse<IOrder>) => {
        return {
          ...res.data
        };
      })
    );
  },
  getStatusOrder: () => {
    const request = confAxios.get(`/orders/sync-status`);
    return from(request).pipe(
      map((res: AxiosResponse) => {
        return {
          ...res.data
        };
      })
    );
  },
  syncOrder: (id: number[]) => {
    const data = {
      ids: id,
      do_type: 're_sync'
    };
    const request = confAxios.post(`/orders/do-something`, data);
    return from(request).pipe(
      map(res => {
        return {
          ...res
        };
      })
    );
  }
};

export default OrderService;
