import React, { useEffect, useState } from 'react';
import ConfigService from '../../services/config-services/index';
import './config.scss';
import OrderSyncConfig, { IOrderConfig } from './OrderSyncConfig';
import PricePolicyConfig, { IPriceConfig } from './PricePolicyConfig';
import LocationConfig, { TLocationConfig } from './LocationConfig';
import WebProductConfig, { IProductConfig } from './WebProductConfig';
import PosProductConfig from './PosProductConfig';
import CustomerConfig from './CustomerConfig';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import ProductService from '../../services/product-services/product-services';
import { TipContent, TTip, TipContext } from 'components/Tip';
import FirstSyncConfig from './FirstSyncConfig';
import { IConfig } from './data';
import Toast from 'components/Toast';
import { LoadableButton } from 'components/Button';
import * as configUtils from './configUtils';

interface ISyncConfig {
  posProductConfig: IProductConfig;
  webProductConfig: IProductConfig;
  syncType: string;
  orderConfig: IOrderConfig;
  priceConfig: IPriceConfig;
  locationConfig: TLocationConfig;
  customerIdentifier: string;
}

const fromConfigApi = (config: IConfig): ISyncConfig => ({
  posProductConfig: {
    productCreateAutoSync: config.auto_sync_create_product_pos_to_web,
    productUpdateutoSync: config.auto_sync_update_product_pos_to_web,
    fields: config.product_configs_sync_pos_to_web || []
  },
  webProductConfig: {
    productCreateAutoSync: config.auto_sync_create_product_web_to_pos,
    productUpdateutoSync: config.auto_sync_update_product_web_to_pos,
    fields: config.product_configs_sync_web_to_pos || []
  },
  syncType: config.original_sync_type,
  orderConfig: {
    webOrderSyncEnable: config.auto_sync_order_web_to_pos,
    posOrderSyncEnable: config.auto_sync_order_pos_to_web,
    posOrderInitStatus: config.pos_initial_order_status,
    webOrderManagementEnable: config.web_order_management_by_omni,
    posOrderAccount: config.pos_account_id,
    posAccounts: config.pos_accounts || [],
    syncOrderNameWebToPos: config.sync_order_name_web_to_pos
  },
  priceConfig: {
    price: config.sale_price_list_id || undefined,
    comparePrice: config.compare_price_list_id === null ? '' : config.compare_price_list_id,
    posPrices: config.pos_prices || []
  },
  locationConfig: {
    orderLocation: config.order_location_id,
    productLocation: config.product_location_id,
    posLocations: config.pos_locations || [],
    locations: config.location_ids || [],
    mappings: config.order_location_mappings || [],
    enableMapping: !!config.order_location_mappings.length
  },
  customerIdentifier: config.customer_identifier
});

const toConfigApi = (config: ISyncConfig): IConfig => ({
  auto_sync_create_product_pos_to_web: config.posProductConfig.productCreateAutoSync,
  auto_sync_update_product_pos_to_web: config.posProductConfig.productUpdateutoSync,
  product_configs_sync_pos_to_web: config.posProductConfig.fields,
  auto_sync_create_product_web_to_pos: config.webProductConfig.productCreateAutoSync,
  auto_sync_update_product_web_to_pos: config.webProductConfig.productUpdateutoSync,
  product_configs_sync_web_to_pos: config.webProductConfig.fields,
  original_sync_type: config.syncType,
  auto_sync_order_pos_to_web: config.orderConfig.posOrderSyncEnable,
  auto_sync_order_web_to_pos: config.orderConfig.webOrderSyncEnable,
  web_order_management_by_omni: config.orderConfig.webOrderManagementEnable,
  sync_order_name_web_to_pos: config.orderConfig.syncOrderNameWebToPos,
  pos_account_id: config.orderConfig.posOrderAccount,
  pos_initial_order_status: config.orderConfig.posOrderInitStatus,
  sale_price_list_id: config.priceConfig.price,
  compare_price_list_id: config.priceConfig.comparePrice,
  order_location_id: config.locationConfig.orderLocation,
  product_location_id: config.locationConfig.productLocation,
  order_location_mappings: config.locationConfig.enableMapping ? config.locationConfig.mappings : [],
  customer_identifier: config.customerIdentifier,
  location_ids: config.locationConfig.locations || []
});

const defaultConfig: ISyncConfig = {
  posProductConfig: {
    productCreateAutoSync: false,
    productUpdateutoSync: false,
    fields: []
  },
  webProductConfig: {
    productCreateAutoSync: false,
    productUpdateutoSync: false,
    fields: []
  },
  syncType: undefined,
  orderConfig: {
    posAccounts: [],
    posOrderAccount: undefined,
    posOrderInitStatus: 'drafted',
    posOrderSyncEnable: false,
    webOrderManagementEnable: false,
    webOrderSyncEnable: false,
    syncOrderNameWebToPos: false
  },
  priceConfig: {
    price: undefined,
    comparePrice: undefined,
    posPrices: []
  },
  locationConfig: {
    locations: [],
    posLocations: [],
    mappings: [],
    enableMapping: false
  },
  customerIdentifier: undefined
};

export function Config() {
  const [config, setConfig] = useState<ISyncConfig>(defaultConfig);
  const [tip, setTip] = useState<TTip>({
    title: 'Đồng bộ Web',
    description: 'Thiết lập các thông tin đồng bộ giữa hai hệ thống'
  });
  const [isOpenModal, setOpenModal] = useState<boolean>();
  const [showingAlert] = useState(false);
  const [alertType] = useState('custom-success');
  const [textAlert] = useState('');
  const [isLoading, setLoading] = useState(false);
  const [lastSaveLocations, setLastSaveLocations] = useState<Set<number>>(new Set());

  useEffect(() => {
    setLoading(true);
    ConfigService.getConfig().subscribe(data => {
      setLoading(false);
      const config = fromConfigApi(data);
      setConfig(config);
      setLastSaveLocations(new Set(config.locationConfig.locations));

      if (data.invalid_field && data.invalid_field.length) {
        data.invalid_field.forEach(item => {
          // TODO: handle invalid field
          // if (item === 'product_location_id') {
          //   setProductLocationId('');
          // }
          // if (item === 'order_location_id') {
          //   setOrderLocationId('');
          // }
          // if (item === 'location_ids') {
          //   var locationAfter = data.location_ids.filter(function (obj) {
          //     return data.pos_locations.some(function (obj2) {
          //       return obj === obj2.id;
          //     });
          //   });
          //   setLocation(locationAfter);
          // }
        });
      }
    });
  }, []);

  const handleUpdateConfig = () => {
    if (config.priceConfig.price === config.priceConfig.comparePrice) {
      return Toast.error('Không thể chọn 2 loại giá trùng nhau');
    }
    if (!config.locationConfig.locations.length) {
      return Toast.error('Vui lòng thiết lập chi nhánh đồng bộ');
    }
    setLoading(true);
    return ConfigService.updateConfig(toConfigApi(config)).subscribe(
      rs => {
        setLoading(false);
        setConfig(fromConfigApi(rs.res));
        Toast.info('Lưu cấu hình thành công');
        const savedLocations = new Set(config.locationConfig.locations);
        const locationChanged = !configUtils.isSameSet(savedLocations, lastSaveLocations);
        setLastSaveLocations(savedLocations);
        setOpenModal(locationChanged);
      },
      err => {
        setLoading(false);
        if (err.response.data && err.response.data.location_ids) {
          return Toast.error('Lưu cấu hình thất bại: Cấu hình chứa chi nhánh không tồn tại hoặc đã bị khoá');
        }
        return Toast.error('Lưu cấu hình thất bại: statusCode=' + err.response.status);
      }
    );
  };

  return (
    <TipContext.Provider value={{ tip, setTip }}>
      <div id="config-root">
        <div
          className={`alert alert-${alertType} ${
            showingAlert ? 'alert-shown' : 'alert-hidden'
          } d-flex justify-content-center`}
        >
          <span>{textAlert}</span>
        </div>

        <div className="flex-row d-flex justify-content-between">
          <div className="config-content-main flex-column d-flex align-items-center justify-content-between col-md-8 pl-0">
            <PosProductConfig
              config={config.posProductConfig}
              onConfigChange={posProductConfig =>
                setConfig({
                  ...config,
                  posProductConfig
                })
              }
            />
            <WebProductConfig
              config={config.webProductConfig}
              onConfigChange={webProductConfig =>
                setConfig({
                  ...config,
                  webProductConfig
                })
              }
            />
            <FirstSyncConfig
              config={config.syncType}
              onConfigChange={syncType =>
                setConfig({
                  ...config,
                  syncType
                })
              }
            />
            <OrderSyncConfig
              config={config.orderConfig}
              onConfigChange={orderConfig =>
                setConfig({
                  ...config,
                  orderConfig
                })
              }
            />
            <PricePolicyConfig
              config={config.priceConfig}
              onConfigChange={priceConfig =>
                setConfig({
                  ...config,
                  priceConfig
                })
              }
            />
            <LocationConfig
              config={config.locationConfig}
              onConfigChange={locationConfig =>
                setConfig({
                  ...config,
                  locationConfig
                })
              }
            />
            <CustomerConfig
              config={config.customerIdentifier}
              onConfigChange={customerIdentifier =>
                setConfig({
                  ...config,
                  customerIdentifier
                })
              }
            />
            <div
              style={{
                width: '100%',
                textAlign: 'right'
              }}
            >
              <LoadableButton color="primary" loading={isLoading} onClick={handleUpdateConfig} size="lg">
                Cập nhật
              </LoadableButton>
            </div>
          </div>

          <div className="config-content-sub flex-column d-flex col-md-4">
            <TipContent />
          </div>
        </div>
        <Modal isOpen={isOpenModal} className="modal-confirm" id="modal-action-config" autoFocus={false}>
          <ModalHeader toggle={() => setOpenModal(false)} style={{ fontSize: '1.6rem' }} className="modal--header">
            Tồn kho sản phẩm trên Web chưa cập nhật theo cấu hình mới
          </ModalHeader>
          <ModalBody className="modal--body border-bottom">
            <div className="flex-column d-flex align-items-start card-config-popup-body">
              <span>
                Bạn vừa cấu hình lại chi nhánh đồng bộ, thiết lập này chỉ được áp dụng cho các sản phẩm cập nhật sau
                thời điểm thay đổi cấu hình.
              </span>
              <span className="mt-3">
                Để áp dụng cả cho sản phẩm trước đó vui lòng nhấn <b>Cập nhật toàn bộ</b>
              </span>
              <span className="mt-3">
                Để cấu hình mới chỉ áp dụng cho sản phẩm cập nhật sau này, nhấn <b>Bỏ qua</b>
              </span>
            </div>
          </ModalBody>
          <ModalFooter className="modal--footer">
            <Button onClick={() => setOpenModal(false)}>Bỏ qua</Button>
            <Button
              color="primary"
              disabled={isLoading}
              onClick={() => {
                setOpenModal(false);
                ProductService.syncAllInventory().subscribe(data => {
                  if (data) {
                    Toast.info('Cập nhật toàn bộ thành công');
                  }
                });
              }}
            >
              Cập nhật toàn bộ
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    </TipContext.Provider>
  );
}

export default Config;
