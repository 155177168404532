import React, { useState, useRef, useEffect } from 'react';
import './dashboard.scss';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import SWButton from '../../shared/sw-button/SWButton';
import SapoWeb from '../../assets/img/sapo-web.svg';
import Spinner from '../../assets/img/spinner.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faCheckCircle, faGlobeAsia } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import urlUtils from '../../core/urlUtils';
import { LoadableButton } from 'components/Button';
import auth from '../../../src/auth';
import Toast from 'components/Toast';

export function DataResult(props: any) {
  const listStore = [
    {
      name: 'store1',
      id: '1'
    },
    {
      name: 'store2',
      id: '2'
    }
  ];
  const { webAlias, createdOn, webDomain, orderStatistic, productStatistic, syncStatus } = props ? props : null;

  //open Popup
  const ref = useRef(null);
  const [isOpen, setOpenConditon] = useState(false);
  const [uninstalling, setUninstalling] = useState(false);
  const useOnClickOutside = (ref, handler) => {
    useEffect(() => {
      const listener = event => {
        if (!ref.current || ref.current.contains(event.target)) {
          return;
        }

        handler(event);
      };

      document.addEventListener('mousedown', listener);
      document.addEventListener('touchstart', listener);

      return () => {
        document.removeEventListener('mousedown', listener);
        document.removeEventListener('touchstart', listener);
      };
    }, [ref, handler]);
  };
  useEffect(() => {}, [props]);
  useOnClickOutside(ref, () => setOpenConditon(false));

  function handleSetStore(store: any) {
    setOpenConditon(false);
  }

  function handleWeb() {
    setOpenConditon(false);
  }

  const handleUninstallApp = () => {
    const authStore = auth.get();
    setUninstalling(true);
    SapoApp.uninstall({
      confirm: false,
      callback: res => {
        if (!res.success) {
          setUninstalling(false);
          Toast.error('lỗi khi gỡ kênh: ' + res.message);
          return;
        }
        auth.clear();
        if (authStore && authStore.storeAlias) {
          window.parent.location.href = `${urlUtils.resolvePosDomain(authStore.storeAlias)}/admin/settings/channels`;
        }
      }
    });
  };
  const [isOpenManualPopup, setOpenManualPopup] = useState(false);
  const closeModalAction = () => {
    setOpenManualPopup(false);
  };
  return (
    <div className="card card-dashboard-right pt-15 pb-15 mr-3" style={{ width: '25%', marginTop: '0' }}>
      <div className="card-dashboard-header d-flex align-items-center justify-content-center p-0">
        <FontAwesomeIcon icon={faGlobeAsia} size="1x" className="mr-2" color="#08f" />
        <span style={{ whiteSpace: 'nowrap' }}>Đang kết nối </span>
        <div className="list-store">
          <span style={{ whiteSpace: 'nowrap' }}>{webAlias ? webAlias : ''}</span>
          {/* <Down className="ml-1 icon-per" /> */}
        </div>

        {isOpen ? (
          <div className="d-flex align-items-center flex-column popover-list-store" ref={ref}>
            <div className="d-flex flex-column w-100">
              {listStore.map((itm, index) => (
                <div
                  key={index}
                  className="select-store d-flex align-items-center justify-content-center"
                  onClick={() => handleSetStore(itm.name)}
                >
                  {itm.name}
                </div>
              ))}
            </div>
            <div className="border-top d-flex flex-row w-100">
              <span
                className="select-store d-flex align-items-center justify-content-center"
                onClick={() => handleWeb()}
              >
                Chọn web
              </span>
            </div>
          </div>
        ) : null}
      </div>

      <div className="card-dashboard-body flex-column d-flex flex-column mt-5 align-items-center justify-content-center">
        <SapoWeb className="logo"></SapoWeb>
        <div className="d-flex flex-column align-items-center justify-content-center">
          <span>Tên web</span>
          <a className="web-domain" href={webDomain} target="_blank" rel="noreferrer">
            {webDomain ? webDomain : ''}
          </a>
          <span>({createdOn ? moment(createdOn).format('DD/MM/YYYY HH:mm') : ''})</span>
        </div>
      </div>
      <div className="d-flex card-dashboard-body align-items-center justify-content-center pt-3 pb-3">
        {syncStatus === 'success' ? (
          <React.Fragment>
            <FontAwesomeIcon icon={faCheckCircle} size="1x" className="mr-3" color="#08f" />
            <span className="text-result">Dữ liệu đã được đồng bộ</span>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Spinner
              style={{ width: '45px', marginLeft: 0, marginRight: 0, marginBottom: 0 }}
              className="logo"
            ></Spinner>
            <span className="text-result">Dữ liệu đang được đồng bộ</span>
          </React.Fragment>
        )}
      </div>
      <div className="d-flex card-dashboard-body justify-content-center pt-3 pb-3 flex-column">
        <div className="d-flex flex-row align-items-center">
          <FontAwesomeIcon icon={faCheck} size="1x" className="mr-2" color="#08f" />
          <span color="#08f" className="text-result-number mr-2">
            {productStatistic ? productStatistic.connected : 0}
          </span>
          <span className="text-result">sản phẩm đã được đồng bộ</span>
        </div>
        <div className="d-flex flex-row align-items-center mt-2">
          <FontAwesomeIcon icon={faCheck} size="1x" className="mr-2" color="#08f" />
          <span color="#08f" className="text-result-number mr-2">
            {orderStatistic ? orderStatistic.connected : 0}
          </span>
          <span className="text-result">đơn hàng đã được đồng bộ</span>
        </div>
      </div>

      <div className="card-dashboard-footer" style={{ textAlign: 'center' }}>
        <LoadableButton className="btn-danger" loading={uninstalling} onClick={() => setOpenManualPopup(true)}>
          Gỡ bỏ web
        </LoadableButton>
      </div>
      <Modal isOpen={isOpenManualPopup} className="modal-confirm" id="modal-action-config" autoFocus={false}>
        <ModalHeader toggle={closeModalAction} className="modal--header">
          Thông báo
        </ModalHeader>
        <ModalBody className="modal--body border-bottom">
          <div className="flex-column d-flex align-items-center card-config-popup-body">
            <span>
              Ngắt kết nối với Web và xóa dữ liệu đồng bộ. Bạn sẽ không thể đồng bộ lại những dữ liệu đã xóa
            </span>
          </div>
        </ModalBody>
        <ModalFooter className="modal--footer">
          <SWButton
            color="primary"
            onClick={() => {
              setOpenManualPopup(false);
              handleUninstallApp();
            }}
          >
            Đồng ý
          </SWButton>
          <SWButton onClick={closeModalAction}>Thoát</SWButton>
        </ModalFooter>
      </Modal>
    </div>
  );
}

export default DataResult;
