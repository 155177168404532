import React from 'react';
import { FormGroup, Row, Col } from 'reactstrap';
import { Card, CardHeader, CardBody } from 'components/Card';
import Tip from 'components/Tip';
import Checkbox from 'components/Checkbox';
import { IProductConfig } from './WebProductConfig';

const fieldGroups = [
  [
    {
      id: 'product_name',
      name: 'Tên sản phẩm',
      descriptions: [
        'Đồng bộ tên sản phẩm từ Omni lên Web.',
        'Lưu ý khi bỏ tích chọn, tên sản phẩm vẫn sẽ được đồng bộ lúc tạo do là trường bắt buộc của 2 bên, hệ thống chỉ xem xét cấu hình này lúc update'
      ]
    },
    {
      id: 'option',
      name: 'Thuộc tính',
      disabled: true,
      descriptions: []
    },
    {
      id: 'price',
      name: 'Chính sách giá',
      disabled: true,
      descriptions: ['Đồng bộ từ Omni lên Web theo đúng các giá đã liên kết.']
    },
    {
      id: 'sku',
      name: 'Mã SKU',
      disabled: true,
      descriptions: [
        'Đồng bộ mã SKU từ Omni lên Web.',
        'Mã SKU là trường thông tin đồng bộ bắt buộc, người dùng không thể bỏ tích chọn.'
      ]
    }
  ],
  [
    {
      id: 'stock',
      name: 'Tồn kho',
      disabled: true,
      descriptions: [
        'Tồn kho luôn đẩy từ Omni lên Web.',
        'Tồn kho là trường thông tin đồng bộ bắt buộc, người dùng không thể bỏ tích chọn.'
      ]
    },
    {
      id: 'tag',
      name: 'Thẻ tag',
      descriptions: [
        'Nên bật thông tin đồng bộ này nếu bạn thường xuyên quản lý và làm dữ liệu tag trên Web . Hãy tắt tính năng đồng bộ tag từ Web về Omni khi người dùng chỉ có nhu cầu quản lý dữ liệu tag trên Omni. Khi chọn đồng bộ tag từ Omni về Web, thì khi cập nhật sản phẩm trên Omni sẽ update thông tin tag về Web.',
        'Một số tình huống có thể xảy ra :',
        '- Khi update sản phẩm trên Omni thì sản phẩm bên Web sẽ update theo. Nếu sản phẩm Omni đang không có tag, sản phẩm Web đang có tag >> sản phẩm Web sẽ update theo và mất tag.'
      ]
    },
    {
      id: 'image',
      name: 'Ảnh',
      descriptions: [
        'Khi bật đồng bộ ảnh đại diện từ Omni lên Web, nếu thay đổi thông tin sản phẩm trên Omni:',
        '- Những ảnh đã có sẵn trên Omni trước khi bật, hoặc được tạo mới trên Omni sau khi bật, sẽ được tạo mới tương ứng trên Web. Hai ảnh sẽ được liên kết với nhau.',
        '- Những ảnh được xóa trên Omni, nếu có liên kết với ảnh trên Web, sẽ được xóa tương ứng trên Web.',
        '- Những ảnh đã có sẵn trên Web, chưa được liên kết với ảnh trên Omni, sẽ không bị xóa.'
      ]
    },
    {
      id: 'category',
      name: 'Nhóm sản phẩm',
      descriptions: [
        'Được đồng bộ lần đầu từ Omni lên Web.',
        'Được cập nhật thông tin nếu một trong hai hệ thống thay đổi.'
      ]
    }
  ],
  [
    // {
    //   id: 'taxable',
    //   name: 'Áp dụng thuế',
    //   disabled: true,
    //   descriptions: [
    //     'Được đồng bộ lần đầu từ Omni lên Web.',
    //     'Được cập nhật thông tin nếu một trong hai hệ thống thay đổi.'
    //   ]
    // },
    {
      id: 'weight',
      name: 'Khối lượng',
      descriptions: [
        'Được đồng bộ lần đầu từ Omni lên Web.',
        'Được cập nhật lại thông tin nếu một trong hai hệ thống thay đổi.'
      ]
    },
    {
      id: 'barcode',
      name: 'Mã barcode',
      descriptions: ['Đồng bộ mã Barcode từ Omni lên Web.']
    },
    {
      id: 'description',
      name: 'Mô tả',
      descriptions: [
        'Mô tả sản phẩm sẽ được đồng bộ từ Omni lên Web khi có thay đổi thông tin sản phẩm trên Omni.',
        'Lưu ý, mô tả sản phẩm tại Omni sẽ được ghi đè lên mô tả sản phẩm tại Web. Bạn vui lòng kiểm tra lại dữ liệu nếu mô tả sản phẩm tại Web đang đầy đủ và chi tiết hơn tại Omni.'
      ]
    },
    {
      id: 'vendor',
      name: 'Nhãn hiệu',
      descriptions: [
        'Khi bật trường này sẽ đồng bộ với trường "Nhà cung cấp" bên Web'
      ]
    }
  ],
  [
    {
      id: 'variant_title',
      name: 'Tên thuộc tính sản phẩm quy đổi'
    }
  ]
];

export default ({
  config,
  onConfigChange
}: {
  config: IProductConfig;
  onConfigChange: (v: IProductConfig) => any;
}) => {
  return (
    <Card>
      <CardHeader title="Dữ liệu đồng bộ sản phẩm từ Omni lên Web" />
      <CardBody>
        <FormGroup>
          <Checkbox
            checked={config.productCreateAutoSync}
            onChange={e =>
              onConfigChange({
                ...config,
                productCreateAutoSync: e.target.checked
              })
            }
            label="Đồng bộ dữ liệu khi tạo sản phẩm trên Omni"
          />
          <Tip
            title="Tạo sản phẩm trên Omni"
            descriptions={[
              'Sản phẩm trên Omni sẽ được đồng bộ lên Web, nếu trùng mã SKU sẽ liên kết sản phẩm, nếu không trùng mã SKU sẽ thêm mới sản phẩm trên Web.',
              '- Bật cấu hình này nếu bạn muốn sản phẩm được tự động đồng bộ.',
              '- Tắt cấu hình nếu bạn muốn truy cập kênh chọn lọc thủ công những sản phẩm từ Omni đồng bộ lên Web.'
            ]}
          />
        </FormGroup>
        <FormGroup>
          <Checkbox
            checked={config.productUpdateutoSync}
            onChange={e =>
              onConfigChange({
                ...config,
                productUpdateutoSync: e.target.checked
              })
            }
            label="Đồng bộ dữ liệu khi cập nhật sản phẩm trên Omni"
          />
          <Tip
            title="Cập nhật sản phẩm trên Omni"
            descriptions={[
              'Khi sản phẩm trên hai hệ thống đã được liên kết:',
              '- Bật cấu hình nếu bạn muốn nhận dữ liệu cập nhật từ Omni lên Web.',
              '- Tắt cấu hình nếu bạn không muốn nhận dữ liệu cập nhật từ Omni lên Web.'
            ]}
          />
        </FormGroup>
        <hr />
        <p>Chọn thông tin đồng bộ</p>
        <div>
          {fieldGroups.map((fields, gIdx) => (
            <Row
              key={gIdx}
              style={{
                marginBottom: '5px'
              }}
            >
              {fields.map((f, fIdx) => (
                <Col
                  key={fIdx}
                  style={{
                    paddingRight: '10px'
                  }}
                >
                  <Checkbox
                    checked={config.fields.indexOf(f.id) >= 0}
                    disabled={f.disabled}
                    onChange={e => {
                      if (e.target.checked) {
                        return onConfigChange({
                          ...config,
                          fields: [...config.fields, f.id]
                        });
                      }
                      var valueIdx = config.fields.indexOf(f.id);
                      return onConfigChange({
                        ...config,
                        fields: [
                          ...config.fields.slice(0, valueIdx),
                          ...config.fields.slice(valueIdx + 1)
                        ]
                      });
                    }}
                    label={f.name}
                  />
                  {f.descriptions && f.descriptions.length > 0 && (
                    <Tip title={f.name} descriptions={f.descriptions} />
                  )}
                </Col>
              ))}
            </Row>
          ))}
        </div>
      </CardBody>
    </Card>
  );
};
