import { AxiosResponse } from 'axios';
import { from, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { IConfig, TProvince } from '../../components/config/data';
import confAxios from '../../core/config/conf-axios';

let cacheProvinces: TProvince[];

const ConfigService = {
  getConfig: (): Observable<IConfig> => {
    const request = confAxios.get<IConfig>(`/store/config`);
    return from(request).pipe(
      map((res: AxiosResponse<IConfig>) => {
        return {
          ...res.data
        };
      })
    );
  },
  getProvinces: (): Observable<TProvince[]> => {
    if (cacheProvinces) {
      return of(cacheProvinces);
    }
    const request = confAxios.get<TProvince[]>('/store/provinces');
    return from(request).pipe(
      map((res: AxiosResponse<TProvince[]>) => {
        cacheProvinces = res.data;
        return cacheProvinces;
      })
    );
  },
  updateConfig: (config: IConfig): any => {
    const requestBody = config;

    const request = confAxios.put<IConfig>(`/store/config`, requestBody);

    return from(request).pipe(
      map((res: AxiosResponse<IConfig>) => {
        return {
          res: res.data,
          status: res.status
        };
      })
    );
  },
  postDefaultConfig: (): Observable<any> => {
    const request = confAxios.post<any>(`/store/config/set-default`);

    return from(request).pipe(
      map((res: AxiosResponse<any>) => {
        return {
          ...res.data,
          status: res.status
        };
      })
    );
  }
};

export default ConfigService;
