import moment from 'moment';

export function formatCurrency(n, separate = '.') {
  if (n) {
    var s = n.toString();
    var regex = /\B(?=(\d{3})+(?!\d))/g;
    var ret = s.replace(regex, separate);
    return ret;
  } else return 0;
}

export function formatDate(date: string) {
  return moment(date).format('DD/MM/YYYY HH:mm');
}
