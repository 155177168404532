interface IAuth {
  token: string;
  storeAlias: string;
  storeId: string;
}

const auth = {
  get(): IAuth {
    if (DEBUG.enable) {
      return {
        token: DEBUG.token,
        storeAlias: DEBUG.storeAlias,
        storeId: DEBUG.storeId + ''
      };
    }
    const token = localStorage.getItem('token');
    if (!token) {
      return null;
    }
    return {
      token: token,
      storeAlias: localStorage.getItem('appStoreAlias'),
      storeId: localStorage.getItem('appStoreId')
    };
  },
  set({ token, storeAlias, storeId }: IAuth) {
    localStorage.setItem('token', token);
    localStorage.setItem('appStoreAlias', storeAlias);
    localStorage.setItem('appStoreId', storeId);
  },
  clear() {
    localStorage.removeItem('token');
    localStorage.removeItem('apiKey');
    localStorage.removeItem('appStoreAlias');
    localStorage.removeItem('appStoreId');
  }
};

export default auth;
