import React from 'react';
import './no-data.scss';
import BigSearch from '../../assets/img/icons/search-big-icon.svg';
interface NoDataProps {
  title: any;
  content?: any;
}
export default function NoData(props: NoDataProps) {
  const { content, title } = props;
  return (
    <div id="sw-no-data">
      <div className="box">
        <div>
          <BigSearch></BigSearch>
        </div>
        <div>{title}</div>
        <div>{content}</div>
      </div>
    </div>
  );
}
