import React, { useEffect, useState } from 'react';
import './data-sync.scss';

export function Step5(props: any) {
  const [price, setPrice] = useState(null);
  const [priceCompare, setPriceCompare] = useState(null);

  const [orderLocation, setOrderLocation] = useState(null);
  const [productLocation, setProductLocation] = useState(null);

  const [locations, setLocation] = useState([]);

  const [customerIdentifier, setCustomerIdentifier] = useState(null);

  const [webtoPos, setWebtoPos] = useState<boolean>(true);
  const [postoWeb, setPostoWeb] = useState<boolean>(true);

  useEffect(() => {
    if (typeof props.posLocation !== 'undefined') {
      if (typeof props.location !== 'undefined') {
        let locations = [];
        props.location.forEach(item => {
          props.posLocation.forEach(itm => {
            if (itm.id === item) {
              locations.push(itm.label);
            }
          });
        });
        setLocation(locations);
      }

      if (typeof props.productLocationId !== 'undefined') {
        var resultProduct = props.posLocation.filter(obj => {
          return obj.id === props.productLocationId;
        });
        if (resultProduct.length > 0) {
          setProductLocation(resultProduct[0]);
        }
      }

      if (typeof props.orderLocationId !== 'undefined') {
        var result = props.posLocation.filter(obj => {
          return obj.id === props.orderLocationId;
        });
        if (result.length > 0) {
          setOrderLocation(result[0]);
        }
      }
    }

    if (typeof props.posPrices !== 'undefined') {
      if (typeof props.salePriceId !== 'undefined') {
        var resultPrice = props.posPrices.filter(obj => {
          return obj.id === props.salePriceId;
        });
        if (resultPrice.length > 0) {
          setPrice(resultPrice[0]);
        }
      }

      if (typeof props.comparePriceId !== 'undefined') {
        var resultCompare = props.posPrices.filter(obj => {
          return obj.id === props.comparePriceId;
        });
        if (resultCompare.length > 0) {
          setPriceCompare(resultCompare[0]);
        }
      }
    }

    if (typeof props.webtoPos !== 'undefined') {
      setWebtoPos(props.webtoPos);
    }
    if (typeof props.postoWeb !== 'undefined') {
      setPostoWeb(props.postoWeb);
    }
    if (typeof props.customerIdentifier !== 'undefined') {
      setCustomerIdentifier(props.customerIdentifier);
    }
  }, [props]);

  return (
    <React.Fragment>
      <div className="card-install-header pb-25 pt-25">
        <h3>{props.title}</h3>
      </div>
      <div className="card-install-body d-flex flex-row align-items-center w-100 install-title">
        <span className="col-md-8">Cấu hình</span>
        <span className="col-md-3 no-padding">Kết quả</span>
      </div>

      <div className="card-install-body d-flex flex-row align-items-center w-100">
        <span className="col-md-8">Danh sách chi nhánh Omni đồng bộ với Web</span>
        <div className="col-md-3 no-padding d-flex flex-column">
          {locations.length > 0
            ? locations.map((itm, index) => (
                <div key={index} className="d-flex align-items-center">
                  {itm}
                </div>
              ))
            : ''}
        </div>
      </div>

      <div className="card-install-body d-flex flex-row align-items-center w-100">
        <span className="col-md-8">Chi nhánh nhận dữ liệu tồn kho khi tạo mới sản phẩm từ Web về Omni</span>
        <div className="col-md-3 no-padding">{productLocation != null ? productLocation.label : ''}</div>
      </div>

      <div className="card-install-body d-flex flex-row align-items-center w-100">
        <span className="col-md-8">Chi nhánh nhận đơn hàng từ kênh Web đổ về</span>
        <div className="col-md-3 no-padding">{orderLocation != null ? orderLocation.label : ''}</div>
      </div>

      <div className="card-install-body d-flex flex-row align-items-center w-100">
        <span className="col-md-8">Giá đồng bộ với giá bán Web</span>
        <div className="col-md-3 no-padding">{price != null ? price.label : 'Giá bán lẻ'}</div>
      </div>

      <div className="card-install-body d-flex flex-row align-items-center w-100">
        <span className="col-md-8">Giá đồng bộ với giá so sánh Web</span>
        <div className="col-md-3 no-padding">{priceCompare != null ? priceCompare.label : 'Không so sánh giá'}</div>
      </div>

      <div className="card-install-body d-flex flex-row align-items-center w-100">
        <span className="col-md-8">Dữ liệu sản phẩm cũ trên Web trước đồng bộ</span>
        <div className="col-md-3 no-padding">{webtoPos ? 'Có đồng bộ' : 'Không đồng bộ'}</div>
      </div>

      <div className="card-install-body d-flex flex-row align-items-center w-100">
        <span className="col-md-8">Dữ liệu sản phẩm cũ trên Omni trước đồng bộ</span>
        <div className="col-md-3 no-padding">{postoWeb ? 'Có đồng bộ' : 'Không đồng bộ'}</div>
      </div>

      <div className="card-install-body d-flex flex-row align-items-center w-100">
        <span className="col-md-8">Tiêu chí liên kết đồng bộ khách hàng</span>
        <div className="col-md-3 no-padding">{customerIdentifier === 'phone' ? 'Số điện thoại' : 'Email'}</div>
      </div>
    </React.Fragment>
  );
}

export default Step5;
