import { faCheck, faSync, faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useRef, useState } from 'react';
import OrderService from '../../../services/order-services/order-services';
import ProductService from '../../../services/product-services/product-services';
import ChevronDuoRight from '../../../assets/img/icons/chevron_duo_right.svg';
import CaretDownIcon from '../../../assets/img/icons/caret_down_icon.svg';
import TrashEmpty from '../../../assets/img/icons/trash_empty.svg';
import SyncIcon from '../../../assets/img/icons/sync_icon.svg';
import LinkIcon from '../../../assets/img/icons/link_icon.svg';
import MoonEmptyIcon from '../../../assets/img/icons/moon_empty_icon.svg';

import UnlinkIcon from '../../../assets/img/icons/unlink_icon.svg';
import PrayerIcon from '../../../assets/img/icons/prayer_icon.svg';
import SemicircleIcon from '../../../assets/img/icons/semicircle_icon.svg';
import _ from 'lodash';
import ReactTooltip from 'react-tooltip';

import { formatDate, formatCurrency } from '../../../shared/util/number-format';
import SWebPagination from '../../../shared/sw-pagination/sweb-pagination';
import { IConditionOrder, IOrder, IOrderError, itemPerPageModel, navModelActionProduct } from '../data';
import './table.scss';
import SWButton from '../../../shared/sw-button/SWButton';
import { useDebouncedEffect, useOnClickOutside } from '../../../shared/util/function';
import { GridData } from '../../../shared/model/grid-data';
import NoData from '../../../shared/no-data/no-data';
import { IItem } from '../../../shared/model/base-data';
interface TableOrderProps {
  condition?: IConditionOrder;
  checkCallApi: boolean;
  setCheckCallApi?: any;
}

export default function TableOrder(props: TableOrderProps) {
  const { condition, checkCallApi, setCheckCallApi } = props;

  const alias = localStorage.getItem('appStoreAlias');
  const ref2 = useRef();
  const status = condition.status;
  const query = condition.query;

  const [productSync, setProductSync] = useState<number[]>([]);

  const [data, setData] = useState<GridData<IOrder>>();
  const [orderDetail, setOrderDetail] = useState<IOrder[]>([]);
  const [orderError, setOrderError] = useState<IOrderError[]>([]);

  const ref = useRef();
  const sort: string = 'id,DESC';
  const itemPerPages = itemPerPageModel;

  const [openItemPerPage, setOpenItemPerPage] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [showBulkActionEvent, setShowBulkActionEvent] = useState<boolean>(false);
  const [loadingDetail, setLoadingDetail] = useState<boolean>(true);

  //pagination
  const [page, setPage] = useState<number>(1);
  const [totalPage, setToltalPage] = useState<number>(0);
  const [itemPerPage, setItemPerPage] = useState<number>(20);
  const [totalElements, settotalElements] = useState<number>(0);

  const [listChecked, setListChecked] = useState<number[]>([]);
  // const [synchronizedList, setSynchronizedList] = useState<number[]>([]);

  //close openItemPerPage
  useOnClickOutside(ref, () => setOpenItemPerPage(false), 'item-per-page');
  useOnClickOutside(ref2, () => setShowBulkActionEvent(false), 'box-action-btn');

  //setDefault
  const setDefault = () => {
    setOrderDetail([]);
    setOrderError([]);
    setListChecked([]);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [page]);

  useEffect(() => {
    setPage(1);
  }, [status, itemPerPage, query]);

  useEffect(() => {
    var a = null;
    if (data) {
      var check = false;
      data.content.forEach(order => {
        if (order.last_sync_request_status === 'waiting') {
          check = true;
        }
      });
      if (check) {
        a = setInterval(() => {
          getData();
        }, 3000);
      }
    }
    return () => {
      clearInterval(a);
    };
  }, [data]);

  const getData = (action?: any, action2?: any) => {
    OrderService.getListOrder(sort, itemPerPage, page - 1, query ? query : null, status ? status : null).subscribe(
      data => {
        setToltalPage(data.total_pages);
        settotalElements(data.total_elements);
        setData(data);
        setLoading(false);
        if (action) {
          action();
        }
        if (action2) {
          action2();
        }
      }
    );
  };

  useDebouncedEffect(
    () => {
      OrderService.getListOrder(sort, itemPerPage, 0, query ? query : null, status ? status : null).subscribe(
        data => {
          setToltalPage(data.total_pages);
          settotalElements(data.total_elements);
          setData(data);
          setLoading(false);
          setDefault();
          setCheckCallApi(false);
        }
      );
    },
    300,
    [query]
  );

  useEffect(() => {
    getData(null, setDefault());
  }, [sort, itemPerPage, page, status]);

  const onClickSync = (id?: number[]) => {
    const listOrder = [...orderDetail];
    const listOrderError = [...orderError];
    id.slice()
      .reverse()
      .forEach(i => {
        if (listOrderError.find(product => product.target_id === i)) {
          listOrderError.splice(listOrderError.indexOf(listOrderError.find(order => order.target_id === i)), 1);
        }
        // if (listOrder.find(product => product.id === i)) {
        //   listOrder.splice(listOrder.indexOf(listOrder.find(order => order.id === i)), 1);
        // }
      });
    setOrderDetail(listOrder);
    setOrderError(listOrderError);
    OrderService.syncOrder(id).subscribe(re => {
      if (re.status > 200 && re.status < 300) {
        getData();
        if (id.length === 1) {
          if (listOrder.find(detail => detail.id === id[0])) {
            OrderService.getOrderDetail(id[0]).subscribe(dataDetail => {
              const listOrderDetail = _.cloneDeep(listOrder);
              listOrderDetail.splice(listOrder.indexOf(listOrder.find(detail => detail.id === id[0])), 1, dataDetail);
              setOrderDetail(listOrderDetail);
            });
          }
        } else {
          setOrderDetail([]);
        }
      }
    });
  };
  const onClickSyncProduct = (id_P: number, order: IOrder, st: string, index_P: number) => {
    setProductSync([...productSync, order.id]);
    const status = st === 'stopped' ? 're_connect' : 're_sync';

    ProductService.changeStatus([id_P], status).subscribe(() => {
      setTimeout(() => {
        var listOrder = [...orderError];
        var index = orderError.indexOf(orderError.find(error => error.target_id === order.id));

        OrderService.getOrderErrors(order.id).subscribe(data => {
          listOrder[index] = data;
          if (data.product_errors[index_P].status === 'connected') {
            onClickSync([order.id]);
          }
          const listSync = [...productSync];
          listSync.splice(productSync.indexOf(productSync.find(l => l === order.id)), 1);
          setProductSync(listSync);
        });
        setOrderError(listOrder);
      }, 2000);
    });
  };

  const switchStatusDetail = (status: string, id: number, mess: string) => {
    switch (status) {
      case 'connected':
        return (
          <td className="status-connected">
            <LinkIcon className="mb-2" /> Đã liên kết
          </td>
        );
      case 'stopped':
        return (
          <td className="status-error">
            <UnlinkIcon /> Ngắt kết nối đến Omni
          </td>
        );
      default:
        return (
          <td className="status-error">
            <UnlinkIcon /> Chưa được liên kết với sản phẩm trên Omni
          </td>
        );
    }
  };

  const switchStatus = (order: IOrder) => {
    switch (order.status) {
      case 'connected':
        return <div className={`status status-${order.status}`}>Đã liên kết</div>;
      case 'error':
        return (
          <>
            <div className={`status status-${order.status}`} data-tip data-for={`status-${order.id}`}>
              Lỗi liên kết
            </div>
            {order.error ? (
              <ReactTooltip
                id={`status-${order.id}`}
                className="tooltip-custom"
                border
                borderColor="#E8EAEB"
                textColor="#FFF"
                backgroundColor="#182537"
                effect="solid"
                delayShow={200}
              >
                <span>{order.error.message_error}</span>
              </ReactTooltip>
            ) : (
              ''
            )}
          </>
        );

      default:
        return (
          <>
            <div className={`status status-${order.status}`} data-tip data-for={`status-${order.id}`}>
              Chưa liên kết
            </div>
            {order.error ? (
              <ReactTooltip
                id={`status-${order.id}`}
                className="tooltip-custom"
                border
                borderColor="#E8EAEB"
                textColor="#FFF"
                backgroundColor="#182537"
                effect="solid"
                delayShow={200}
              >
                <span>{order.error.message_error}</span>
              </ReactTooltip>
            ) : (
              ''
            )}
          </>
        );
    }
  };

  const switchMoon = (str: string, discription: string, id: number) => {
    switch (str) {
      case 'none':
        return (
          <>
            <MoonEmptyIcon data-tip data-for={`none-${id}`} />
            <ReactTooltip
              id={`none-${id}`}
              className="tooltip-custom"
              border
              borderColor="#E8EAEB"
              textColor="#FFF"
              backgroundColor="#182537"
              effect="solid"
              delayShow={200}
            >
              <span>{discription}</span>
            </ReactTooltip>
          </>
        );
      case 'full':
        return (
          <>
            <PrayerIcon data-tip data-for={`full-${id}`} />
            <ReactTooltip
              id={`full-${id}`}
              className="tooltip-custom"
              border
              borderColor="#E8EAEB"
              textColor="#FFF"
              backgroundColor="#182537"
              effect="solid"
              delayShow={200}
            >
              <span>{discription}</span>
            </ReactTooltip>
          </>
        );
      case 'partial':
        return (
          <>
            <SemicircleIcon data-tip data-for={`partial-${id}`} />
            <ReactTooltip
              id={`partial-${id}`}
              className="tooltip-custom"
              border
              borderColor="#E8EAEB"
              textColor="#FFF"
              backgroundColor="#182537"
              effect="solid"
              delayShow={200}
            >
              <span>{discription}</span>
            </ReactTooltip>
          </>
        );
      default:
        return '';
    }
  };

  const handleClickChoosePage = (num: number) => {
    setItemPerPage(num);
    setOpenItemPerPage(false);
  };

  const onClickCheckBoxAll = (e: any) => {
    const li: number[] = [];
    if (e.target.checked) {
      data.content.forEach(l => {
        li.push(l.id);
      });
    }
    setListChecked(li);
  };

  const handleRowClick = (event: any, id: number) => {
    const selectedIndex = listChecked.indexOf(id);
    let newSelected: number[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(listChecked, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(listChecked.slice(1));
    } else if (selectedIndex === listChecked.length - 1) {
      newSelected = newSelected.concat(listChecked.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(listChecked.slice(0, selectedIndex), listChecked.slice(selectedIndex + 1));
    }
    setListChecked(newSelected);
  };

  const isSelected = (id: number) => listChecked.indexOf(id) !== -1;

  const getOrderErrors = (id: number) => {
    OrderService.getOrderErrors(id).subscribe(data => {
      setOrderError([...orderError, data]);
      setLoadingDetail(false);
    });
  };

  const getOrderDetailLinked = (id: number) => {
    OrderService.getOrderDetail(id).subscribe(data => {
      setOrderDetail([...orderDetail, data]);
      setLoadingDetail(false);
    });
  };

  const onClickShowDetail = (id: number, status: string) => {
    if (status === 'connected') {
      if (orderDetail.find(order => order.id === id)) {
        const listOrder = [...orderDetail];
        listOrder.splice(orderDetail.indexOf(orderDetail.find(order => order.id === id)), 1);
        setOrderDetail(listOrder);
      } else {
        getOrderDetailLinked(id);
      }
    } else {
      if (orderError.find(order => order.target_id === id)) {
        const listOrder = [...orderError];
        listOrder.splice(orderError.indexOf(orderError.find(order => order.target_id === id)), 1);
        setOrderError(listOrder);
      } else {
        getOrderErrors(id);
      }
    }
  };

  const onSubmitAction = (id: number[]) => {
    onClickSync(id);
    setListChecked([]);
    setShowBulkActionEvent(false);
  };

  const checkOrderDetailEmpty = (arr: IOrderError) => {
    if (arr && arr.product_errors && arr.product_errors.length) {
      return true;
    } else return false;
  };
  // const openModalAction = (item: IItem) => {
  //   setActionType(item);
  //   setModalAction(true);
  // };
  const switchIconAction = (item: IItem) => {
    switch (item.key) {
      case 're_sync':
        return (
          <>
            <SyncIcon />
            {item.value}
          </>
        );
      case 're_connect':
        return (
          <>
            <LinkIcon />
            {item.value}
          </>
        );
      case 'stop_connect':
        return (
          <>
            <UnlinkIcon />
            {item.value}
          </>
        );
      case 'remove_connect':
        return (
          <>
            <TrashEmpty />
            {item.value}
          </>
        );
      default:
        break;
    }
  };
  return (
    <>
      {loading ? (
        ''
      ) : (
        <>
          {data.content.length ? (
            <div id="sw-order">
              <div id="table-order">
                <table className="orders">
                  <thead className="thead-order">
                    <tr>
                      <th></th>
                      <th className="select">
                        <div className={`next-input-wrapper checkbox ${listChecked.length > 0 ? 'on-checked' : ''}`}>
                          <label className="checkbox-button" htmlFor={`chooseCategory`}>
                            <input
                              type="checkbox"
                              name={`chooseCategory`}
                              id={`chooseCategory`}
                              onChange={onClickCheckBoxAll}
                              checked={listChecked.length === data.content.length}
                            ></input>
                            <span
                              className={`checkmark ${
                                listChecked.length > 0 && listChecked.length < data.content.length ? 'children' : ''
                              }`}
                            >
                              <FontAwesomeIcon icon={faCheck} size="1x" color="#08f" />
                            </span>
                          </label>
                        </div>
                        <div className={`bulk-actions ${listChecked.length > 0 ? '' : 'd-none'}`}>
                          <ul>
                            <li className="select-count">
                              <span>Đã chọn {listChecked.length} đơn hàng</span>
                            </li>
                            <li className="box-action-btn">
                              <SWButton onClick={() => setShowBulkActionEvent(() => !showBulkActionEvent)}>
                                Chọn thao tác
                                <FontAwesomeIcon className="ml-3" icon={faCaretDown} color="#212b35" size="1x" />
                              </SWButton>
                              {showBulkActionEvent ? (
                                <div className="popover-bulk-action" ref={ref2}>
                                  <span className="triangle"></span>
                                  {navModelActionProduct.map((nav, index) => (
                                    <div key={index} className="action" onClick={() => onSubmitAction(listChecked)}>
                                      {switchIconAction(nav)}
                                    </div>
                                  ))}
                                </div>
                              ) : (
                                ''
                              )}
                            </li>
                          </ul>
                        </div>
                      </th>
                      <th>Mã đơn hàng</th>
                      <th>Tình trạng liên kết</th>
                      <th>Ngày tạo đơn</th>
                      <th>Tên khách hàng</th>
                      <th style={{ minWidth: 90 }}>Tổng tiền</th>
                      <th style={{ minWidth: 60 }}>Thao tác</th>
                    </tr>
                  </thead>
                  <tbody className="tbody-order">
                    {data.content.map((order, i) => {
                      const isItemSelected = isSelected(order.id);
                      const orderWP: IOrder = orderDetail.find(o => o.id === order.id);
                      const orderErr: IOrderError = orderError.find(o => o.target_id === order.id);
                      var showDetail: boolean = false;
                      if (orderWP || orderErr) {
                        showDetail = true;
                      }
                      const sync = order.last_sync_request_status === 'waiting';
                      const proSync = Boolean(productSync.find(syn => syn === order.id));
                      return (
                        <React.Fragment key={i}>
                          <tr
                            className={`row-custom ${isItemSelected ? 'row-active' : ''} ${
                              sync || proSync ? 'row-syncing' : ''
                            }`}
                            onClick={
                              !sync
                                ? order.status === 'unlink' ||
                                  (order.error && order.error.type !== 'order_product_not_map')
                                  ? null
                                  : () => onClickShowDetail(order.id, order.status)
                                : null
                            }
                          >
                            <td>
                              {order.status === 'unlink' ||
                              (order.error && order.error.type !== 'order_product_not_map') ? (
                                ''
                              ) : (
                                <>
                                  <ChevronDuoRight
                                    className={`icon-show-detail ${showDetail ? 'active' : ''}`}
                                    data-tip
                                    data-for={`show-detail-${order.id}`}
                                  />
                                  {!sync ? (
                                    <ReactTooltip
                                      id={`show-detail-${order.id}`}
                                      className="tooltip-custom"
                                      border
                                      borderColor="#E8EAEB"
                                      textColor="#FFF"
                                      backgroundColor="#182537"
                                      effect="solid"
                                      delayShow={200}
                                    >
                                      <span>Đơn hàng đang được đồng bộ</span>
                                    </ReactTooltip>
                                  ) : (
                                    ''
                                  )}
                                </>
                              )}
                            </td>
                            <td>
                              <div className={`next-input-wrapper checkbox`} onClick={e => e.stopPropagation()}>
                                <label className="checkbox-button" htmlFor={`chooseCategory-${order.id}`}>
                                  <input
                                    type="checkbox"
                                    checked={isItemSelected}
                                    onChange={e => handleRowClick(e, order.id)}
                                    name={`chooseCategory-${order.id}`}
                                    id={`chooseCategory-${order.id}`}
                                  ></input>
                                  <span className="checkmark">
                                    <FontAwesomeIcon icon={faCheck} size="1x" color="#08f" />
                                  </span>
                                </label>
                              </div>
                            </td>
                            <td>
                              <a
                                href={order.web_url}
                                rel="noopener noreferrer"
                                target="_blank"
                                className="_08F"
                                onClick={e => e.stopPropagation()}
                              >
                                {order.web_order_name}
                              </a>
                            </td>
                            <td>{switchStatus(order)}</td>
                            <td>{formatDate(order.created_on)}</td>
                            <td>{order.customer_name}</td>
                            <td>{formatCurrency(order.total_price)}</td>
                            <td>
                              {order.status === 'connected' ? (
                                <>
                                  <button
                                    disabled={sync}
                                    className="sync"
                                    onClick={e => {
                                      onClickSync([order.id]);
                                      e.stopPropagation();
                                    }}
                                    data-tip
                                    data-for={`btn-sync-${order.id}`}
                                  >
                                    <FontAwesomeIcon
                                      icon={faSync}
                                      size="1x"
                                      color="#0088FF"
                                      cursor="pointer"
                                      className={`${sync ? 'syncing' : ''}`}
                                    />
                                  </button>
                                  {!sync ? (
                                    <ReactTooltip
                                      id={`btn-sync-${order.id}`}
                                      className="tooltip-custom"
                                      border
                                      borderColor="#E8EAEB"
                                      textColor="#FFF"
                                      backgroundColor="#182537"
                                      effect="solid"
                                      delayShow={200}
                                    >
                                      <span>Đồng bộ dữ liệu</span>
                                    </ReactTooltip>
                                  ) : null}
                                </>
                              ) : (
                                <>
                                  {/* <button
                                    disabled={sync}
                                    className="sync"
                                    onClick={e => {
                                      onClickSync([order.id]);
                                      e.stopPropagation();
                                    }}
                                    data-tip
                                    data-for={`btn-sync-${order.id}`}
                                  >
                                    <FontAwesomeIcon
                                      icon={faSync}
                                      size="1x"
                                      color="#0088FF"
                                      cursor="pointer"
                                      className={`${sync ? 'syncing' : ''}`}
                                    />
                                  </button>
                                  {!sync ? (
                                    <ReactTooltip
                                      id={`btn-sync-${order.id}`}
                                      className="tooltip-custom"
                                      border
                                      borderColor="#E8EAEB"
                                      textColor="#FFF"
                                      backgroundColor="#182537"
                                      effect="solid"
                                      delayShow={200}
                                    >
                                      <span>Đồng bộ dữ liệu</span>
                                    </ReactTooltip>
                                  ) : null} */}
                                  <button
                                    disabled={sync}
                                    className="sync"
                                    onClick={e => {
                                      onClickSync([order.id]);
                                      e.stopPropagation();
                                    }}
                                    data-tip
                                    data-for={`btn-sync-${order.id}`}
                                  >
                                    <LinkIcon className="link-btn" />
                                  </button>
                                  {!sync ? (
                                    <ReactTooltip
                                      id={`btn-sync-${order.id}`}
                                      className="tooltip-custom"
                                      border
                                      borderColor="#E8EAEB"
                                      textColor="#FFF"
                                      backgroundColor="#182537"
                                      effect="solid"
                                      delayShow={200}
                                    >
                                      <span>{order.status === 'unlink' ? 'Liên kết' : 'Liên kết lại'}</span>
                                    </ReactTooltip>
                                  ) : null}
                                </>
                              )}
                            </td>
                          </tr>
                          <tr>
                            <td colSpan={8}>
                              {showDetail ? (
                                <>
                                  {loadingDetail ? (
                                    // <Loading />
                                    ''
                                  ) : (
                                    <div className={`${sync || proSync ? 'row-syncing' : ''} order-detail`}>
                                      <table className="order">
                                        {order.status === 'connected' && orderWP ? (
                                          <>
                                            <thead>
                                              <tr>
                                                <th>Chi tiết đơn hàng</th>
                                                <th>Mã đơn hàng</th>
                                                <th className="text-center">Trạng thái thanh toán</th>
                                                <th className="text-center">Trạng thái giao hàng</th>
                                                <th className="text-center">Trạng thái hoàn trả</th>
                                                <th className="text-center">Trạng thái hủy đơn hàng</th>
                                              </tr>
                                            </thead>
                                            <tbody>
                                              {orderWP.web_detail ? (
                                                <tr>
                                                  <td>Web</td>
                                                  <td>
                                                    <a
                                                      href={orderWP.web_url}
                                                      rel="noopener noreferrer"
                                                      target="_blank"
                                                      className="_0088ff"
                                                    >
                                                      {orderWP.web_order_name}
                                                    </a>
                                                  </td>
                                                  <td className="text-center">
                                                    {switchMoon(
                                                      orderWP.web_detail.financial.tag,
                                                      orderWP.web_detail.financial.description,
                                                      1
                                                    )}
                                                  </td>
                                                  <td className="text-center">
                                                    {switchMoon(
                                                      orderWP.web_detail.fulfillment.tag,
                                                      orderWP.web_detail.fulfillment.description,
                                                      2
                                                    )}
                                                  </td>
                                                  <td className="text-center">
                                                    {switchMoon(
                                                      orderWP.web_detail.refund.tag,
                                                      orderWP.web_detail.refund.description,
                                                      3
                                                    )}
                                                  </td>
                                                  <td className="text-center">
                                                    {switchMoon(
                                                      orderWP.web_detail.cancel.tag,
                                                      orderWP.web_detail.cancel.description,
                                                      4
                                                    )}
                                                  </td>
                                                </tr>
                                              ) : null}
                                              {orderWP.pos_detail ? (
                                                <>
                                                  <tr>
                                                    <td>Omni</td>
                                                    <td>
                                                      <a
                                                        rel="noopener noreferrer"
                                                        href={orderWP.pos_url}
                                                        target="_blank"
                                                        className="_0088ff"
                                                      >
                                                        {orderWP.pos_order_code}
                                                      </a>
                                                    </td>
                                                    <td className="text-center">
                                                      {switchMoon(
                                                        orderWP.pos_detail.financial.tag,
                                                        orderWP.pos_detail.financial.description,
                                                        5
                                                      )}
                                                    </td>
                                                    <td className="text-center">
                                                      {switchMoon(
                                                        orderWP.pos_detail.fulfillment.tag,
                                                        orderWP.pos_detail.fulfillment.description,
                                                        6
                                                      )}
                                                    </td>
                                                    <td className="text-center">
                                                      {switchMoon(
                                                        orderWP.pos_detail.refund.tag,
                                                        orderWP.pos_detail.refund.description,
                                                        7
                                                      )}
                                                    </td>
                                                    <td className="text-center">
                                                      {switchMoon(
                                                        orderWP.pos_detail.cancel.tag,
                                                        orderWP.pos_detail.cancel.description,
                                                        8
                                                      )}
                                                    </td>
                                                  </tr>
                                                  <tr>
                                                    <td colSpan={6}>
                                                      <div className="btn-groups">
                                                        <SWButton
                                                          color="primary"
                                                          disabled={Boolean(sync)}
                                                          className="m-0"
                                                          onClick={e => {
                                                            onClickSync([order.id]);
                                                            e.stopPropagation();
                                                          }}
                                                        >
                                                          Đồng bộ dữ liệu ngay
                                                        </SWButton>
                                                      </div>
                                                    </td>
                                                  </tr>
                                                </>
                                              ) : null}
                                            </tbody>
                                          </>
                                        ) : checkOrderDetailEmpty(orderErr) ? (
                                          <>
                                            <thead>
                                              <tr>
                                                <th>Mã sản phẩm</th>
                                                <th>Sản phẩm</th>
                                                <th className="text-left">Tùy chọn</th>
                                                <th className="text-left" colSpan={2}>
                                                  Trạng thái
                                                </th>
                                              </tr>
                                            </thead>
                                            <tbody>
                                              {orderErr.product_errors.map((oError, i) => {
                                                return orderErr.sync_type === 'order_web_to_pos' ? (
                                                  <tr key={oError.id}>
                                                    <td>
                                                      <a
                                                        href={oError.web_url}
                                                        rel="noopener noreferrer"
                                                        target="_blank"
                                                        className="_08F"
                                                      >
                                                        {oError.web_sku}
                                                      </a>
                                                    </td>
                                                    <td>{oError.web_product_name}</td>
                                                    <td className="text-left">{oError.web_attribute_title}</td>
                                                    {switchStatusDetail(
                                                      oError.status,
                                                      oError.id,
                                                      oError.web_product_name
                                                    )}
                                                    <td>
                                                      {/* <button
                                                        disabled={proSync}
                                                        className="sync"
                                                        onClick={() =>
                                                          onClickSyncProduct(oError.id, order, oError.status)
                                                        }
                                                      >
                                                        <FontAwesomeIcon
                                                          icon={faSync}
                                                          size="1x"
                                                          color="#0088FF"
                                                          cursor="pointer"
                                                          className={`${proSync ? 'syncing' : ''}`}
                                                        />
                                                        
                                                      </button> */}
                                                      {/* <div className="action-status">
                                                        <button
                                                          className="action-connect-link action-btn"
                                                          data-tip
                                                          data-for={`btn-status-connect-${oError.id}`}
                                                          onClick={() =>
                                                            onClickSyncProduct(oError.id, order, oError.status)
                                                          }
                                                        >
                                                          <LinkIcon />
                                                        </button>
                                                        <ReactTooltip
                                                          id={`btn-status-connect-${oError.id}`}
                                                          className="tooltip-custom"
                                                          border
                                                          borderColor="#E8EAEB"
                                                          textColor="#FFF"
                                                          backgroundColor="#182537"
                                                          effect="solid"
                                                          delayShow={200}
                                                        >
                                                          <span>Liên kết</span>
                                                        </ReactTooltip>
                                                      </div> */}
                                                      <div className="btn-groups">
                                                        <SWButton
                                                          color="primary"
                                                          disabled={Boolean(sync)}
                                                          className="m-0"
                                                          onClick={() =>
                                                            onClickSyncProduct(oError.id, order, oError.status, i)
                                                          }
                                                        >
                                                          Liên kết sản phẩm
                                                        </SWButton>
                                                      </div>
                                                    </td>
                                                  </tr>
                                                ) : (
                                                  <tr key={oError.id}>
                                                    <td>
                                                      <a
                                                        href={oError.pos_url}
                                                        rel="noopener noreferrer"
                                                        target="_blank"
                                                        className="_08F"
                                                      >
                                                        {oError.pos_sku}
                                                      </a>
                                                    </td>
                                                    <td>{oError.pos_product_name}</td>
                                                    <td className="text-left">{oError.pos_attribute_title}</td>
                                                    {switchStatusDetail(
                                                      oError.status,
                                                      oError.id,
                                                      oError.pos_product_name
                                                    )}
                                                    <td className="text-right">
                                                      <button
                                                        disabled={proSync}
                                                        className="sync"
                                                        onClick={() =>
                                                          onClickSyncProduct(oError.id, order, oError.status, i)
                                                        }
                                                      >
                                                        <FontAwesomeIcon
                                                          icon={faSync}
                                                          size="1x"
                                                          color="#0088FF"
                                                          cursor="pointer"
                                                          className={`${proSync ? 'syncing' : ''}`}
                                                        />
                                                        Đồng bộ
                                                      </button>
                                                    </td>
                                                  </tr>
                                                );
                                              })}
                                            </tbody>
                                          </>
                                        ) : (
                                          <tbody>
                                            <tr>
                                              <td className="text-center text-danger">
                                                {orderErr.internal_message ??
                                                  order.error?.message_error ??
                                                  'Đơn hàng đã bị xóa hoặc không tồn tại!'}
                                              </td>
                                            </tr>
                                          </tbody>
                                        )}
                                      </table>
                                    </div>
                                  )}
                                </>
                              ) : (
                                ''
                              )}
                            </td>
                          </tr>
                        </React.Fragment>
                      );
                    })}
                  </tbody>
                </table>
              </div>
              <div className="pagination">
                <div>
                  Từ {page > 1 ? (page - 1) * itemPerPage : 1} đến{' '}
                  {page * itemPerPage < totalElements ? page * itemPerPage : totalElements} trên tổng {totalElements}
                </div>
                <div className="d-flex align-items-center">
                  Hiển thị
                  <div className="item-per-page">
                    <SWButton onClick={() => setOpenItemPerPage(() => !openItemPerPage)}>
                      {itemPerPage}
                      <CaretDownIcon className="icon-drop" />
                    </SWButton>
                    {openItemPerPage ? (
                      <div className="popover-filter filter-radio" ref={ref}>
                        {itemPerPages.map((itemPage, index) => (
                          <div
                            key={index}
                            onClick={e => handleClickChoosePage(itemPage)}
                            className={`${itemPerPage === itemPage ? 'active-item-per-page' : ''}`}
                          >
                            {itemPage}
                          </div>
                        ))}
                      </div>
                    ) : (
                      ''
                    )}
                  </div>
                  kết quả
                </div>
                <div>
                  <SWebPagination page={page} setPage={setPage} totalPage={totalPage} />
                </div>
              </div>
            </div>
          ) : status === '' && query === '' && !checkCallApi ? (
            <NoData
              title="Cửa hàng chưa có đơn hàng nào để hiển thị"
              content={
                <div>
                  Vui lòng tạo đơn hàng trên Web để liên kết và quản lý đồng bộ với Omni{' '}
                  <a
                    href={`https://${alias}.mysapo.net/admin/orders`}
                    rel="noopener noreferrer"
                    target="_blank"
                    className="_08F"
                    onClick={e => e.stopPropagation()}
                  >
                    tại đây
                  </a>
                </div>
              }
            />
          ) : (
            <NoData
              title="Không tìm thấy đơn hàng phù hợp với điều kiện tìm kiếm"
              content="Thử thay đổi điều kiện lọc hoặc từ khóa tìm kiếm"
            />
          )}
        </>
      )}
      {/* <Modal
        isOpen={modalAction}
        toggle={closeModalAction}
        className="modal-confirm"
        id="modal-action"
        autoFocus={false}
      >
        <ModalHeader toggle={closeModalAction} className="modal--header">
          {actionType.value} đơn hàng
        </ModalHeader>
        <ModalBody className="modal--body">
          Thao tác này sẽ {actionType.value.toLowerCase()} đơn hàng bạn đã chọn
        </ModalBody>
        <ModalFooter className="modal--footer">
          <SWButton onClick={closeModalAction}>Hủy</SWButton>
          <SWButton color="primary" onClick={() => onSubmitAction(listChecked)}>
            Đồng ý
          </SWButton>
        </ModalFooter>
      </Modal> */}
    </>
  );
}
