import React from 'react';
import './dashboard.scss';
import PieChart from '../../shared/charts/pieChart/pie-chart';
import NextIcon from '../../assets/img/icons/right.svg';
import PieChart2 from '../../shared/charts/pieChart/pie-chart2';
import PieChart3 from '../../shared/charts/pieChart/pie-chart3';
export function DataInfo(props: any) {
  const { posStatistic, webStatistic, orderStatistic } = props ? props : null;
  return (
    <div style={{ width: '72%' }} className="card card-dashboard-left pl-0 pr-0">
      <div className="card-dashboard-header">
        <h3 style={{ fontSize: '1.7rem' }}>Thông tin đồng bộ dữ liệu</h3>
      </div>

      <div className="card-dashboard-body">
        <div className="d-flex flex-row mt-5 align-items-center justify-content-between chart-div">
          <PieChart2 type={'sapo-chart'} typeAuthor={'t'} posStatistic={posStatistic} />
          <PieChart type={'web-chart'} typeAuthor={'t'} webStatistic={webStatistic} />
          <PieChart3 type={'don-hang-chart'} typeAuthor={'t'} orderStatistic={orderStatistic} />
        </div>

        <div className="d-flex flex-row align-items-center justify-content-center mt-5">
          <span className="round" style={{ backgroundColor: '#0088ff' }}></span> Đã liên kết
          <span className="round" style={{ backgroundColor: '#526680' }}></span> Chưa liên kết
          <span className="round" style={{ backgroundColor: '#e83c19' }}></span> Lỗi liên kết
        </div>
      </div>

      <div className="card-dashboard-footer pl-4 pr-4">
        <h4 className="support-title">Trang hỗ trợ</h4>

        <a
          className="d-flex flex-row align-items-center"
          href="https://support.sapo.vn/huong-dan-cai-dat-kenh-ban-hang-tren-website"
          rel="noreferrer"
          target="_blank"
        >
          <NextIcon className="mr-3 icon-per" />
          Hướng dẫn cách cài đặt kênh Web
        </a>
      </div>
    </div>
  );
}

export default DataInfo;
