import React, { useEffect, useState } from 'react';
import DashBoardService from '../../services/dashboard-services';
import './dashboard.scss';
import { OrderStatistic, PosProductStatistic, ProductStatistic, WebProductStatistic } from './data';
import DataInfo from './data-info';
import DataResult from './data-result';

export function DashBoard(props: any) {
  const [orderStatistic, setOrderStatistic] = useState<OrderStatistic>(null);
  const [productStatistic, setProductStatistic] = useState<ProductStatistic>(null);
  const [posStatistic, setPosStatistic] = useState<PosProductStatistic>(null);
  const [webStatistic, setWebStatistic] = useState<WebProductStatistic>(null);
  const [webAlias, setWebAlias] = useState<string>('');
  const [createdOn, setCreatedOn] = useState<string>('');
  const [webDomain, setWebDomain] = useState<string>('');
  const [syncStatus, setSyncStatus] = useState<string>('');
  function getDashboard() {
    DashBoardService.getDashBoard().subscribe(
      data => {
        if (data) {
          setOrderStatistic(data.order_statistic);
          setProductStatistic(data.product_statistic);
          setPosStatistic(data.pos_product_statistic);
          setWebStatistic(data.web_product_statistic);
          setWebAlias(data.web_store_alias);
          setCreatedOn(data.created_on);
          setWebDomain(data.web_domain);
          setSyncStatus(data.sync_status);
          if (data.sync_status !== 'success') {
            setTimeout(() => {
              getDashboard();
            }, 1500);
          }
        }
      },
      error => {
        setTimeout(() => {
          getDashboard();
        }, 2000);
      }
    );
  }
  useEffect(() => {
    getDashboard();
  }, []);
  return (
    <div id="dashboard-root">
      <div className="dashboard-content flex-row d-flex justify-content-between">
        <DataInfo orderStatistic={orderStatistic} posStatistic={posStatistic} webStatistic={webStatistic} />

        <DataResult
          webAlias={webAlias}
          createdOn={createdOn}
          webDomain={webDomain}
          orderStatistic={orderStatistic}
          productStatistic={productStatistic}
          syncStatus={syncStatus}
        />
      </div>
    </div>
  );
}

export default DashBoard;
