import { IItem } from '../../shared/model/base-data';

export interface IErrors {
  id?: number;
  time?: string;
  event?: string;
  item?: string;
  error?: string;
}

export const navModel: IItem[] = [
  {
    key: '',
    value: 'Tất cả lỗi'
  },
  {
    key: 'product',
    value: 'Lỗi sản phẩm'
  },
  {
    key: 'order',
    value: 'Lỗi đơn hàng'
  }
];
export const filterConditionModel: IItem[] = [
  {
    key: '',
    value: ' Tất cả'
  },
  {
    key: 'product',
    value: 'Sản phẩm'
  },
  {
    key: 'order',
    value: 'Đơn hàng'
  }
];

export const navModelActionProduct: IItem[] = [
  {
    key: 're_sync',
    value: 'Đồng bộ lại'
  },
  {
    key: 're_connect',
    value: 'Bật đồng bộ'
  },
  {
    key: 'stop_connect',
    value: 'Ngắt đồng bộ'
  },
  {
    key: 'remove_connect',
    value: 'Xóa liên kết sản phẩm'
  }
];
