import React, { useContext } from 'react';

import HelpIcon from '../assets/img/icons/help.svg';

export interface TTip {
  title?: string;
  description?: string;
  descriptions?: string[];
  link?: string;
  example?: string;
  examples?: string[];
}

export const TipContext = React.createContext({
  tip: {} as TTip,
  setTip: (tip: TTip) => {}
});

export const TipContent = () => {
  const { tip } = useContext(TipContext);
  return (
    <div className="card">
      <div className="card-config-header">
        <span>{tip.title}</span>
      </div>
      <div className="card-config-footer pb-0">
        {tip.description}{' '}
        {tip.descriptions ? (
          <>
            {tip.descriptions.map((item, i) => (
              <div key={i}>{item}</div>
            ))}
          </>
        ) : null}
        {tip.link ? (
          <a className="ml-0" href={tip.link} rel="noopener noreferrer" target="_blank">
            tại đây
          </a>
        ) : null}
      </div>
      {tip.example || tip.examples ? (
        <div className="card-config-footer d-flex flex-column pt-0">
          <div style={{ fontWeight: 700 }}>Ví dụ</div>
          {tip.example ? <div>{tip.example}</div> : null}
          {tip.examples ? tip.examples.map((item, i) => <div key={i}>{item}</div>) : null}
        </div>
      ) : null}
    </div>
  );
};

const Tip = ({ title, description, descriptions, link, example, examples }: TTip) => {
  const { setTip } = useContext(TipContext);
  return (
    <span
      style={{
        cursor: 'pointer',
        marginLeft: '2px',
        marginRight: '2px'
      }}
      onClick={e => {
        e.stopPropagation();
        e.preventDefault();
        setTip({
          title,
          description,
          descriptions,
          link,
          example,
          examples
        });
      }}
    >
      <HelpIcon style={{ height: '1em', width: '1em' }} />
    </span>
  );
};

export default Tip;
