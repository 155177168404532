import React from 'react';
import PropTypes from 'prop-types';
import CheckMark from '../../assets/img/icons/check-mark-black-outline.svg';
import CheckMarkActive from '../../assets/img/icons/check-mark-white-outline.svg';
import './stepper.scss';
export function Step(props: any) {
  const getStyles = () => {
    const {
      activeColor,
      defaultColor,
      circleFontColor,
      circleFontColorActive,
      completeTitleColor,
      defaultTitleColor,
      size,
      circleFontSize,
      circleTop,
      titleTop,
      width,
      activeOpacity,
      defaultOpacity,
      completeTitleOpacity,
      activeTitleOpacity,
      defaultTitleOpacity,
      barStyle,
      defaultBarColor,
      activeBorderColor,
      activeBorderStyle,
      defaultBorderWidth
    } = props;

    return {
      step: {
        width: `${width}%`,
        display: 'table-cell',
        position: 'relative' as 'relative',
        paddingTop: circleTop
      },
      circle: {
        position: 'relative' as 'relative',
        width: size,
        height: size,
        margin: '0 auto',
        backgroundColor: defaultColor,
        borderRadius: '50%',
        textAlign: 'center' as 'center',
        padding: 1,
        fontSize: circleFontSize,
        color: circleFontColor,
        display: 'block',
        opacity: defaultOpacity,
        borderWidth: 1,
        borderColor: '#ccc',
        borderStyle: 'solid',
        zIndex: 2
      },
      activeCircle: {
        // transition: "all 2s ease",
        // WebkitTransition: "all 2s ease",
        backgroundColor: activeColor,
        opacity: activeOpacity,
        borderWidth: activeBorderColor ? defaultBorderWidth : 0,
        borderColor: activeBorderColor,
        borderStyle: activeBorderStyle
      },
      completedCircle: {
        // transition: "all 2s ease",
        // WebkitTransition: "all 2s ease",
      },
      index: {
        lineHeight: `${size + circleFontSize / 4}px`,
        color: circleFontColor
      },
      indexActive: {
        lineHeight: `${size + circleFontSize / 4}px`,
        color: circleFontColorActive
      },
      title: {
        marginTop: titleTop,
        fontWeight: 400 as 400,
        textAlign: 'center' as 'center',
        display: 'block',
        color: defaultTitleColor,
        opacity: defaultTitleOpacity
      },
      activeTitle: {
        transition: 'all 1s ease',
        WebkitTransition: 'all 1s ease',
        color: '#212b35',
        opacity: activeTitleOpacity
      },
      completedTitle: {
        transition: 'all 1s ease',
        WebkitTransition: 'all 1s ease',
        color: completeTitleColor,
        opacity: completeTitleOpacity
      },
      leftBar: {
        position: 'absolute' as 'absolute',
        top: circleTop + size / 2,
        height: 1,
        borderTopStyle: barStyle,
        borderTopWidth: 2,
        borderTopColor: defaultBarColor,
        left: 0,
        right: '50%',
        marginRight: 0,
        opacity: defaultOpacity
      },
      rightBar: {
        position: 'absolute' as 'absolute',
        top: circleTop + size / 2,
        height: 1,
        borderTopStyle: barStyle,
        borderTopWidth: 2,
        borderTopColor: defaultBarColor,
        right: 0,
        left: '50%',
        marginLeft: 0,
        opacity: defaultOpacity
      },
      rightBarProcess: {
        position: 'absolute' as 'absolute',
        top: circleTop + size / 2,
        height: 1,
        borderTopStyle: barStyle,
        borderTopWidth: 2,
        borderTopColor: defaultBarColor,
        right: 0,
        left: '50%',
        width: '100%',
        //marginLeft: size / 2 + lineMarginOffset,
        marginLeft: 0,
        opacity: defaultOpacity,
        zIndex: 1
      },
      completedBar: {
        borderTopStyle: barStyle,
        borderTopWidth: 2,
        transition: 'all 1s ease',
        WebkitTransition: 'all 1s ease',
        borderTopColor: '#08f',
        opacity: 1
      }
    };
  };

  const { title, index, active, completed, first, isLast, onClick, indexHasActive, setIndexHasActive } = props;

  const handleClick = (index: any) => {
    if (indexHasActive + 1 === index) {
      setIndexHasActive(index);
      onClick(index);
    }

    if (indexHasActive >= index) {
      onClick(index);
    }
  };

  const styles = getStyles();
  const circleStyle = Object.assign(
    styles.circle,
    completed ? styles.completedCircle : {},
    active ? styles.activeCircle : {}
  );
  const titleStyle = Object.assign(
    styles.title,
    completed ? styles.completedTitle : {},
    active ? styles.activeTitle : {}
  );

  const rightStyleProcess = Object.assign(styles.rightBarProcess, completed ? styles.completedBar : {});

  const stepContent = index === 5 ? active || completed ? <CheckMarkActive /> : <CheckMark /> : index + 1;

  return (
    <div id="step-root" style={styles.step}>
      <div
        className={`d-flex align-items-center justify-content-center step-container step-main${
          active || completed ? '-active' : ''
        }`}
        style={circleStyle}
        onClick={() => handleClick(index)}
      >
        {active || completed ? (
          <span className="text-title" style={styles.index}>
            {stepContent}
          </span>
        ) : (
          <span className="step-content" style={styles.indexActive}>
            {stepContent}
          </span>
        )}
      </div>
      {active || completed ? (
        <span className="step-tag-title" style={titleStyle}>
          {title}
        </span>
      ) : (
        <div className="step-title" style={titleStyle}>
          {title}
        </div>
      )}
      {!first && <div className="step-line-process-left" style={styles.leftBar}></div>}
      {!isLast && <div className="step-line-process-right" style={styles.rightBar}></div>}
      {index === 0 && <div className="step-line-process" style={rightStyleProcess}></div>}
      {index === 1 && <div className="step-line-process" style={rightStyleProcess}></div>}
      {index === 2 && <div className="step-line-process" style={rightStyleProcess}></div>}
      {index === 3 && <div className="step-line-process" style={rightStyleProcess}></div>}
    </div>
  );
}

Step.defaultProps = {
  activeColor: '#5096FF',
  completeColor: '#5096FF',
  defaultColor: '#ffffff',
  activeTitleColor: '#000',
  completeTitleColor: '#000',
  defaultTitleColor: '#757575',
  circleFontColor: '#FFF',
  circleFontColorActive: '#212b35',
  size: 32,
  circleFontSize: 16,
  titleFontSize: 16,
  circleTop: 24,
  titleTop: 8,
  defaultBarColor: '#E0E0E0',
  barStyle: 'solid',
  borderStyle: 'solid',
  lineMarginOffset: 4,
  defaultBorderWidth: 3
};

Step.propTypes = {
  width: PropTypes.number.isRequired,
  activeColor: PropTypes.string,
  completeColor: PropTypes.string,
  defaultColor: PropTypes.string,
  activeTitleColor: PropTypes.string,
  completeTitleColor: PropTypes.string,
  defaultTitleColor: PropTypes.string,
  circleFontColor: PropTypes.string,
  circleFontColorActive: PropTypes.string,
  size: PropTypes.number,
  indexHasActive: PropTypes.number,
  circleFontSize: PropTypes.number,
  titleFontSize: PropTypes.number,
  circleTop: PropTypes.number,
  titleTop: PropTypes.number,
  title: PropTypes.string,
  index: PropTypes.number,
  active: PropTypes.bool,
  completed: PropTypes.bool,
  first: PropTypes.bool,
  isLast: PropTypes.bool,
  completeOpacity: PropTypes.string,
  activeOpacity: PropTypes.string,
  defaultOpacity: PropTypes.string,
  completeTitleOpacity: PropTypes.string,
  activeTitleOpacity: PropTypes.string,
  defaultTitleOpacity: PropTypes.string,
  barStyle: PropTypes.string,
  defaultBarColor: PropTypes.string,
  completeBarColor: PropTypes.string,
  defaultBorderColor: PropTypes.string,
  completeBorderColor: PropTypes.string,
  activeBorderColor: PropTypes.string,
  defaultBorderStyle: PropTypes.string,
  completeBorderStyle: PropTypes.string,
  activeBorderStyle: PropTypes.string,
  lineMarginOffset: PropTypes.number,
  defaultBorderWidth: PropTypes.number,
  onClick: PropTypes.func,
  setIndexHasActive: PropTypes.func
};
export default Step;
