import { AxiosResponse } from 'axios';
import { from, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IProduct } from '../../components/dashboard/data';
import confAxios from '../../core/config/conf-axios';
import { GridData } from '../../shared/model/grid-data';

const ProductService = {
  getListProduct: (
    source: string,
    size: number,
    page: number,
    sort?: string,
    keyword?: string,
    status?: string
  ): Observable<GridData<IProduct>> => {
    const request = confAxios.get<GridData<IProduct>>(`/products`, {
      params: { source, size, page, sort, keyword, status }
    });
    return from(request).pipe(
      map((res: AxiosResponse<GridData<IProduct>>) => {
        return {
          ...res.data
        };
      })
    );
  },
  getProductDetail: (id: number): Observable<IProduct> => {
    const request = confAxios.get<IProduct>(`/products/${id}`);
    return from(request).pipe(
      map((res: AxiosResponse<IProduct>) => {
        return {
          ...res.data
        };
      })
    );
  },
  changeStatus: (id: number[], type: string) => {
    const data = {
      ids: id,
      do_type: type
    };
    const request = confAxios.post(`/products/do-something`, data);
    return from(request).pipe(
      map(res => {
        return {
          ...res
        };
      })
    );
  },
  syncAllInventory: (): Observable<any> => {
    const request = confAxios.post<any>(`/products/re-sync-all-inventory`);
    return from(request).pipe(
      map((res: AxiosResponse<any>) => {
        return {
          ...res.data,
          status: res.status
        };
      })
    );
  }
};

export default ProductService;
