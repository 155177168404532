import React, { useContext, useEffect, useState } from 'react';
import './install.scss';
import Stepper from '../../shared/stepper/index';
import NextIcon from '../../assets/img/icons/right.svg';
import Step1 from './main/step1';
import { IConfig } from '../config/data';
import ConfigService from '../../services/config-services';
import SWButton from '../../shared/sw-button/SWButton';
import Step2 from './main/step2';
import Step3 from './main/step3';
import Step4 from './main/step4';
import Step5 from './main/step5';
import Step0 from './main/step0';
import Finish from './main/finish';
import Welcome from './main/welcome';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { withRouter } from 'react-router-dom';
import auth from '../../auth';
import { AppContext, IAppCtx } from '../../App';
import urlUtils from '../../core/urlUtils';

export function GettingStart() {
  const appCtx = useContext<IAppCtx>(AppContext);
  const currentConfig = appCtx.config;

  const [isWelcome, setIsWelcome] = useState(true);
  const [currentStep, setCurrentStep] = useState(0);
  const [indexHasActive, setIndexHasActive] = useState<number>(currentStep);

  const [isIntro, setIsIntro] = useState(true);
  const [isFinish, setIsFinish] = useState(false);
  const [config, setConfig] = useState<IConfig>(currentConfig);

  const [salePriceId, setSalePriceId] = useState(currentConfig.sale_price_list_id);
  const [comparePriceId, setComparePriceId] = useState(currentConfig.compare_price_list_id);
  const [posPrices, setPosPrices] = useState(currentConfig.pos_prices || []);

  const [orderLocationId, setOrderLocationId] = useState(currentConfig.order_location_id);
  const [productLocationId, setProductLocationId] = useState(currentConfig.product_location_id);
  const [posLocation, setPosLocation] = useState(currentConfig.pos_locations || []);
  const [location, setLocation] = useState([]);

  const [customerIdentifier, setCustomerIdentifier] = useState(currentConfig.customer_identifier);

  const [webtoPos, setWebtoPos] = useState<boolean>(
    typeof currentConfig.auto_sync_current_product_web_to_pos !== 'undefined'
      ? currentConfig.auto_sync_current_product_web_to_pos
      : true
  );
  const [postoWeb, setPostoWeb] = useState<boolean>(
    typeof currentConfig.auto_sync_current_product_pos_to_web !== 'undefined'
      ? currentConfig.auto_sync_current_product_pos_to_web
      : true
  );

  const [showingAlert] = useState(false);
  const [textAlert] = useState('');

  useEffect(() => {
    if (currentStep > indexHasActive) setIndexHasActive(currentStep);
  }, [currentStep]);

  useEffect(() => {
    let data: IConfig = {
      sale_price_list_id: salePriceId,
      compare_price_list_id: comparePriceId,
      pos_prices: posPrices,

      order_location_id: orderLocationId,
      product_location_id: productLocationId,
      pos_locations: posLocation,
      location_ids: location,
      reference: 'install_page',
      sync_status_value: 'wait_config',
      auto_sync_current_product_web_to_pos: webtoPos,
      auto_sync_current_product_pos_to_web: postoWeb,
      customer_identifier: customerIdentifier,
      order_location_mappings: []
      // reference: "install_page",
    };

    setConfig(data);
  }, [
    customerIdentifier,
    comparePriceId,
    salePriceId,
    posPrices,
    orderLocationId,
    productLocationId,
    location,
    posLocation,
    webtoPos,
    postoWeb
  ]);

  let steps = [
    {
      title: 'Thiết lập chi nhánh',
      href: 'http://example1.com',
      onClick: e => {
        e.preventDefault();
      }
    },
    {
      title: 'Đồng bộ giá',
      href: 'http://example3.com',
      onClick: e => {
        e.preventDefault();
      }
    },
    {
      title: 'Dữ liệu sản phẩm hiện có',
      href: 'http://example4.com',
      onClick: e => {
        e.preventDefault();
      }
    },
    {
      title: 'Đồng bộ khách hàng',
      href: 'http://example5.com',
      onClick: e => {
        e.preventDefault();
      }
    },
    {
      title: 'Hoàn thành',
      href: 'http://example6.com',
      onClick: e => {
        e.preventDefault();
      }
    }
  ];
  const renderStep = () => {
    switch (currentStep) {
      case 0:
        return (
          <Step1
            title={'Thiết lập danh sách chi nhánh đồng bộ'}
            setLocation={name => setLocation(name)}
            location={location}
            setPosLocation={name => setPosLocation(name)}
            posLocation={posLocation}
            setOrderLocationId={name => setOrderLocationId(name)}
            orderLocationId={orderLocationId}
            setProductLocationId={name => setProductLocationId(name)}
            productLocationId={productLocationId}
          />
        );
      case 1:
        return (
          <Step2
            title={'Đồng bộ giá'}
            setSalePriceId={name => setSalePriceId(name)}
            salePriceId={salePriceId}
            setComparePriceId={name => setComparePriceId(name)}
            comparePriceId={comparePriceId}
            setPosPrices={name => setPosPrices(name)}
            posPrices={posPrices}
          />
        );
      case 2:
        return (
          <Step3
            title={'Xử lý dữ liệu sản phẩm hiện có trước đồng bộ'}
            setWebtoPos={name => setWebtoPos(name)}
            webtoPos={webtoPos}
            setPostoWeb={name => setPostoWeb(name)}
            postoWeb={postoWeb}
          />
        );
      case 3:
        return (
          <Step4
            title={'Đồng bộ thông tin khách hàng'}
            setCustomerIdentifier={name => setCustomerIdentifier(name)}
            customerIdentifier={customerIdentifier}
          />
        );
      case 4:
        return (
          <Step5
            title={'Thông tin chi tiết các cấu hình đồng bộ'}
            location={location}
            posLocation={posLocation}
            orderLocationId={orderLocationId}
            productLocationId={productLocationId}
            salePriceId={salePriceId}
            comparePriceId={comparePriceId}
            posPrices={posPrices}
            webtoPos={webtoPos}
            postoWeb={postoWeb}
            customerIdentifier={customerIdentifier}
          />
        );
    }
  };

  const onClickNext = () => {
    if (currentStep === 0) {
      if (location.length === 0) {
        SapoApp.flashNotice('Vui lòng chọn chi nhánh');
      } else {
        setCurrentStep(currentStep + 1);
      }
    }
    // Cai dat app
    if (currentStep === 4) {
      const currentAuth = auth.get();
      //todo: why clear?
      //auth.clear();
      ConfigService.updateConfig(config).subscribe(data => {
        if (data) {
          if (data.status === 200) {
            setIsFinish(true);
            auth.set(currentAuth);
          }
        }
      });
    }
    if (currentStep === 1 || currentStep === 2 || currentStep === 3) {
      setCurrentStep(currentStep + 1);
    }
  };
  const onClickBack = () => {
    if (currentStep !== 0) {
      setCurrentStep(currentStep - 1);
    }
  };
  const onClickCancel = () => {
    const appStoreAlias = localStorage.getItem('appStoreAlias');
    if (appStoreAlias) {
      window.parent.location.href = `${urlUtils.resolvePosDomain(appStoreAlias)}/admin/settings/channels`;
    }
  };
  const onClickChooseStep = e => {
    if (e === 1) {
      if (location.length === 0) {
        SapoApp.flashNotice('Vui lòng chọn chi nhánh');
      } else {
        setCurrentStep(e);
      }
    } else {
      setCurrentStep(e);
    }
  };
  const [isOpenManualPopup, setOpenManualPopup] = useState(false);
  const closeModalAction = () => {
    setTimeout(() => { }, 500);
    setOpenManualPopup(false);
  };

  return (
    <div id="install-root">
      <div className={`alert ${showingAlert ? 'alert-shown' : 'alert-hidden'} d-flex justify-content-center`}>
        <span>{textAlert}</span>
      </div>
      {isWelcome ? (
        <Welcome setIsWelcome={e => setIsWelcome(e)} />
      ) : isIntro ? (
        <Step0
          title={'Lựa chọn phương thức cài đặt kênh mà bạn muốn'}
          setIsIntro={e => setIsIntro(e)}
          setIsFinish={e => setIsFinish(e)}
        />
      ) : isFinish ? (
        <Finish />
      ) : (
        <React.Fragment>
          <div className="card col-md-12">
            <Stepper
              steps={steps}
              activeStep={currentStep}
              disabledSteps={[]}
              onClick={e => onClickChooseStep(e)}
              indexHasActive={indexHasActive}
              setIndexHasActive={e => setIndexHasActive(e)}
            />
          </div>

          <div className="card col-md-12">
            {renderStep()}
            {currentStep === 2 ? (
              <div className="cd-flex flex-row mt-3">
                <FontAwesomeIcon icon={faExclamationTriangle} size="1x" className="mr-3" color="red" />
                <i>
                  Chú ý: Mã SKU là duy nhất định danh sản phẩm giữa hai hệ thống, cần lưu ý cẩn thận khi làm dữ liệu để
                  thông tin đồng bộ được chính xác
                </i>
              </div>
            ) : null}

            <div className="card-install-body d-flex flex-row align-items-center w-100">
              {currentStep !== 0 && (
                <SWButton className="ml-0 button-sub" onClick={() => onClickBack()}>
                  Quay lại
                </SWButton>
              )}
              <SWButton className="ml-2 button-sub" onClick={() => setOpenManualPopup(true)}>
                Hủy
              </SWButton>
              <SWButton className="ml-2" color="primary" onClick={() => onClickNext()}>
                Tiếp
              </SWButton>
            </div>
            <div className="card-install-footer">
              <h4 className="support-title">Trang hỗ trợ</h4>

              <a
                className="d-flex flex-row align-items-center"
                href="https://support.sapo.vn/huong-dan-cai-dat-kenh-ban-hang-tren-website"
                rel="noreferrer"
                target="_blank"
              >
                <NextIcon className="mr-3 icon-per" />
                Hướng dẫn cách cài đặt kênh Web
              </a>
            </div>
          </div>
        </React.Fragment>
      )}
      <Modal
        isOpen={isOpenManualPopup}
        //toggle={closeModalAction}
        className="modal-confirm"
        id="modal-action-config"
        autoFocus={false}
      >
        <ModalHeader toggle={closeModalAction} className="modal--header">
          Hủy thiết lập đồng bộ Web
        </ModalHeader>
        <ModalBody className="modal--body border-bottom">
          <span>
            Thao tác này sẽ hủy các cấu hình mà bạn đã lựa chọn. Bạn có chắc muốn hủy các thiết lập này không?
          </span>
        </ModalBody>
        <ModalFooter className="modal--footer">
          <SWButton onClick={closeModalAction}>Thoát</SWButton>
          <SWButton
            color="destroy"
            onClick={() => {
              setOpenManualPopup(false);
              onClickCancel();
            }}
          >
            Xác nhận
          </SWButton>
        </ModalFooter>
      </Modal>
    </div>
  );
}
export default withRouter(GettingStart);
