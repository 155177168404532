import React from 'react';
import { Row, Col, Input, FormGroup, Label } from 'reactstrap';
import { Card, CardHeader, CardBody } from 'components/Card';
import Tip from 'components/Tip';
import Select from 'components/Select';
import Checkbox from 'components/Checkbox';

export interface IOrderConfig {
  webOrderSyncEnable: boolean;
  posOrderSyncEnable: boolean;
  webOrderManagementEnable: boolean;
  syncOrderNameWebToPos: boolean;
  posOrderInitStatus: string;
  posAccounts: {
    id: number;
    label: string;
  }[];
  posOrderAccount: number;
}

export default ({ config, onConfigChange }: { config: IOrderConfig; onConfigChange: (v: IOrderConfig) => any }) => {
  const handleConfigChange = (config: IOrderConfig) => {
    onConfigChange(config);
  };

  const isValidAccount =
    config.posOrderAccount && config.posAccounts.findIndex(acc => acc.id === config.posOrderAccount) >= 0;

  const accounts = config.posAccounts.map(acc => ({
    value: acc.id + '',
    label: acc.label
  }));

  if (!isValidAccount) {
    accounts.push({
      value: config.posOrderAccount + '',
      label: 'Nhân viên không tồn tại'
    });
  }

  return (
    <Card>
      <CardHeader title="Thiết lập đồng bộ dữ liệu đơn hàng" />
      <CardBody>
        <FormGroup>
          <FormGroup>
            <Checkbox
              checked={config.webOrderSyncEnable}
              onChange={e =>
                handleConfigChange({
                  ...config,
                  webOrderSyncEnable: e.target.checked
                })
              }
              label="Đồng bộ tự động đơn hàng tạo ra trên Web về Omni"
            />
            <Tip
              title="Đồng bộ đơn hàng từ Web về Omni"
              descriptions={[
                'Đơn hàng tạo ra trên Web sẽ được đồng bộ về Omni dưới dạng đơn đặt hàng.',
                'Đơn hàng sẽ được đẩy vào chi nhánh được chọn.'
              ]}
            />
          </FormGroup>
          <FormGroup>
            <Checkbox
              checked={config.posOrderSyncEnable}
              onChange={e =>
                handleConfigChange({
                  ...config,
                  posOrderSyncEnable: e.target.checked
                })
              }
              label="Đồng bộ tự động trạng thái đơn hàng từ Omni lên Web"
            />
            <Tip
              title="Đồng bộ trạng thái đơn hàng"
              descriptions={[
                'Các thao tác cập nhật trạng thái đơn hàng trên Omni, bao gồm: Thanh toán, Giao hàng, Hoàn trả, sẽ được đồng bộ sang Web.',
                'Lưu ý: nếu bạn thao tác đơn hàng trên Web, các thao tác này sẽ không được đồng bộ sang Omni và có thể gây sai lệch dữ liệu đồng bộ từ Omni sang Web.'
              ]}
            />
          </FormGroup>
          <FormGroup>
            <Checkbox
              checked={!config.webOrderManagementEnable}
              onChange={e =>
                handleConfigChange({
                  ...config,
                  webOrderManagementEnable: !e.target.checked
                })
              }
              label="Cho phép xử lý đơn hàng thủ công trên Web"
            />
            <Tip
              title="Xử lý đơn hàng thủ công trên Web"
              descriptions={[
                'Các thao tác xử lý đơn hàng tại Web sẽ không được đồng bộ sang Omni và có thể gây sai lệch dữ liệu đồng bộ từ Omni lên Web.',
                'Bạn nên tắt cấu hình này để ẩn các thao tác xử lý đơn hàng tại Web nhằm tránh sai lệch dữ liệu đồng bộ.'
              ]}
            />
            <p style={{ margin: '1em 0 ' }}>
              <strong>Lưu ý</strong>:{' '}
              <em>
                Tự động cập nhật trạng thái đơn từ Omni sang Web và đồng thời cho phép thao tác thủ công bên Web có thể khiến dữ liệu bị sai lệch
              </em>
            </p>
          </FormGroup>
        </FormGroup>
        <hr />
        <FormGroup>
          <p>Đồng bộ trạng thái đơn hàng mới</p>
          <FormGroup check>
            <Label check style={{ cursor: 'pointer' }}>
              <Input
                type="radio"
                name="posOrderInitStatus"
                value="finalized"
                checked={config.posOrderInitStatus === 'finalized'}
                onChange={e =>
                  handleConfigChange({
                    ...config,
                    posOrderInitStatus: e.target.value
                  })
                }
                style={{ cursor: 'pointer' }}
              />
              &nbsp;Đơn hàng từ Web về sẽ được tự động duyệt trên Omni
              <Tip
                title="Trạng thái đơn hàng mới khi đồng bộ từ Web về Omni"
                descriptions={[
                  'Đơn hàng từ Web về Omni sẽ tự động chuyển sang trạng thái duyệt.',
                  'Đơn hàng được duyệt trên Omni sẽ giảm thông số tồn có thể bán của sản phẩm trên Omni.'
                ]}
              />
            </Label>
          </FormGroup>
          <FormGroup check>
            <Label check style={{ cursor: 'pointer' }}>
              <Input
                type="radio"
                name="posOrderInitStatus"
                value="draft"
                checked={config.posOrderInitStatus === 'draft'}
                onChange={e =>
                  handleConfigChange({
                    ...config,
                    posOrderInitStatus: e.target.value
                  })
                }
                style={{ cursor: 'pointer' }}
              />
              &nbsp;Đơn hàng từ Web về không được tự động duyệt trên Omni
              <Tip
                title="Trạng thái đơn hàng mới khi đồng bộ từ Web về Omni"
                descriptions={[
                  'Đơn hàng từ Web về Omni sẽ không tự động được duyệt mà chỉ ở trạng thái đặt hàng.',
                  'Đơn hàng chưa được duyệt trên Omni sẽ không ảnh hưởng tới tồn kho của sản phẩm.'
                ]}
              />
            </Label>
          </FormGroup>
        </FormGroup>

        <Row>
          <Col>
            <FormGroup>
              <Label for="posOrderAccount">
                Lựa chọn nhân viên xử lý đơn hàng
                <Tip
                  title="Nhân viên xử lý đơn hàng"
                  description="Lựa chọn nhân viên xử lý đơn hàng từ Web đồng bộ về Omni"
                />
              </Label>
              <Select
                id="posOrderAccount"
                value={config.posOrderAccount}
                onChange={e =>
                  handleConfigChange({
                    ...config,
                    posOrderAccount: parseInt(e.target.value)
                  })
                }
                options={accounts}
              />
            </FormGroup>
            <FormGroup>
              <Label for="syncOrderNameWebToPos">
                Cấu hình đồng bộ mã đơn hàng
                <Tip title="Đồng bộ mã đơn hàng" description="Mã đơn hàng từ Web sẽ được đồng bộ về Omni" />
              </Label>
              <Checkbox
                checked={config.syncOrderNameWebToPos}
                onChange={e =>
                  handleConfigChange({
                    ...config,
                    syncOrderNameWebToPos: e.target.checked
                  })
                }
                label="Cho phép đồng bộ mã đơn hàng từ Web sang Omni"
              />
            </FormGroup>
          </Col>
          <Col></Col>
        </Row>

        {!isValidAccount ? (
          <p className="text-danger">Nhân viên không tồn tại hoặc đã bị xoá. Vui lòng chọn nhân viên khác</p>
        ) : null}
      </CardBody>
    </Card>
  );
};
