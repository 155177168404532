import React from 'react';
import { FormGroup, Label } from 'reactstrap';
import './data-sync.scss';
import LocationMapping from './LocationMapping';

import { Card, CardHeader, CardBody } from 'components/Card';
import Select, { SelectMultiple } from 'components/Select';
import Tip from 'components/Tip';
import { TLocationMapping } from './data';

export interface TLocationConfig {
  orderLocation?: number;
  productLocation?: number;
  posLocations: {
    id: number;
    label: string;
  }[];
  locations: number[];
  mappings: TLocationMapping[];
  enableMapping: boolean;
}

export default ({
  config,
  onConfigChange
}: {
  config: TLocationConfig;
  onConfigChange: (config: TLocationConfig) => any;
}) => {
  const deletedLocations = config.locations
    .filter(id => config.posLocations.every(l => l.id !== id))
    .map(id => ({
      id,
      label: 'Chi nhánh không tồn tại hoặc đã bị khoá'
    }));
  const allLocations = config.posLocations.concat(deletedLocations);
  const filteredLocations = allLocations
    .filter(l => config.locations.indexOf(l.id) >= 0)
    .map(l => ({
      value: l.id + '',
      label: l.label
    }));
  const posLocationsOptions = allLocations.map(l => ({
    value: l.id,
    label: l.label
  }));
  return (
    <Card id="data-sync-5">
      <CardHeader title="Thiết lập chi nhánh đồng bộ" />
      <CardBody>
        <FormGroup>
          <Label for="location">
            Thiết lập chi nhánh đồng bộ kho với Web
            <Tip
              title="Số lượng chi nhánh Omni"
              description="Chọn số lượng chi nhánh Omni sẽ ảnh hưởng đến số lượng tồn kho sản phẩm sẽ đẩy lên Web."
              examples={[
                'Cửa hàng Omni có 2 chi nhánh A và B. Sản phẩm X có Tồn chi nhánh A = 10, tồn chi nhánh B = 5:',
                '- Nếu chọn chỉ đồng bộ chi nhánh A > Sẽ đẩy tồn kho sản phẩm X lên Web là 10',
                '- Nếu chọn chỉ đồng bộ chi nhánh B > Sẽ đẩy tồn kho sản phẩm X lên Web là 5',
                '- Nếu chọn đồng bộ cả hai chi nhánh A và B > Sẽ đẩy tồn kho sản phẩm X lên Web là 15'
              ]}
            />
          </Label>
          <SelectMultiple
            id="location"
            value={posLocationsOptions.filter(l => config.locations.indexOf(l.value) >= 0)}
            onChange={values => {
              const selectedLocations = values ? values.map(v => v.value) : [];
              const firstSelectedLocation = selectedLocations.length > 0 ? selectedLocations[0] : '';
              onConfigChange({
                ...config,
                locations: selectedLocations,
                productLocation:
                  selectedLocations.findIndex(l => l === config.productLocation) >= 0
                    ? config.productLocation
                    : firstSelectedLocation,
                orderLocation:
                  selectedLocations.findIndex(l => l === config.orderLocation) >= 0
                    ? config.orderLocation
                    : firstSelectedLocation
              });
            }}
            options={posLocationsOptions}
          />
        </FormGroup>

        <FormGroup>
          <Label for="posProductLocation">
            Chi nhánh nhận dữ liệu sản phẩm từ Web
            <Tip
              title="Chi nhánh đồng bộ sản phẩm"
              description="Sản phẩm từ Web tạo mới trên Omni sẽ thiết lập tồn kho tại một chi nhánh nhất định."
              examples={[
                'Cửa hàng Omni có 3 chi nhánh : A, B, C Sản phẩm X được tạo ra trên Web có tồn = 10 và được App đẩy về Omni:',
                '- Khi chọn A, số lượng tồn kho từ Web sẽ đẩy về kho A. Tồn X tại kho A = 10, tồn X tại kho B = 0, tồn X tại kho C = 0.'
              ]}
            />
          </Label>
          <Select
            id="posProductLocation"
            value={config.productLocation}
            onChange={e =>
              onConfigChange({
                ...config,
                productLocation: parseInt(e.target.value)
              })
            }
            options={filteredLocations}
          />
        </FormGroup>

        <FormGroup>
          <Label for="posOrderLocation">
            Chi nhánh nhận đơn hàng từ Web
            <Tip
              title="Chi nhánh đồng bộ đơn hàng"
              descriptions={[
                'Đơn hàng tạo ra trên Web sẽ được đồng bộ về Omni dưới dạng một đơn đặt hàng.',
                'Đơn hàng sẽ được đẩy vào chi nhánh được chọn.'
              ]}
            />
          </Label>
          <Select
            id="posOrderLocation"
            value={config.orderLocation}
            onChange={e =>
              onConfigChange({
                ...config,
                orderLocation: parseInt(e.target.value)
              })
            }
            options={filteredLocations}
          />
        </FormGroup>

        <LocationMapping
          enableMapping={config.enableMapping}
          toggleEnableMapping={() =>
            onConfigChange({
              ...config,
              enableMapping: !config.enableMapping
            })
          }
          locations={allLocations.filter(l => config.locations.indexOf(l.id) >= 0)}
          mappings={config.mappings.filter(m => config.locations.indexOf(m.location_id) >= 0)}
          setMappings={mappings =>
            onConfigChange({
              ...config,
              mappings
            })
          }
        />
      </CardBody>
    </Card>
  );
};
