import { AxiosResponse } from 'axios';
import { from, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import confAxios from '../../core/config/conf-axios';
import { GridData } from '../../shared/model/grid-data';
import { IDataError } from '../../shared/model/page-error';

const PageError = {
  getListError: (
    size: number,
    page: number,
    keyword?: string,
    targetType?: string
  ): Observable<GridData<IDataError>> => {
    const request = confAxios.get<GridData<IDataError>>(`/sync/errors`, {
      params: { size, page, keyword, targetType }
    });
    return from(request).pipe(
      map((res: AxiosResponse<GridData<IDataError>>) => {
        return {
          ...res.data
        };
      })
    );
  }
};

export default PageError;
