import React from 'react';
import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

export const LoadableButton = ({ loading, children, ...rest }) => (
  <Button disabled={loading} {...rest}>
    <FontAwesomeIcon
      icon={faSpinner}
      spin
      style={
        loading
          ? null
          : {
              display: 'none'
            }
      }
    />{' '}
    {children}
  </Button>
);
