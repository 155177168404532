import React, { useState, useCallback } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import qs from 'query-string';
import debounce from 'lodash.debounce';

export default function useHistoryQuery<S>(
  params: S | (() => S)
): [S, React.Dispatch<React.SetStateAction<S>>, React.Dispatch<React.SetStateAction<S>>] {
  const location = useLocation();
  const history = useHistory();
  const [query, setQ] = useState<S>({
    ...params,
    ...qs.parse(location.search)
  } as S);

  const setQuery = q => {
    const newQuery = {
      ...query,
      ...q
    };
    const search = qs.stringify(newQuery);
    history.push({
      ...location,
      search
    });
    setQ(newQuery);
    if (!DEBUG.enable) {
      const q = search.startsWith('?') ? search : '?' + search;
      SapoApp.pushState(location.pathname + q);
    }
  };
  const setDebounceQuery = useCallback(debounce(setQuery, 200), [query]);

  return [query, setQuery, setDebounceQuery];
}
