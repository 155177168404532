import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import React, { useRef, useEffect, useState } from 'react';
import img_no_product from '../../../assets/img/no-product.png';
import img_no_order from '../../../assets/img/no-order.png';
//import './pie-chart.scss';
export const PieChart = (props: any) => {
  const [dataActiCus, setDataActiCus] = useState([]);
  const [sapoChart, setSapoChart] = useState<boolean>(true);
  useEffect(() => {
    let data;

    if (props.type === 'web-chart' && props.webStatistic !== null) {
      if (props.webStatistic.unlink === 0 && props.webStatistic.error === 0 && props.webStatistic.connected === 0) {
        setSapoChart(true);
      } else {
        data = [
          {
            category: 'Sản phẩm chưa đồng bộ',
            value: props.webStatistic.unlink
          },
          {
            category: 'Sản phẩm lỗi đồng bộ',
            value: props.webStatistic.error
          },
          {
            category: 'Sản phẩm đã đồng bộ',
            value: props.webStatistic.connected
          }
        ];
        setSapoChart(false);
      }
    }
    setDataActiCus(data);
    if (props.webStatistic === null) {
      setSapoChart(true);
    }
  }, [props]);

  const chartRef = useRef(null);
  React.useEffect(() => {
    if (!chartRef.current && !sapoChart) {
      drawChart();
      //chartRef.current.data = dataActiCus;
    }
    return () => {
      chartRef.current && chartRef.current.dispose();
    };
  }, [dataActiCus]);

  React.useEffect(() => {
    return () => {
      chartRef.current && chartRef.current.dispose();
    };
  }, []);

  function drawChart() {
    am4core.useTheme(am4themes_animated);

    let chart = am4core.create(`pie-chart-web`, am4charts.PieChart);

    //chartRef.current.data = data;
    chart.data = dataActiCus;
    chart.logo.disabled = true;
    chart.colors.list = [
      am4core.color('#526680'), //chua
      am4core.color('#e83c19'), //loi
      am4core.color('#0088ff') //thanh cong
    ];

    chart.numberFormatter.numberFormat = '#.';
    let pieSeries = chart.series.push(new am4charts.PieSeries());
    pieSeries.dataFields.value = 'value';
    pieSeries.dataFields.category = 'category';
    pieSeries.slices.template.stroke = am4core.color('#fff');
    pieSeries.slices.template.strokeOpacity = 1;
    pieSeries.slices.template.tooltipText = '{category}[/]\n - Số lượng: [bold]{value.value}';
    pieSeries.slices.template.events.on(
      'hit',
      function (ev) {
        let status;
        if (ev.target.dataItem.properties.category === 'Sản phẩm đã đồng bộ') {
          status = 'connected';
        }
        if (ev.target.dataItem.properties.category === 'Sản phẩm lỗi đồng bộ') {
          status = 'error';
        }
        if (ev.target.dataItem.properties.category === 'Sản phẩm chưa đồng bộ') {
          status = 'unlink';
        }
        document.location.href = `/products?status=${status}&source=web`;
      },
      this
    );
    pieSeries.tooltip.getFillFromObject = false;
    pieSeries.tooltip.autoTextColor = false;
    pieSeries.tooltip.label.fill = am4core.color('#212B35');
    pieSeries.tooltip.background.strokeWidth = 1;
    pieSeries.tooltip.background.propertyFields.stroke = 'color';

    //pieSeries.tooltip.background.stroke =am4core.color('color');
    // pieSeries.slices.template.propertyFields.fill = 'color';
    // pieSeries.labels.template.background.propertyFields.stroke = 'color';
    pieSeries.labels.template.text = '{value.value}';
    pieSeries.colors.list = [am4core.color('#526680'), am4core.color('#e83c19'), am4core.color('#0088ff')];

    // This creates initial animation
    pieSeries.hiddenState.properties.opacity = 1;
    pieSeries.hiddenState.properties.endAngle = -90;
    pieSeries.hiddenState.properties.startAngle = -90;

    chart.hiddenState.properties.radius = am4core.percent(0);

    pieSeries.labels.template.paddingTop = 0;
    pieSeries.labels.template.paddingBottom = 0;

    return chart;
  }

  return (
    <div>
      {props.type === 'web-chart' ? (
        <div className="d-flex flex-column align-items-center">
          <span style={{ marginBottom: '1rem', fontSize: '1.6rem', whiteSpace: 'nowrap'  }}>Sản phẩm từ Web</span>
          {!sapoChart ? (
            <div id="pie-chart-web" className="pieChart mt-3" style={{ height: '25vh' }}></div>
          ) : (
            <div className="pieChart d-flex justify-content-center" style={{ height: '25vh' }}>
              <img alt="icon double file" className="mt-5" style={{ height: '80%' }} src={img_no_product}></img>
            </div>
          )}
        </div>
      ) : props.type === 'web-chart' ? (
        <div className="d-flex flex-column align-items-center">
          <span style={{ marginBottom: '1rem', fontSize: '1.6rem' }}>Sản phẩm từ Web</span>
          {/* {!webChart ? (
            <div id="pie-chart-web" className="pieChart mt-3"></div>
          ) : (
            <div className="pieChart d-flex justify-content-center">
              <img
                alt="icon double file"
                className="mt-5"
                src={img_no_product}
              ></img>
            </div>
          )} */}
        </div>
      ) : (
        <div className="d-flex flex-column align-items-center">
          <span style={{ marginBottom: '1rem', fontSize: '1.6rem' }}>Đơn hàng từ Web</span>
          {/* {!orderChart ? (
            <div id="pie-chart-order" className="pieChart mt-3"></div>
          ) : (
            <div className="pieChart d-flex justify-content-center">
              <img
                alt="icon double file"
                className="mt-5"
                src={img_no_order}
              ></img>
            </div>
          )} */}
        </div>
      )}
    </div>
  );
};
export default PieChart;
