import React from 'react';
import './SWButton.scss';
interface SWButtonProps {
  color?: 'primary' | 'destroy';
  children?: any;
  disabled?: boolean;
  onClick?: any;
  className?: string;
}
export default function SWButton(props: SWButtonProps) {
  const { color, children, disabled, onClick, className } = props;
  return (
    <div id={`sw-button`}>
      <button
        onClick={onClick}
        disabled={disabled}
        className={`ui-button ${color ? 'ui-button--' + color : ''} ${className ? className : ''}`}
      >
        {children}
      </button>
    </div>
  );
}
