import axios from 'axios';
import auth from '../../auth';

const instance = axios.create({
  baseURL: '/api',
  responseType: 'json'
});

instance.interceptors.request.use(config => {
  const currentAuth = auth.get();
  config.headers['X-App-Storeid'] = currentAuth?.storeId;
  config.headers['X-Sync-Channel-Token'] = currentAuth?.token;
  return config;
});

export default instance;
