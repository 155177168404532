import { IItem } from '../../shared/model/base-data';
export interface IOrder {
  id?: number;
  web_order_id?: number;
  pos_order_id?: number;
  web_order_number?: number;
  pos_order_code?: string;
  web_order_name?: string;
  status?: string;
  last_sync_request_status?: string;
  last_sync_request_error_from?: string;
  last_sync_request_time?: string;
  web_status?: string;
  pos_status?: string;
  created_on?: string;
  modified_on?: string;
  error?: IError;
  web_url?: string;
  pos_url?: string;
  app_address?: string;
  pos_tenant_alias?: string;
  web_store_alias?: string;
  pos_order_address?: string;
  app_store_id?: number;
  customer_name?: string;
  total_price?: number;
  pos_detail?: IOrderDetail;
  web_detail?: IOrderDetail;
}
export interface IOrderError {
  id: number;
  internal_message: string;
  store_id: number;
  sync_type: string;
  error_target: string;
  target_id: number;
  target_name: string;
  type: string;
  message: string;
  created_on: string;
  meta_data: {
    KEY_PRODUCT_IDS_ERROR: string;
  };
  product_errors: IProductError[];
}

export interface IProductError {
  id: number;
  store_id: number;
  web_product_id: number;
  web_variant_id: number;
  web_sku: string;
  web_product_name: string;
  web_attribute_title: string;
  pos_product_id: string;
  pos_variant_id: string;
  pos_sku: string;
  pos_attribute_title: string;
  pos_product_name: string;
  status: string;
  modified_on: string;
  created_on: string;
  type_error: string;
  error: string;
  web_url: string;
  pos_url: string;
  last_sync_request_time: string;
  last_sync_request_error_from: string;
}
export interface IConditionOrder {
  status?: string;
  query?: string;
  source?: string;
}
interface IError {
  message_error?: string;
  type?: string;
  description?: string;
}
export interface IOrderDetail {
  cancel?: {
    status?: string;
    tag?: string;
    description?: string;
  };
  refund?: {
    status?: string;
    tag?: string;
    description?: string;
  };
  fulfillment?: {
    status?: string;
    tag?: string;
    description?: string;
  };
  financial?: {
    status?: string;
    tag?: string;
    description?: string;
  };
}

export const itemPerPageModel: number[] = [20, 30, 50];

export const filterConditionModel: IItem[] = [
  {
    key: '',
    value: 'Tất cả đơn hàng'
  },
  {
    key: 'connected',
    value: 'Đã liên kết'
  },
  {
    key: 'unlink',
    value: 'Chưa liên kết'
  },
  {
    key: 'error',
    value: 'Lỗi liên kết'
  }
];
export const navModel: IItem[] = [
  {
    key: '',
    value: 'Tất cả đơn hàng'
  },
  {
    key: 'connected',
    value: 'Đã liên kết'
  },
  {
    key: 'unlink',
    value: 'Chưa liên kết'
  },
  {
    key: 'error',
    value: 'Lỗi liên kết'
  }
];

export const navModelActionProduct: IItem[] = [
  {
    key: 're_sync',
    value: 'Đồng bộ lại'
  }
];
