import React from 'react';
import { Label, Input, InputProps } from 'reactstrap';
import ReactSelect from 'react-select';

interface SelectProp extends InputProps {
  label?: string;
  options: {
    label: string;
    value: string;
  }[];
}

class Select extends React.Component<SelectProp> {
  render() {
    const { label, id, options, ...rest } = this.props;
    return (
      <>
        {label ? <Label for={id}>{label}</Label> : null}
        <Input type="select" id={id} {...rest}>
          {options.map(option => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </Input>
      </>
    );
  }
}

export const SelectMultiple = props => (
  <ReactSelect placeholder="Chọn chi nhánh " noOptionsMessage={() => 'Không có chi nhánh'} {...props} isMulti />
);

export default Select;
