import React from 'react';
import { navModel } from './data';
import './page-errors.scss';
import SearchIcon from '../../assets/img/icons/search_icon.svg';
import TablePageError from './table/table';
import useHistoryQuery from 'components/useHistoryQuery';

export default function PageErrors() {
  const [condition, setCondition, setDebounceCondition] = useHistoryQuery<{
    query?: string;
    targetType?: string;
  }>({
    query: '',
    targetType: ''
  });
  return (
    <div id="channel-error">
      <div className="nav-bar">
        {navModel.map((link, i) => (
          <div
            key={i}
            className={link.key === condition.targetType ? 'active' : ''}
            onClick={() => setCondition({ targetType: link.key })}
          >
            {link.value}
          </div>
        ))}
      </div>
      <div className="conditon">
        <div className="filters">
          <div className="filter-list">
            <div className="search-container">
              <SearchIcon />
              <input
                type="text"
                className="form-control form-large search-input"
                placeholder="Tìm kiếm"
                defaultValue={condition.query}
                onChange={(e: any) => setDebounceCondition({ query: e.target.value.trim() })}
              ></input>
            </div>
          </div>
        </div>
      </div>
      <TablePageError keyword={condition.query} filter={condition.targetType} />
    </div>
  );
}
