import React, { useState } from 'react';
import SearchIcon from '../../assets/img/icons/search_icon.svg';
import './order.scss';
import TableOrder from './table/table';
import { IConditionOrder, navModel } from './data';
import useHistoryQuery from 'components/useHistoryQuery';

export default function Order() {
  const [condition, setCondition, setDebounceCondition] = useHistoryQuery<IConditionOrder>({
    query: '',
    status: ''
  });
  const [checkCallApi, setCheckCallApi] = useState<boolean>(false);

  return (
    <div id="channel-order">
      <div className="nav-bar">
        {navModel.map((link, i) => (
          <div
            key={i}
            className={link.key === condition.status ? 'active' : ''}
            onClick={() => setCondition({ status: link.key })}
          >
            {link.value}
          </div>
        ))}
      </div>
      <div className="conditon">
        <div className="filters">
          <div className="filter-list">
            <div className="search-container">
              <SearchIcon />
              <input
                type="text"
                className="form-control form-large search-input"
                placeholder="Tìm kiếm"
                defaultValue={condition.query}
                onChange={(e: any) => {
                  setDebounceCondition({ query: e.target.value.trim() });
                  setCheckCallApi(true);
                }}
              ></input>
            </div>
          </div>
        </div>
      </div>
      <TableOrder condition={condition} checkCallApi={checkCallApi} setCheckCallApi={setCheckCallApi} />
    </div>
  );
}
