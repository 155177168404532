import React, { useEffect, useState } from 'react';
import ReactTooltip from 'react-tooltip';
import HelpIcon from '../../../assets/img/icons/help-silver.svg';
import './data-sync.scss';

export function Step3(props: any) {
  const [webtoPos, setWebtoPos] = useState(true);
  const [postoWeb, setPostoWeb] = useState(true);

  useEffect(() => {
    if (typeof props.webtoPos !== 'undefined') {
      setWebtoPos(props.webtoPos);
    }

    if (typeof props.postoWeb !== 'undefined') {
      setPostoWeb(props.postoWeb);
    }
  }, [props]);

  const handleClick = (id: number, check: boolean) => {
    if (id === 0) {
      props.setWebtoPos(check);
    }
    if (id === 1) {
      props.setPostoWeb(check);
    }
  };

  return (
    <React.Fragment>
      <div className="card-install-header pb-25 pt-25">
        <h3>{props.title}</h3>
      </div>
      <div className="card-install-body">
        <span className="mt-4 title-radio">Bạn có muốn đồng bộ dữ liệu sản phẩm hiện tại trên Web về Omni?</span>

        <div className="next-input-wrapper mt-3">
          <label className="radio-button" htmlFor="status1">
            <input
              type="radio"
              name="radioStatus1"
              id="status1"
              value={'true'}
              checked={webtoPos === true}
              onChange={e => handleClick(0, true)}
            ></input>
          </label>
          <span onClick={e => handleClick(0, true)} className="switch-text cursor-text">
            Có tôi muốn đồng bộ về Omni
            <span className="icon-tooltip" data-tip data-for="config-3-1">
              <HelpIcon />
            </span>
            <ReactTooltip
              id="config-3-1"
              className="tooltip-custom"
              border
              borderColor="#E8EAEB"
              textColor="#FFF"
              backgroundColor="#182537"
              effect="solid"
              delayShow={200}
            >
              <span>
                Sản phẩm hiện có trên Web sẽ được đồng bộ về Omni,
                <br />
                nếu trùng mã SKU thì liên kết sản phẩm, nếu không trùng
                <br />
                mã SKU thì thêm mới sản phẩm
              </span>
            </ReactTooltip>
          </span>
        </div>
        <div className="next-input-wrapper mt-2">
          <label className="radio-button" htmlFor="status2">
            <input
              type="radio"
              name="radioStatus2"
              id="status2"
              value={'false'}
              checked={webtoPos === false}
              onChange={e => handleClick(0, false)}
            ></input>
          </label>
          <span onClick={e => handleClick(0, false)} className="switch-text cursor-text">
            Không, tôi sẽ đồng bộ phần dữ liệu này về Omni sau
            <span className="icon-tooltip" data-tip data-for="config-3-2">
              <HelpIcon />
            </span>
            <ReactTooltip
              id="config-3-2"
              className="tooltip-custom"
              border
              borderColor="#E8EAEB"
              textColor="#FFF"
              backgroundColor="#182537"
              effect="solid"
              delayShow={200}
            >
              <span>
                Sản phẩm hiện có trên Web sẽ không đồng bộ về Omni
                <br />
                và có trạng thái là chưa liên kết, bạn có thể thực hiện <br />
                liên kết thủ công về sau
              </span>
            </ReactTooltip>
          </span>
        </div>

        <div className="mt-4">
          <span className="mt-4 title-radio">Bạn có muốn đồng bộ dữ liệu sản phẩm hiện tại từ Omni về Web?</span>

          <div className="next-input-wrapper mt-3">
            <label className="radio-button" htmlFor="status3">
              <input
                type="radio"
                name="radioStatus3"
                id="status3"
                value={'true'}
                checked={postoWeb === true}
                onChange={e => handleClick(1, true)}
              ></input>
            </label>
            <span onClick={e => handleClick(1, true)} className="switch-text cursor-text">
              Có tôi muốn đồng bộ về Web
              <span className="icon-tooltip" data-tip data-for="config-3-3">
                <HelpIcon />
              </span>
              <ReactTooltip
                id="config-3-3"
                className="tooltip-custom"
                border
                borderColor="#E8EAEB"
                textColor="#FFF"
                backgroundColor="#182537"
                effect="solid"
                delayShow={200}
              >
                <span>
                  Sản phẩm hiện có trên Omni sẽ được đồng bộ về Web,
                  <br />
                  nếu trùng mã SKU thì liên kết sản phẩm, nếu không trùng
                  <br />
                  mã SKU thì thêm mới sản phẩm
                </span>
              </ReactTooltip>
            </span>
          </div>
          <div className="next-input-wrapper mt-2">
            <label className="radio-button" htmlFor="status4">
              <input
                type="radio"
                name="radioStatus4"
                id="status4"
                value={'false'}
                checked={postoWeb === false}
                onChange={e => handleClick(1, false)}
              ></input>
            </label>
            <span onClick={e => handleClick(1, false)} className="switch-text cursor-text">
              Không, tôi sẽ đồng bộ phần dữ liệu này về Web sau
              <span className="icon-tooltip" data-tip data-for="config-3-4">
                <HelpIcon />
              </span>
              <ReactTooltip
                id="config-3-4"
                className="tooltip-custom"
                border
                borderColor="#E8EAEB"
                textColor="#FFF"
                backgroundColor="#182537"
                effect="solid"
                delayShow={200}
              >
                <span>
                  Sản phẩm hiện có trên Omni sẽ không đồng bộ về Web
                  <br />
                  và có trạng thái là chưa liên kết, bạn có thể thực hiện
                  <br />
                  liên kết thủ công về sau
                </span>
              </ReactTooltip>
            </span>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default Step3;
