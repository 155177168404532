import React from 'react';
import { Container, Row, Col, FormGroup, Label } from 'reactstrap';
import { Card, CardHeader, CardBody } from 'components/Card';
import Tip from 'components/Tip';
import Select from 'components/Select';

export interface IPriceConfig {
  price: number;
  comparePrice: number;
  posPrices: {
    id: number;
    label: string;
  }[];
}

export default ({ config, onConfigChange }: { config: IPriceConfig; onConfigChange: (v: IPriceConfig) => any }) => {
  return (
    <Card>
      <CardHeader title="Thiết lập chính sách giá đồng bộ của Omni và Web" />
      <CardBody>
        <Container>
          <Row>
            <Col style={{ paddingLeft: 0 }}>
              <FormGroup>
                <Label for="configPrice">
                  Giá bán hàng hoá Web
                  <Tip
                    title="Giá bán"
                    description="Vui lòng chọn một chính sách giá trên Omni để đồng bộ với giá bán trên Web.
              Khi hai giá đã được liên kết, thì một trong hai hệ thống thay đổi giá sẽ thay đổi giá của hệ thống còn lại."
                  />
                </Label>
                <Select
                  id="configPrice"
                  value={config.price}
                  onChange={e =>
                    onConfigChange({
                      ...config,
                      price: parseInt(e.target.value)
                    })
                  }
                  options={config.posPrices.map(p => ({
                    value: p.id + '',
                    label: p.label
                  }))}
                />
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label for="configComparePrice">
                  Giá so sánh Web
                  <Tip
                    title="Giá so sánh"
                    description="Vui lòng chọn một chính sách giá trên Omni để đồng bộ với giá so sánh trên Web.
              Khi hai giá đã được liên kết, thì một trong hai hệ thống thay đổi giá sẽ thay đổi giá của hệ thống còn lại."
                  />
                </Label>
                <Select
                  id="configComparePrice"
                  value={config.comparePrice}
                  onChange={e =>
                    onConfigChange({
                      ...config,
                      comparePrice: e.target.value ? parseInt(e.target.value) : undefined
                    })
                  }
                  options={config.posPrices
                    .map(p => ({
                      value: p.id + '',
                      label: p.label
                    }))
                    .concat({
                      value: '',
                      label: 'Không so sánh giá'
                    })}
                />
              </FormGroup>
            </Col>
          </Row>
        </Container>
      </CardBody>
    </Card>
  );
};
