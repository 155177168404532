import React, { useRef, useState } from 'react';
import SWButton from '../../shared/sw-button/SWButton';
import { useOnClickOutside } from '../../shared/util/function';
import SearchIcon from '../../assets/img/icons/search_icon.svg';
import CaretDownIcon from '../../assets/img/icons/caret_down_icon.svg';
import { IConditionOrder } from '../order/data';
import './product.scss';
import TableProducts from './table-product/table';
import { conditionSource, navModel } from './data';
import { IItem } from '../../shared/model/base-data';
import useHistoryQuery from 'components/useHistoryQuery';

export default function Products() {
  const ref2 = useRef();
  const [condition, setCondition, setDebounceCondition] = useHistoryQuery<IConditionOrder>({
    source: 'pos',
    query: '',
    status: ''
  });

  const [screenType, setScreenType] = useState<boolean>(false);

  const [checkCallApi, setCheckCallApi] = useState<boolean>(false);

  useOnClickOutside(ref2, () => setScreenType(false), 'filter-item filter-item-1');

  const findFilter = (str: string, arr: IItem[]) => {
    return arr.find(fi => fi.key === str).value;
  };
  return (
    <div id="channel-products">
      <div className="nav-bar">
        {navModel.map((link, i) => (
          <div
            key={i}
            className={link.key === condition.status ? 'active' : ''}
            onClick={() => setCondition({ status: link.key })}
          >
            {link.value}
          </div>
        ))}
      </div>
      <div className="conditon">
        <div className="filters">
          <div className="filter-list">
            <div className="filter-item filter-item-1">
              <SWButton onClick={() => setScreenType(() => !screenType)}>
                <span className="on-mobile">{findFilter(condition.source, conditionSource)}</span>
                <CaretDownIcon className="icon-drop" />
              </SWButton>
              {screenType ? (
                <div className="popover-filter" ref={ref2}>
                  <span className="triangle"></span>
                  {conditionSource.map((c, index) => (
                    <div
                      key={index}
                      className={`filter-condition ${c.key === condition.source ? 'filter-active' : ''}`}
                      onClick={() => {
                        setCondition({
                          source: c.key
                        });
                        setScreenType(false);
                      }}
                    >
                      {c.value}
                    </div>
                  ))}
                </div>
              ) : (
                ''
              )}
            </div>
            <div className="search-container">
              <SearchIcon />
              <input
                type="text"
                className="form-control form-large search-input"
                placeholder="Tìm kiếm"
                defaultValue={condition.query}
                onChange={(e: any) => {
                  setDebounceCondition({ query: e.target.value.trim() });
                  setCheckCallApi(true);
                }}
              ></input>
            </div>
          </div>
        </div>
      </div>
      <TableProducts condition={condition} checkCallApi={checkCallApi} setCheckCallApi={setCheckCallApi} />
    </div>
  );
}
