/* global SapoApp */

const Toast = {
  info(m) {
    const embbed = window.self !== window.top;
    if (embbed) {
      return SapoApp.flashNotice(m);
    }
    return alert(`INFO: ${m}`);
  },
  error(m) {
    const embbed = window.self !== window.top;
    if (embbed) {
      return SapoApp.flashError(m);
    }
    return alert(`ERROR: ${m}`);
  }
};
export default Toast;
