import React, { useEffect, useRef, useState } from 'react';
import './data-sync.scss';
import DownGray from '../../../assets/img/icons/down_gray.svg';
import UpGray from '../../../assets/img/icons/up_gray.svg';
import ReactTooltip from 'react-tooltip';
import HelpIcon from '../../../assets/img/icons/help-silver.svg';
import { useOnClickOutside } from '../../../shared/util/function';

export function Step2(props: any) {
  const ref1 = useRef(null);
  const ref2 = useRef(null);

  const [isOpenPrice, setOpenPrice] = useState(false);
  const [isOpenPriceCompare, setOpenPriceCompare] = useState(false);

  useOnClickOutside(ref1, () => setOpenPrice(false), 'price-item');
  useOnClickOutside(ref2, () => setOpenPriceCompare(false), 'price-compare-item');

  const [posPrices, setPosPrices] = useState([]);
  const [price, setPrice] = useState(null);
  const [priceCompare, setPriceCompare] = useState(null);

  useEffect(() => {
    if (typeof props.posPrices !== 'undefined') {
      if (props.posPrices.length > 0) {
        setPosPrices(props.posPrices);
      }
      if (props.posPrices.length === 0) {
        setPosPrices([
          { id: 22721, label: 'Giá bán lẻ' },
          { id: 22720, label: 'Giá nhập' },
          { id: 22719, label: 'Giá bán buôn' }
        ]);
      }

      if (typeof props.salePriceId !== 'undefined') {
        var resultPrice = props.posPrices.filter(obj => {
          return obj.id === props.salePriceId;
        });
        if (resultPrice.length > 0) {
          setPrice(resultPrice[0]);
        }
        if (resultPrice.length === 0 && props.posPrices.length > 0) {
          setPrice(props.posPrices[0]);
          props.setSalePriceId(props.posPrices[0].id);
        }
      }

      if (typeof props.comparePriceId !== 'undefined') {
        var result = props.posPrices.filter(obj => {
          return obj.id === props.comparePriceId;
        });
        if (result.length > 0) {
          setPriceCompare(result[0]);
        }
        if (result.length === 0) {
          props.setComparePriceId(null);
        }
      }
    }
  }, [props]);

  function handleSetPrice(id: any) {
    props.setSalePriceId(id);
    setOpenPrice(false);
  }

  function handleSetPriceCompare(id: any) {
    props.setComparePriceId(id);
    setOpenPriceCompare(false);
  }

  return (
    <React.Fragment>
      <div className="card-install-header pb-25 pt-25">
        <h3>{props.title}</h3>
      </div>
      <div className="card-install-body d-flex flex-row align-items-center w-100 install-title">
        <span className="col-md-8">Mô tả</span>
        <span className="col-md-3 no-padding">Chi sách giá Omni</span>
      </div>

      <div className="card-install-body d-flex flex-row align-items-center w-100">
        <span className="col-md-8">
          Giá đồng bộ với giá bán tại Web
          <span className="icon-tooltip" data-tip data-for="config-2-1">
            <HelpIcon />
          </span>
          <ReactTooltip
            id="config-2-1"
            className="tooltip-custom"
            border
            borderColor="#E8EAEB"
            textColor="#FFF"
            backgroundColor="#182537"
            effect="solid"
            delayShow={200}
          >
            <span>Chọn chính sách giá trên Omni sẽ liên kết và đồng bộ với giá bán tại Web</span>
          </ReactTooltip>
        </span>
        <div className="col-md-3 no-padding">
          <div className="price-item">
            <div
              className={`list-store d-flex justify-content-between align-items-center mt-2 ${
                isOpenPrice ? 'list-store-open' : ''
              }`}
              onClick={e => {
                setOpenPrice(!isOpenPrice);
              }}
            >
              <span className="account-name">{price != null ? price.label : ''}</span>
              {isOpenPrice ? <UpGray className="ml-1 icon-per" /> : <DownGray className="ml-1 icon-per" />}
            </div>

            {isOpenPrice ? (
              <div className={`d-flex align-items-center flex-column popover-list-store`} ref={ref1}>
                <div className="d-flex flex-column w-100">
                  {posPrices.map((itm, index) => (
                    <div
                      key={index}
                      className="border-top select-store d-flex align-items-center"
                      onClick={() => handleSetPrice(itm.id)}
                    >
                      {itm.label}
                    </div>
                  ))}
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
      <div className="card-install-body d-flex flex-row align-items-center w-100">
        <span className="col-md-8">
          Giá đồng bộ với giá so sánh tại Web
          <span className="icon-tooltip" data-tip data-for="config-2-2">
            <HelpIcon />
          </span>
          <ReactTooltip
            id="config-2-2"
            className="tooltip-custom"
            border
            borderColor="#E8EAEB"
            textColor="#FFF"
            backgroundColor="#182537"
            effect="solid"
            delayShow={200}
          >
            <span>Chọn chính sách giá trên Omni sẽ liên kết và đồng bộ với giá so sánh tại Web</span>
          </ReactTooltip>
        </span>
        <div className="col-md-3 no-padding">
          <div className="price-compare-item">
            <div
              className={`list-store d-flex justify-content-between align-items-center mt-2 ${
                isOpenPriceCompare ? 'list-store-open' : ''
              }`}
              onClick={e => {
                setOpenPriceCompare(!isOpenPriceCompare);
              }}
            >
              <span className="account-name">{priceCompare != null ? priceCompare.label : 'Không so sánh giá'}</span>
              {isOpenPriceCompare ? <UpGray className="ml-1 icon-per" /> : <DownGray className="ml-1 icon-per" />}
            </div>

            {isOpenPriceCompare ? (
              <div className="d-flex align-items-center flex-column popover-list-store" ref={ref2}>
                <div className="d-flex flex-column w-100">
                  <div
                    className="border-top select-store d-flex align-items-center"
                    onClick={() => handleSetPriceCompare(null)}
                  >
                    Không so sánh giá
                  </div>

                  {posPrices.map((itm, index) => (
                    <div
                      key={index}
                      className="border-top select-store d-flex align-items-center"
                      onClick={() => handleSetPriceCompare(itm.id)}
                    >
                      {itm.label}
                    </div>
                  ))}
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default Step2;
