import React from 'react';
import { FormGroup, Label, Input } from 'reactstrap';
import { Card, CardHeader, CardBody } from 'components/Card';
import Tip from 'components/Tip';

export default ({ config, onConfigChange }: { config: string; onConfigChange: (v: string) => any }) => {
  return (
    <Card>
      <CardHeader title="Thiết lập đồng bộ khách hàng" />
      <CardBody>
        <FormGroup>
          <p>Tiêu chí định danh để đồng bộ thông tin khách hàng trong đơn hàng từ Web về Omni</p>
          <FormGroup check>
            <Label check style={{ cursor: 'pointer' }}>
              <Input
                type="radio"
                name="customerIdent"
                value="email"
                checked={config === 'email'}
                onChange={e => onConfigChange(e.target.value)}
                style={{ cursor: 'pointer' }}
              />
              &nbsp;Email
              <Tip
                title="Liên kết bằng Email"
                description="Tiêu chí liên kết khách hàng giữa hai hệ thống là email"
                examples={[
                  'Khách hàng Nguyễn Văn A trên Web có email là nguyenvana@gmail.com',
                  'Khách hàng Nguyễn Văn A trên Omni có email là nguyenvana@gmail.com',
                  'Hai khách hàng sẽ liên kết đồng bộ với nhau khi người dùng chọn thiết lập này.'
                ]}
              />
            </Label>
          </FormGroup>
          <FormGroup check>
            <Label check style={{ cursor: 'pointer' }}>
              <Input
                type="radio"
                name="customerIdent"
                value="phone"
                checked={config === 'phone'}
                onChange={e => onConfigChange(e.target.value)}
                style={{ cursor: 'pointer' }}
              />
              &nbsp;Số điện thoại
              <Tip
                title="Liên kết bằng số điện thoại"
                description="Tiêu chí liên kết khách hàng giữa hai hệ thống là số điện thoại"
                examples={[
                  'Khách hàng Nguyễn Văn A trên Web có số điện thoại là 0987654321',
                  'Khách hàng Nguyễn Văn A trên Omni có số điện thoại là 0987654321',
                  'Hai khách hàng sẽ liên kết đồng bộ với nhau khi chọn thiết lập này.'
                ]}
              />
            </Label>
          </FormGroup>
        </FormGroup>
      </CardBody>
    </Card>
  );
};
