import React, { useEffect, useMemo, useState } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Form, FormGroup, Label, Input } from 'reactstrap';
import * as configUtils from './configUtils';
import Select from 'components/Select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { TLocationMapping, TLocationMappingProvince, TProvince } from './data';
import ConfigService from '../../services/config-services';

const ItemGroup = ({ children, style }: { style?: any; children: any }) => (
  <ul
    style={{
      ...style,
      listStyleType: 'none'
    }}
  >
    {children}
  </ul>
);

const Item = ({
  label,
  checked,
  onChange,
  children,
  renderSublabel,
  disabled = false
}: {
  checked: boolean;
  children?: any;
  label: string;
  onChange: () => any;
  renderSublabel: () => any;
  disabled?: boolean;
}) => (
  <li style={{ marginBottom: '7px' }}>
    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
      <div>
        <Input
          type="checkbox"
          checked={checked}
          onChange={onChange}
          disabled={disabled}
          style={{
            cursor: 'pointer'
          }}
        />
        <span
          style={{
            marginLeft: '5px',
            color: disabled ? 'gray' : 'initial',
            cursor: !disabled ? 'pointer' : 'not-allowed'
          }}
          onClick={onChange}
        >
          {label}
        </span>
      </div>
      <div style={{ color: 'gray' }}>{renderSublabel()}</div>
    </div>
    {children}
  </li>
);

interface TProvinceState {
  name: string;
  collapse: boolean;
  districts: {
    name: string;
    disabled: boolean;
    checked: boolean;
  }[];
}

export default ({
  mappings,
  locations,
  mappingIdx,
  closeModal,
  onMappingChange
}: {
  mappings: TLocationMapping[];
  locations: {
    id: number;
    label: string;
  }[];
  mappingIdx: number;
  closeModal: (e: any) => any;
  onMappingChange: (m: TLocationMapping) => any;
}) => {
  const initialMapping =
    mappingIdx >= 0
      ? mappings[mappingIdx]
      : {
          title: '',
          location_id: locations.length ? locations[0].id : undefined
        };
  const [query, setQuery] = useState<string>('');
  const [title, setTitle] = useState<string>(initialMapping.title);
  const [location, setLocation] = useState<number>(initialMapping.location_id);
  const [allProvinces, setAllProvinces] = useState<TProvinceState[]>([]);

  const handleToggleProvince = (pName: string) => {
    const provinceIdx = allProvinces.findIndex(p => p.name === pName);
    const province = allProvinces[provinceIdx];
    const stats = province.districts.reduce(
      (acc, k) => {
        acc.nDisabled += k.disabled ? 0 : 1;
        acc.nCheked += k.checked ? 0 : 1;
        return acc;
      },
      {
        nDisabled: 0,
        nCheked: 0
      }
    );
    if (stats.nCheked === province.districts.length - stats.nDisabled) {
      return setAllProvinces([
        ...allProvinces.slice(0, provinceIdx),
        {
          ...province,
          districts: province.districts.map(d => {
            if (!d.disabled) {
              return {
                ...d,
                checked: false
              };
            }
            return d;
          })
        },
        ...allProvinces.slice(provinceIdx + 1)
      ]);
    }
    return setAllProvinces([
      ...allProvinces.slice(0, provinceIdx),
      {
        ...province,
        districts: province.districts.map(d => {
          if (!d.disabled) {
            return {
              ...d,
              checked: true
            };
          }
          return d;
        })
      },
      ...allProvinces.slice(provinceIdx + 1)
    ]);
  };

  const handleToggleDistrict = (pName: string, dName: string) => {
    const provinceIdx = allProvinces.findIndex(p => p.name === pName);
    const province = allProvinces[provinceIdx];
    const districtIdx = province.districts.findIndex(d => d.name === dName);
    const district = province.districts[districtIdx];
    if (district.disabled) {
      return;
    }
    return setAllProvinces([
      ...allProvinces.slice(0, provinceIdx),
      {
        ...province,
        districts: [
          ...province.districts.slice(0, districtIdx),
          {
            ...district,
            checked: !district.checked
          },
          ...province.districts.slice(districtIdx + 1)
        ]
      },
      ...allProvinces.slice(provinceIdx + 1)
    ]);
  };

  const provincesFromApi = (mappings: TLocationMapping[], allProvinces: TProvince[]): TProvinceState[] => {
    const pTracks = {};
    for (let i = 0; i < mappings.length; i++) {
      const mapping = mappings[i];
      for (const p of mapping.provinces) {
        const pTrack = (pTracks[p.province] = pTracks[p.province] || {});
        if (p.districts.length) {
          for (const d of p.districts) {
            pTrack[d] = i;
          }
          continue;
        }
        const province = allProvinces.find(pv => pv.name === p.province);
        for (const d of province.districts) {
          pTrack[d.name] = i;
        }
      }
    }
    return allProvinces.map(p => ({
      name: p.name,
      collapse: true,
      districts: p.districts.map(d => ({
        name: d.name,
        checked: !!pTracks[p.name] && pTracks[p.name][d.name] >= 0 && pTracks[p.name][d.name] === mappingIdx,
        disabled: !!pTracks[p.name] && pTracks[p.name][d.name] >= 0 && pTracks[p.name][d.name] !== mappingIdx
      }))
    }));
  };

  useEffect(() => {
    ConfigService.getProvinces().subscribe(res => setAllProvinces(provincesFromApi(mappings, res)));
  }, []);

  const matchProvinces = useMemo(() => allProvinces.filter(p => configUtils.nameContains(p.name, query)), [
    allProvinces,
    query
  ]);

  const hasSelectedProvince = useMemo(() => allProvinces.some(p => p.districts.some(d => !d.disabled && d.checked)), [
    allProvinces
  ]);

  const renderProvinceStat = (p: TProvinceState): string => {
    if (p.districts.every(d => d.disabled)) {
      return 'Tại chi nhánh khác';
    }
    const usedDistricts = p.districts.reduce((acc, d) => (d.checked ? acc + 1 : acc), 0);
    return `${usedDistricts} trên ${p.districts.length} quận huyện`;
  };

  return (
    <Modal isOpen={true} toggle={closeModal}>
      <ModalHeader toggle={closeModal}>{mappingIdx >= 0 ? 'Sửa liên kết' : 'Thêm liên kết'}</ModalHeader>
      <ModalBody>
        <Form>
          <FormGroup>
            <Label for="locationModalTitle">Tên khu vực</Label>
            <Input
              type="text"
              id="locationModalTitle"
              placeholder="Ví dụ: Khu vực miền bắc"
              value={title}
              onChange={e => setTitle(e.target.value)}
              autoComplete="off"
            />
          </FormGroup>
          <FormGroup>
            <Select
              id="locationModalLocation"
              label="Chi nhánh nhận đơn hàng"
              value={location}
              onChange={e => setLocation(parseInt(e.target.value))}
              options={locations.map(l => ({
                value: l.id + '',
                label: l.label
              }))}
            />
          </FormGroup>
          <FormGroup>
            <Label for="locationModalSearch">Tỉnh thành/quận huyện</Label>
            <Input
              type="text"
              id="locationModalSearch"
              placeholder="Tìm kiếm tỉnh thành"
              value={query}
              onChange={e => setQuery(e.currentTarget.value)}
              autoComplete="off"
              style={{
                marginBottom: '1em'
              }}
            />
            <ItemGroup style={{ paddingRight: '2em', maxHeight: '10em', overflowY: 'scroll', position: 'relative' }}>
              {matchProvinces.length
                ? matchProvinces.map(p => {
                    const pIdx = allProvinces.findIndex(p1 => p1.name === p.name);
                    return (
                      <Item
                        key={p.name}
                        label={p.name}
                        checked={p.districts.every(d => d.disabled || d.checked)}
                        disabled={p.districts.every(d => d.disabled)}
                        onChange={() => handleToggleProvince(p.name)}
                        renderSublabel={() => (
                          <span
                            style={{
                              cursor: 'pointer'
                            }}
                            onClick={() =>
                              setAllProvinces([
                                ...allProvinces.slice(0, pIdx),
                                {
                                  ...p,
                                  collapse: !p.collapse
                                },
                                ...allProvinces.slice(pIdx + 1)
                              ])
                            }
                          >
                            {renderProvinceStat(p)} <FontAwesomeIcon icon={p.collapse ? faChevronDown : faChevronUp} />
                          </span>
                        )}
                      >
                        {p.districts.length && !p.collapse ? (
                          <ItemGroup>
                            {p.districts.map((d, dIdx) => (
                              <Item
                                key={dIdx}
                                label={d.name}
                                checked={d.checked || d.disabled}
                                disabled={d.disabled}
                                onChange={() => handleToggleDistrict(p.name, d.name)}
                                renderSublabel={() => (d.disabled ? 'Tại chi nhánh khác' : null)}
                              />
                            ))}
                          </ItemGroup>
                        ) : null}
                      </Item>
                    );
                  })
                : 'không tìm thấy kết quả'}
            </ItemGroup>
          </FormGroup>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button
          outline
          color="secondary"
          onClick={closeModal}
          style={{
            border: '1px solid #bbb'
          }}
        >
          Bỏ qua
        </Button>
        <Button
          color="primary"
          disabled={!title || !hasSelectedProvince}
          onClick={() =>
            onMappingChange({
              title,
              location_id: location,
              provinces: allProvinces.reduce((acc: TLocationMappingProvince[], p) => {
                const districts = p.districts.filter(d => !d.disabled && d.checked).map(d => d.name);
                if (districts.length) {
                  acc.push({
                    province: p.name,
                    districts
                  });
                }
                return acc;
              }, [])
            })
          }
        >
          Đồng ý
        </Button>
      </ModalFooter>
    </Modal>
  );
};
