import React, { useEffect, useRef, useState } from 'react';
import './data-sync.scss';
import DownGray from '../../../assets/img/icons/down_gray.svg';
import UpGray from '../../../assets/img/icons/up_gray.svg';
import SearchIcon from '../../../assets/img/icons/search_icon.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import Step1Plus from './step1-plus';
import ReactTooltip from 'react-tooltip';
import HelpIcon from '../../../assets/img/icons/help-silver.svg';
import { useOnClickOutside } from '../../../shared/util/function';

export function Step1(props: any) {
  const [posLocation, setPosLocation] = useState([]);

  const [listChecked, setListChecked] = useState<number[]>([]);

  const [locationSearch, setLocationSearch] = useState<string>('');

  const [textLocation, setTextLocation] = useState('Chưa chọn chi nhánh');

  const onClickCheckBoxAll = (e: any) => {
    e.stopPropagation();
    const li: number[] = [];
    if (e.target.checked) {
      posLocation.forEach(l => {
        li.push(l.id);
      });
    }
    setListChecked(li);
    props.setLocation(li);
  };

  const handleRowClick = (event: any, id: number) => {
    const selectedIndex = listChecked.indexOf(id);
    let newSelected: number[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(listChecked, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(listChecked.slice(1));
    } else if (selectedIndex === listChecked.length - 1) {
      newSelected = newSelected.concat(listChecked.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(listChecked.slice(0, selectedIndex), listChecked.slice(selectedIndex + 1));
    }

    setListChecked(newSelected);
    props.setLocation(newSelected);
  };

  const isSelected = (id: number) => listChecked.indexOf(id) !== -1;

  useEffect(() => {
    if (typeof props.posLocation !== 'undefined') {
      setPosLocation(props.posLocation);

      if (props.posLocation.length === 1) {
        setListChecked(props.posLocation[0].id);
      }
    }
    if (typeof props.location !== 'undefined') {
      setListChecked(props.location);
    }
  }, [props]);

  useEffect(() => {
    if (typeof props.location !== 'undefined' && posLocation.length !== 0) {
      if (posLocation.length === listChecked.length) {
        setTextLocation(`Toàn bộ chi nhánh (${props.location.length})`);
      } else if (listChecked.length > 1) {
        setTextLocation('');
      }

      if (listChecked.length === 1) {
        posLocation.forEach(e => {
          if (e.id === listChecked[0]) {
            setTextLocation(e.label);
          }
        });
      }
      if (listChecked.length === 0) {
        setTextLocation('Chưa chọn chi nhánh');
      }

      //props.setLocation(listChecked)
    }
  }, [listChecked]);

  const ref = useRef(null);

  const refCheckAll = useRef(null);
  const [isOpen, setOpenConditon] = useState(false);

  useOnClickOutside(ref, () => setOpenConditon(false), 'location-item');

  function xoaDau(str: string) {
    str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, 'a');
    str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, 'e');
    str = str.replace(/ì|í|ị|ỉ|ĩ/g, 'i');
    str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, 'o');
    str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, 'u');
    str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, 'y');
    str = str.replace(/đ/g, 'd');
    str = str.replace(/À|Á|Ạ|Ả|Ã|Â|Ầ|Ấ|Ậ|Ẩ|Ẫ|Ă|Ằ|Ắ|Ặ|Ẳ|Ẵ/g, 'A');
    str = str.replace(/È|É|Ẹ|Ẻ|Ẽ|Ê|Ề|Ế|Ệ|Ể|Ễ/g, 'E');
    str = str.replace(/Ì|Í|Ị|Ỉ|Ĩ/g, 'I');
    str = str.replace(/Ò|Ó|Ọ|Ỏ|Õ|Ô|Ồ|Ố|Ộ|Ổ|Ỗ|Ơ|Ờ|Ớ|Ợ|Ở|Ỡ/g, 'O');
    str = str.replace(/Ù|Ú|Ụ|Ủ|Ũ|Ư|Ừ|Ứ|Ự|Ử|Ữ/g, 'U');
    str = str.replace(/Ỳ|Ý|Ỵ|Ỷ|Ỹ/g, 'Y');
    str = str.replace(/Đ/g, 'D');
    return str;
  }

  function handleClickCheckAll(e: any) {
    e.stopPropagation();
    refCheckAll.current.click();
  }

  const handleSearchItems = (str: string) => {
    let search = xoaDau(str.trim().toLowerCase());
    setLocationSearch(search);

    // setCondition({ ...condition, keyword: str.trim() });
  };

  const SearchLocation = () => {
    var posLocationFilter = posLocation.filter(a => xoaDau(a.label).toLowerCase().includes(locationSearch));
    return (
      <>
        {' '}
        {posLocationFilter.map((itm, i) => {
          const isItemSelected = isSelected(itm.id);

          return (
            <React.Fragment key={i}>
              <div className={`row-custom d-flex flex-row`}>
                <div>
                  <div className={`next-input-wrapper checkbox`}>
                    <label className="checkbox-button" htmlFor={`chooseCategory-${itm.id}`}>
                      <input
                        type="checkbox"
                        checked={isItemSelected}
                        onChange={e => handleRowClick(e, itm.id)}
                        name={`chooseCategory-${itm.id}`}
                        id={`chooseCategory-${itm.id}`}
                      ></input>
                      <span className="checkmark">
                        <FontAwesomeIcon icon={faCheck} size="1x" color="#08f" />
                      </span>
                    </label>
                  </div>
                </div>

                <div onClick={e => handleRowClick(e, itm.id)}>
                  <span className="location-name">{itm.label}</span>
                </div>
              </div>
            </React.Fragment>
          );
        })}
      </>
    );
  };

  return (
    <React.Fragment>
      <div className="card-install-header pb-25 pt-25">
        <h3>{props.title}</h3>
      </div>
      <div className="card-install-body d-flex flex-row align-items-center w-100 install-title">
        <span className="col-md-8">Mô tả</span>
        <span className="col-md-3 no-padding">Chi nhánh trên Omni</span>
      </div>

      <div className="card-install-body d-flex flex-row align-items-center w-100">
        <span className="col-md-8">
          Chọn danh sách chi nhánh Omni sẽ đồng bộ dữ liệu tồn kho lên Web
          <span className="icon-tooltip" data-tip data-for="config-1-1">
            <HelpIcon />
          </span>
          <ReactTooltip
            id="config-1-1"
            className="tooltip-custom"
            border
            borderColor="#E8EAEB"
            textColor="#FFF"
            backgroundColor="#182537"
            effect="solid"
            delayShow={200}
          >
            <span>
              Chọn danh sách chi nhánh của Omni sẽ ảnh hưởng đến số lượng tồn kho của sản phẩm trên Web
              <br />
              Ví dụ: Cửa hàng Sapo có 2 chi nhánh A và B. Sản phẩm X có Tồn chi nhánh A = 10, tồn chi nhánh B = 5:
              <br />
              - Nếu chọn chỉ đồng bộ chi nhánh A &gt; Sẽ đẩy tồn kho sản phẩm X lên Web là 10
              <br />
              - Nếu chọn chỉ đồng bộ chi nhánh B &gt; Sẽ đẩy tồn kho sản phẩm X lên Web là 5<br />- Nếu chọn đồng bộ cả
              hai chi nhánh A và B &gt; Sẽ đẩy tồn kho sản phẩm X lên Web là 15
            </span>
          </ReactTooltip>
        </span>
        <div className="col-md-3 no-padding">
          <div className="location-item">
            <div
              className={`list-store d-flex justify-content-between align-items-center mt-2 ${
                isOpen ? 'list-store-open' : ''
              }`}
              onClick={e => {
                setOpenConditon(!isOpen);
              }}
            >
              <span className="account-name">
                {textLocation === '' ? `${listChecked.length} Chi nhánh được chọn` : textLocation}
              </span>
              {isOpen ? <UpGray className="ml-4 icon-per" /> : <DownGray className="ml-4 icon-per" />}
            </div>

            {isOpen ? (
              <div className="d-flex align-items-center flex-column popover-list-store" ref={ref}>
                <div className="d-flex flex-column w-100">
                  <div className="search-container d-flex flex-row align-items-center">
                    <SearchIcon />
                    <input
                      type="text"
                      className="form-control form-large search-input"
                      placeholder="Tìm kiếm"
                      onChange={(e: any) => handleSearchItems(e.target.value)}
                    ></input>
                  </div>

                  <div id="table-order">
                    <div className="orders">
                      <div className="thead-order">
                        <div className="d-flex flex-row">
                          <div>
                            <div
                              className={`next-input-wrapper d-flex flex-row align-items-center checkbox ${
                                posLocation.length > 0 ? 'on-checked' : ''
                              }`}
                            >
                              <label className="checkbox-button" htmlFor={`chooseCategory`}>
                                <input
                                  ref={refCheckAll}
                                  type="checkbox"
                                  name={`chooseCategory`}
                                  id={`chooseCategory`}
                                  onChange={e => onClickCheckBoxAll(e)}
                                  checked={listChecked.length === posLocation.length}
                                ></input>
                                <span
                                  className={`checkmark ${
                                    listChecked.length > 0 && listChecked.length < posLocation.length ? 'children' : ''
                                  }`}
                                >
                                  <FontAwesomeIcon icon={faCheck} size="1x" color="#08f" />
                                </span>
                              </label>
                            </div>
                          </div>
                          <div className="location-name" onClick={e => handleClickCheckAll(e)}>
                            Toàn bộ chi nhánh
                          </div>
                        </div>
                      </div>
                      <div className="tbody-order">
                        <SearchLocation />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
      <Step1Plus
        title={'Thiết lập danh sách chi nhánh đồng bộ'}
        setPosLocation={name => props.setPosLocation(name)}
        posLocation={props.posLocation}
        setOrderLocationId={name => props.setOrderLocationId(name)}
        orderLocationId={props.orderLocationId}
        setProductLocationId={name => props.setProductLocationId(name)}
        productLocationId={props.productLocationId}
        listChecked={listChecked}
      />
    </React.Fragment>
  );
}

export default Step1;
