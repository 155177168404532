import React from 'react';
import './data-sync.scss';
import bizweb_success from '../../../assets/img/bizweb-channel-success.png';
import icon_cog from '../../../assets/img/icon-cog.png';
import icon_double_files from '../../../assets/img/icon-double-files.png';
import SWButton from '../../../shared/sw-button/SWButton';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
export function Finish(props: any) {
  const onClickConfirm = () => {
    window.location.href = '/dashboard';
  };

  return (
    <div className="card card-finish">
      <div className="flex-column d-flex align-items-center pt-5 ">
        <h2>Chúc mừng bạn đã hoàn thành cài đặt kênh đồng bộ Omni - Web</h2>
        <img
          alt="bizweb img success"
          style={{ width: '25%' }}
          className="mt-5 image-finish"
          src={'https://i.imgur.com/2yGsYkz.png'}
        ></img>
        <i className="button-title">
          Bấm vào nút dưới đây để bắt đầu quản lý đồng bộ sản phẩm, đơn hàng giữa Web và Omni
        </i>
        <SWButton className="mt-4" color="primary" onClick={() => onClickConfirm()}>
          <FontAwesomeIcon icon={faExternalLinkAlt} size="1x" className="mr-2" color="#fff" />
          Truy cập kênh website
        </SWButton>
        <h4 className="strong-finish">
          <strong>Một số thông tin bạn nên kiểm tra trước khi bán hàng</strong>
        </h4>
        <div className="flex-row d-flex align-items-center justify-content-around pt-4 w-100">
          <div className="flex-column d-flex align-items-center col-md-3 p-0">
            <img alt="icon double file" className="mt-3 icon-body" src={'https://i.imgur.com/uMNtEUt.png'}></img>
            <span className="mt-3">Kiểm tra danh sách sản phẩm đồng bộ</span>
            <a href="/products" target="_blank" rel="noreferrer" className="mt-3">
              Truy cập nhanh
            </a>
          </div>
          <div className="flex-column d-flex align-items-center col-md-3 p-0">
            <img alt="icon-cog" className="mt-3 icon-body" src={'https://i.imgur.com/YCU0hsO.png'}></img>
            <span className="mt-3">Kiểm tra cấu hình đồng bộ</span>
            <a href="/config" target="_blank" rel="noreferrer" className="mt-3">
              Truy cập nhanh
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Finish;
