import React, { useContext, useEffect, useState } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { hot } from 'react-hot-loader/root';
import 'bootstrap/scss/bootstrap.scss';
import './App.scss';
import './shared/toast/toast.scss';
import DashBoard from './components/dashboard';
import Order from './components/order/order';
import Config from './components/config';
import GettingStart from './components/install-app';
import Products from './components/product/product';
import PageErrors from './components/page-errors/page-errors';
import ConfigService from './services/config-services';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import SWButton from './shared/sw-button/SWButton';
import { IConfig } from './components/config/data';
import Layout from './Layout';
import auth from './auth';
import urlUtils from './core/urlUtils';

export interface IAppCtx {
  config: IConfig;
  loading: boolean;
}

export const AppContext = React.createContext<IAppCtx>(undefined);

function Page({ title, component: Component, ...rest }) {
  const appCtx = useContext<IAppCtx>(AppContext);
  if (appCtx.loading) {
    return <Layout>loading...</Layout>;
  }
  if (appCtx.config.sync_status_value === 'wait_config') {
    return <Redirect to="/getting-start" />;
  }
  return (
    <Route
      {...rest}
      render={props => (
        <React.Fragment>
          <div className="title-bar">{title}</div>
          <Component {...props} />
        </React.Fragment>
      )}
    />
  );
}

const App = () => {
  const [ctx, setCtx] = useState<IAppCtx>({
    config: {
      location_ids: [],
      order_location_mappings: []
    },
    loading: true
  });
  const [isOpenManualPopup, setOpenManualPopup] = useState(false);

  useEffect(() => {
    SapoApp.ready(() => {
      SapoApp.Bar.initialize({
        icon: 'https://bizweb.dktcdn.net/dev/105/299/218/themes/518444/assets/vector.png?1614392528411'
      });
    });
    ConfigService.getConfig().subscribe(
      data => {
        setCtx({
          config: data,
          loading: false
        });
        const invalidFields = new Set(data.invalid_field);
        if (
          invalidFields.has('order_location_id') ||
          invalidFields.has('product_location_id') ||
          invalidFields.has('location_ids')
        ) {
          setOpenManualPopup(true);
        }
      },
      err => {
        if (err.response && err.response.status === 406) {
          const authStore = auth.get();
          auth.clear();
          if (authStore && authStore.storeAlias) {
            window.parent.location.href = `${urlUtils.resolvePosDomain(authStore.storeAlias)}/admin/settings/channels`;
          }
        } else {
          console.log('err', err);
        }
      }
    );
  }, []);

  const closeModalAction = () => {
    setTimeout(() => {}, 500);
    setOpenManualPopup(false);
  };

  return (
    <AppContext.Provider value={ctx}>
      <Layout>
        <Switch>
          <Page exact path="/" title="Tổng quan" component={DashBoard} />
          <Page path={['/home', '/dashboard']} title="Tổng quan" component={DashBoard} />
          <Page path="/products" title="Sản phẩm" component={Products} />
          <Page path="/orders" title="Đơn hàng" component={Order} />
          <Page path="/config" title="Cấu hình" component={Config} />
          <Page path="/errors" title="Danh sách lỗi" component={PageErrors} />
          <Route
            path="/getting-start"
            render={props =>
              ctx.config.sync_status_value !== 'wait_config' ? (
                <Redirect to="/dashboard" />
              ) : (
                <GettingStart {...props} />
              )
            }
          />
          <Route>404 page not found</Route>
        </Switch>
        <Modal isOpen={isOpenManualPopup} className="modal-confirm" id="modal-action-config" autoFocus={false}>
          <ModalHeader toggle={closeModalAction} className="modal--header">
            Chi nhánh đồng bộ bạn chọn đã bị khóa
          </ModalHeader>
          <ModalBody className="modal--body border-bottom">
            <div className="flex-column d-flex align-items-center card-config-popup-body">
              <span>
                Dữ liệu sản phẩm và đơn hàng đang bị lỗi đồng bộ do chi nhánh lựa chọn đã bị khóa.Vui lòng truy cập Cấu
                hình kênh để chọn chi nhánh đang hoạt động khác
              </span>
              <span className="mt-3">
                Lưu ý: Sau khi thay đổi cấu hình chi nhánh vui lòng thực hiện thao tác <b>“Đồng bộ lại”</b> những sản
                phẩm và đơn hàng bị lỗi trước đó.
              </span>
            </div>
          </ModalBody>
          <ModalFooter className="modal--footer">
            <SWButton onClick={closeModalAction}>Hủy</SWButton>
            <SWButton
              color="primary"
              onClick={() => {
                setOpenManualPopup(false);
                if (!window.location.pathname.startsWith('/config')) {
                  window.location.href = '/config';
                }
              }}
            >
              Cấu hình kênh
            </SWButton>
          </ModalFooter>
        </Modal>
      </Layout>
    </AppContext.Provider>
  );
};

export default hot(App);
