import { AxiosResponse } from 'axios';
import { from, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IDashBoard } from '../../components/dashboard/data';
import confAxios from '../../core/config/conf-axios';

const DashBoardService = {
  getDashBoard: (): Observable<IDashBoard> => {
    const request = confAxios.get<IDashBoard>(`/sync/dashboard`);
    return from(request).pipe(
      map((res: AxiosResponse<IDashBoard>) => {
        return {
          ...res.data
        };
      })
    );
  },
  deleteStore: (): Observable<any> => {
    const request = confAxios.post<any>(`/store/uninstall`);
    return from(request).pipe(
      map((res: AxiosResponse<any>) => {
        return {
          ...res.data,
          status: res.status
        };
      })
    );
  }
};

export default DashBoardService;
