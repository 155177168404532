import React from 'react';

export const CardHeader = ({ title }) => (
  <div
    style={{
      paddingLeft: '24px',
      paddingRight: '24px',
      paddingTop: '10px',
      fontStyle: 'normal',
      fontWeight: '500',
      fontSize: '16px',
      lineHeight: '20px',
      color: '#182537'
    }}
  >
    <span>{title}</span>
  </div>
);

export const CardBody = ({ children }) => (
  <div
    style={{
      paddingTop: '1.5rem',
      paddingLeft: '2.4rem',
      paddingRight: '2.4rem'
    }}
  >
    {children}
  </div>
);

export const Card = ({ children, ...rest }) => (
  <div
    style={{
      width: '100%',
      float: 'left',
      background: '#FFFFFF',
      borderRadius: '3px',
      marginBottom: '20px',
      paddingBottom: '2rem'
    }}
    {...rest}
  >
    {children}
  </div>
);
