import React from 'react';

const Checkbox = ({ label, onLabelClick = undefined, style, ...rest }, ref) => (
  <label
    style={{
      marginBottom: 0
    }}
  >
    <input
      type="checkbox"
      {...rest}
      ref={ref}
      style={{
        ...style,
        cursor: 'pointer'
      }}
    />
    <span style={{ marginLeft: '4px', cursor: 'pointer' }} onClick={onLabelClick}>
      {label}
    </span>
  </label>
);

export default React.forwardRef(Checkbox);
