import React, { useEffect, useState } from 'react';
import './data-sync.scss';
import NextIcon from '../../../assets/img/icons/right.svg';
import SWButton from '../../../shared/sw-button/SWButton';
import ConfigService from '../../../services/config-services';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import urlUtils from '../../../core/urlUtils';

export function Step0(props: any) {
  const [postConfig, setPostConfig] = useState<string>('default');

  useEffect(() => {
    if (typeof props.postConfig !== 'undefined') {
      setPostConfig(props.postConfig);
    }
  }, [props]);

  const onClickConfirm = () => {
    if (postConfig === 'manual') {
      props.setIsFinish(false);
      props.setIsIntro(false);
    }
    if (postConfig === 'default') {
      ConfigService.postDefaultConfig().subscribe(data => {
        if (data) {
          if (data.status === 200) {
            props.setIsIntro(false);
            props.setIsFinish(true);
          }
        }
      });
    }
  };

  const onClickCancel = () => {
    const appStoreAlias = localStorage.getItem('appStoreAlias');
    if (appStoreAlias) {
      window.parent.location.href = `${urlUtils.resolvePosDomain(appStoreAlias)}/admin/settings/channels`;
    }
  };

  const handleClick = (check: string) => {
    setPostConfig(check);
  };
  const [isOpenManualPopup, setOpenManualPopup] = useState(false);
  const closeModalAction = () => {
    setTimeout(() => {}, 500);
    setOpenManualPopup(false);
  };
  const [isOpenDefaultConfig, setOpenDefaultConfig] = useState(false);
  return (
    <div className="card">
      <div className="card-install-header pb-25 pt-25">
        <h3>{props.title}</h3>
      </div>
      <div className="card-install-body">
        <span className="mt-4 title-radio"></span>

        <div className="next-input-wrapper mt-3">
          <label className="radio-button" htmlFor="status1">
            <input
              type="radio"
              name="radioStatus1"
              id="status1"
              value={'default'}
              checked={postConfig === 'default'}
              onChange={e => handleClick('default')}
            ></input>
          </label>
          <span onClick={e => handleClick('default')} className="switch-text cursor-text">
            Cấu hình mặc định,
          </span>
          <a className="ml-2" style={{ cursor: 'pointer'}} onClick={() => setOpenDefaultConfig(!isOpenDefaultConfig)}>
            xem chi tiết
          </a>
        </div>
        <div className="next-input-wrapper mt-2">
          <label className="radio-button" htmlFor="status2">
            <input
              type="radio"
              name="radioStatus2"
              id="status2"
              value={'manual'}
              checked={postConfig === 'manual'}
              onChange={e => handleClick('manual')}
            ></input>
          </label>
          <span onClick={e => handleClick('manual')} className="switch-text cursor-text">
            Tự thiết lập cấu hình
          </span>
        </div>
      </div>
      <div className="card-install-body d-flex flex-row align-items-center w-100">
        <SWButton className="ml-2 button-sub" onClick={() => setOpenManualPopup(true)}>
          Hủy
        </SWButton>
        <SWButton className="ml-2" color="primary" onClick={() => onClickConfirm()}>
          Xác nhận
        </SWButton>
      </div>
      <div className="card-install-footer">
        <h4 className="support-title">Trang hỗ trợ</h4>

        <a
          className="d-flex flex-row align-items-center"
          href="https://support.sapo.vn/huong-dan-cai-dat-kenh-ban-hang-tren-website"
          target="_blank"
          rel="noreferrer"
        >
          <NextIcon className="mr-3 icon-per" />
          Hướng dẫn cách cài đặt kênh Web
        </a>
      </div>
      <Modal
        isOpen={isOpenManualPopup}
        //toggle={closeModalAction}
        className="modal-confirm"
        id="modal-action-config"
        autoFocus={false}
      >
        <ModalHeader toggle={closeModalAction} className="modal--header">
          Hủy thiết lập site Web
        </ModalHeader>
        <ModalBody className="modal--body border-bottom">
          <span>
            Thao tác này sẽ hủy các cấu hình mà bạn đã lựa chọn. Bạn có chắc muốn hủy các thiết lập này không?
          </span>
        </ModalBody>
        <ModalFooter className="modal--footer">
          <SWButton onClick={closeModalAction}>Thoát</SWButton>
          <SWButton
            color="destroy"
            onClick={() => {
              setOpenManualPopup(false);
              onClickCancel();
            }}
          >
            Xác nhận
          </SWButton>
        </ModalFooter>
      </Modal>
      <Modal
        isOpen={isOpenDefaultConfig}
        //toggle={closeModalAction}
        className="modal-default-config"
        autoFocus={false}
      >
        <ModalHeader toggle={() => setOpenDefaultConfig(!isOpenDefaultConfig)} className="modal--header">
          Thông tin cấu hình mặc định
        </ModalHeader>
        <ModalBody className="modal--body">
          <table>
            <thead>
              <tr>
                <th>Tên cấu hình</th>
                <th>Lựa chọn</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Chi nhánh Omni đồng bộ dữ liệu tồn kho lên Web</td>
                <td>Chi nhánh mặc định</td>
              </tr>
              <tr>
                <td>Chi nhánh Omni nhận dữ liệu sản phẩm tạo mới từ Web</td>
                <td>Chi nhánh mặc định</td>
              </tr>
              <tr>
                <td>Chi nhánh Omni nhận dữ liệu đơn hàng từ Web</td>
                <td>Chi nhánh mặc định</td>
              </tr>
              <tr>
                <td>Giá đồng bộ với giá bán trên Web</td>
                <td>Giá bán lẻ</td>
              </tr>
              <tr>
                <td>Giá đồng bộ với giá so sánh trên Web</td>
                <td>Không so sánh giá</td>
              </tr>
              <tr>
                <td>Đồng bộ sản phẩm hiện có trên Web về Omni</td>
                <td>Có đồng bộ</td>
              </tr>
              <tr>
                <td>Đồng bộ sản phẩm hiện có từ Omni về Web</td>
                <td>Có đồng bộ</td>
              </tr>
              <tr>
                <td>Tiêu chí định danh để đồng bộ thông tin khách hàng</td>
                <td>Số điện thoại</td>
              </tr>
            </tbody>
          </table>
        </ModalBody>
        <ModalFooter className="modal--footer">
          <SWButton onClick={() => setOpenDefaultConfig(!isOpenDefaultConfig)}>Đóng</SWButton>
        </ModalFooter>
      </Modal>
    </div>
  );
}

export default Step0;
