import { IItem } from '../../shared/model/base-data';

export const conditionSource: IItem[] = [
  {
    key: 'pos',
    value: 'Sản phẩm Omni'
  },
  {
    key: 'web',
    value: 'Sản phẩm Web'
  }
];

export const navModel: IItem[] = [
  {
    key: '',
    value: 'Tất cả sản phẩm'
  },
  {
    key: 'connected',
    value: 'Đã liên kết'
  },
  {
    key: 'unlink',
    value: 'Chưa liên kết'
  },
  {
    key: 'error',
    value: 'Lỗi liên kết'
  },
  {
    key: 'stopped',
    value: 'Ngắt đồng bộ'
  }
];

export const filterConditionModel: IItem[] = [
  {
    key: '',
    value: 'Tất cả sản phẩm'
  },
  {
    key: 'connected',
    value: 'Sản phẩm đã liên kết'
  },
  {
    key: 'unlink',
    value: 'Sản phẩm chưa liên kết'
  },
  {
    key: 'error',
    value: 'Sản phẩm lỗi đồng bộ'
  },
  {
    key: 'stopped',
    value: 'Sản phẩm ngắt đồng bộ'
  }
];

export const navModelActionProduct: IItem[] = [
  {
    key: 're_sync',
    value: 'Đồng bộ dữ liệu ngay'
  },
  {
    key: 're_connect',
    value: 'Bật đồng bộ'
  },
  {
    key: 'stop_connect',
    value: 'Ngắt đồng bộ'
  },
  {
    key: 'remove_connect',
    value: 'Hủy liên kết'
  }
];
