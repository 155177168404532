import 'react-hot-loader';
import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import qs from 'query-string';
import App from './App';
import Layout from './Layout';

import auth from './auth';
import urlUtils from './core/urlUtils';

function Login(props) {
  const tokens = qs.parse(props.location.search);
  if (!tokens || !tokens.token || !tokens.appStoreAlias || !tokens.appStoreId) {
    return <Layout message="invalid token response" />;
  }
  auth.set({
    token: tokens.token as string,
    storeAlias: tokens.appStoreAlias as string,
    storeId: tokens.appStoreId as string
  });
  return <Redirect to="/dashboard" />;
}

function AuthRoute(props) {
  const currentAuth = auth.get();
  const tokens = qs.parse(props.location.search);
  const domain = tokens && (tokens.tenant as string);
  const storeAlias = domain && domain.substring(0, domain.indexOf('.'));
  const embbed = window.self !== window.top;
  const [appInit, setAppInit] = useState<boolean>(false);

  if (!currentAuth || (storeAlias && storeAlias !== currentAuth.storeAlias)) {
    if (embbed) {
      window.location.href = '/api/install/pos-callback' + props.location.search;
    }
    return <Layout message="Vui lòng cài đặt lại kênh" />;
  }
  if (!appInit && (!DEBUG.enable || DEBUG.embeddedMode)) {
    SapoApp.init({
      apiKey: APP_KEY,
      shopOrigin: urlUtils.resolvePosDomain(currentAuth.storeAlias)
    });
    SapoApp.ready(() => {
      const params = new URL(window.location.href).pathname.split('/');
      const route = params[params.length - 1];
      let dashboard = route === '' || route === 'Sync';
      SapoApp.Menu.expand();
      SapoApp.Menu.initialize({
        title: 'web',
        icon: 'https://sapobizsynchronize.bizwebapps.vn/Content/Images/icon_60_60_sapo.png',
        label:
          '<img src="https://bizweb-sync.sapo.vn/Content/Images/icon-bw.png" style="width: 17px; margin-right: 5px;margin-bottom: -3px;"> Web',
        expand: true,
        links: [
          {
            label: 'Quản trị Website',
            message: 'Quản trị Website',
            external_link: true,
            href: `https://${currentAuth.storeAlias}.mysapo.net/admin`,
            target: 'channel'
          },
          {
            label: 'Báo cáo đồng bộ',
            message: 'Báo cáo đồng bộ',
            active:
              window.location.href.includes('dashboard') ||
              window.location.href.includes('Sync') ||
              (dashboard &&
                !window.location.href.includes('orders') &&
                !window.location.href.includes('products') &&
                !window.location.href.includes('config') &&
                !window.location.href.includes('log-errors')),
            href: '/dashboard',
            callback: function () {
              window.location.href = '/dashboard';
            }
          },
          {
            label: 'Sản phẩm',
            message: 'Sản phẩm',
            active: window.location.href.includes('products'),
            href: '/products',
            callback: function () {
              window.location.href = '/products';
            }
          },
          {
            label: 'Đơn hàng',
            message: 'Đơn hàng',
            active: window.location.href.includes('orders'),
            href: '/orders',
            callback: function () {
              window.location.href = '/orders';
            }
          },
          {
            label: 'Cấu hình',
            message: 'Cấu hình',
            active: window.location.href.includes('config'),
            href: '/config',
            callback: function () {
              window.location.href = '/config';
            }
          },
          {
            label: 'Danh sách lỗi',
            message: 'Danh sách lỗi',
            active: window.location.href.includes('errors'),
            href: '/errors',
            callback: function () {
              window.location.href = '/errors';
            }
          }
        ]
      });
    });
    setAppInit(true);
  }
  return <Route {...props} />;
}

ReactDOM.render(
  <Router>
    <Switch>
      <Route path="/login" component={Login} />
      <AuthRoute component={App} />
    </Switch>
  </Router>,
  document.querySelector('#root')
);
