import React, { useState } from 'react';
import LocationModal from './LocationModal';
import { Button, Table } from 'reactstrap';
import Checkbox from 'components/Checkbox';
import Tip from 'components/Tip';
import { TLocationMapping } from './data';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle, faPen, faTrash } from '@fortawesome/free-solid-svg-icons';

export default ({
  enableMapping,
  toggleEnableMapping,
  locations,
  mappings,
  setMappings
}: {
  enableMapping: boolean;
  toggleEnableMapping: () => any;
  mappings: TLocationMapping[];
  setMappings: (m: TLocationMapping[]) => any;
  locations: {
    id: number;
    label: string;
  }[];
}) => {
  const [state, setState] = useState({
    isOpen: false,
    mappingIdx: -1
  });

  const editMapping = (idx: number) => {
    setState({
      isOpen: true,
      mappingIdx: idx
    });
  };

  const addMapping = () => {
    setState({
      isOpen: true,
      mappingIdx: -1
    });
  };

  const closeModal = () => {
    setState({
      isOpen: false,
      mappingIdx: -1
    });
  };

  return (
    <div>
      <Checkbox
        checked={enableMapping}
        onChange={toggleEnableMapping}
        label="Cho phép đồng bộ đơn hàng về nhiều chi nhánh theo điều kiện về khu vực giao hàng"
      />
      <Tip
        title="Lựa chọn chi nhánh khi đẩy đơn về Omni"
        descriptions={[
          'Thiết lập khu vực giao hàng tương ứng với từng chi nhánh, đơn hàng sẽ được phân loại và gửi về chi nhánh dựa theo địa chỉ giao hàng của đơn hàng Web',
          'Lưu ý: Các đơn hàng có khu vực vận chuyển khác các khu vực thiết lập sẽ được đồng bộ về chi nhánh mặc định'
        ]}
      />
      {enableMapping ? (
        <>
          <Table style={{ marginTop: '1em' }}>
            <thead>
              <tr>
                <th>Khu vực</th>
                <th>Chi nhánh</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {mappings.map((m, i) => (
                <tr key={i}>
                  <td>{m.title}</td>
                  <td>{locations.find(l => l.id === m.location_id).label}</td>
                  <td>
                    <Button color="primary" outline onClick={() => editMapping(i)}>
                      <FontAwesomeIcon icon={faPen} />
                    </Button>{' '}
                    <Button
                      color="danger"
                      outline
                      onClick={() => setMappings([...mappings.slice(0, i), ...mappings.slice(i + 1)])}
                    >
                      <FontAwesomeIcon icon={faTrash} />
                    </Button>
                  </td>
                </tr>
              ))}
              <tr>
                <td colSpan={3}>
                  <Button color="link" onClick={addMapping} style={{ fontSize: '1em' }}>
                    <FontAwesomeIcon icon={faPlusCircle} style={{ marginRight: '5px' }} />
                    Thêm liên kết
                  </Button>
                </td>
              </tr>
            </tbody>
          </Table>
          {state.isOpen ? (
            <LocationModal
              mappingIdx={state.mappingIdx}
              mappings={mappings}
              locations={locations}
              closeModal={closeModal}
              onMappingChange={mapping => {
                closeModal();
                if (state.mappingIdx >= 0) {
                  return setMappings([
                    ...mappings.slice(0, state.mappingIdx),
                    mapping,
                    ...mappings.slice(state.mappingIdx + 1)
                  ]);
                }
                return setMappings([...mappings, mapping]);
              }}
            />
          ) : null}
        </>
      ) : null}
    </div>
  );
};
