import { useEffect, useCallback } from 'react';

export const useOnClickOutside = (ref, handler, clas: string) => {
  useEffect(() => {
    const listener = event => {
      var path = event.path || (event.composedPath && event.composedPath());
      if (
        !ref.current ||
        ref.current.contains(event.target) ||
        path.filter(i => i.className === clas).length !== 0 ||
        path.filter(i => i.className === 'modal fade show').length !== 0
      ) {
        return;
      }

      handler(event);
    };

    document.addEventListener('mousedown', listener);
    document.addEventListener('touchstart', listener);

    return () => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
    };
  }, [ref, handler]);
};

export const useDebouncedEffect = (effect, delay, deps) => {
  const callback = useCallback(effect, deps);

  useEffect(() => {
    const handler = setTimeout(() => {
      callback();
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [callback, delay]);
};
