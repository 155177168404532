import React, { useEffect, useRef, useState } from 'react';
import './data-sync.scss';
import DownGray from '../../../assets/img/icons/down_gray.svg';
import UpGray from '../../../assets/img/icons/up_gray.svg';
import ReactTooltip from 'react-tooltip';
import HelpIcon from '../../../assets/img/icons/help-silver.svg';
import { useOnClickOutside } from '../../../shared/util/function';

export function Step1Plus(props: any) {
  const ref1 = useRef(null);
  const ref2 = useRef(null);

  const [isOpenProduct, setOpenProduct] = useState(false);
  const [isOpenOrder, setOpenOrder] = useState(false);

  useOnClickOutside(ref1, () => setOpenProduct(false), 'product-item');
  useOnClickOutside(ref2, () => setOpenOrder(false), 'order-item');

  const [orderLocationId, setOrderLocationId] = useState(null);
  const [productLocationId, setProductLocationId] = useState(null);
  const [location, setLocation] = useState([]);
  const [posLocation, setPosLocation] = useState([]);
  const [posLocationAfter, setPosLocationAfter] = useState([]);
  const [orderLocation, setOrderLocation] = useState(null);
  const [productLocation, setProductLocation] = useState(null);

  useEffect(() => {
    if (typeof props.posLocation !== 'undefined') {
      setPosLocation(props.posLocation);

      if (typeof props.orderLocationId !== 'undefined') {
        setOrderLocationId(props.orderLocationId);
        var resultOrderLocation = props.posLocation.filter(obj => {
          return obj.id === props.orderLocationId;
        });
        if (resultOrderLocation.length > 0) {
          var resultOrderLocationAfter = posLocationAfter.filter(obj => {
            return obj.id === props.orderLocationId;
          });

          if (resultOrderLocationAfter.length > 0 || posLocationAfter.length === 0) {
            setOrderLocation(resultOrderLocation[0]);
            props.setOrderLocationId(resultOrderLocation[0].id);
          } else {
            setOrderLocation(null);
            props.setOrderLocationId('');
          }
        }
        if (resultOrderLocation.length === 0 && props.posLocation.length > 0 && posLocationAfter.length === 0) {
          setOrderLocation(props.posLocation[0]);
          props.setOrderLocationId(props.posLocation[0].id);
        }
      }

      if (typeof props.productLocationId !== 'undefined') {
        setProductLocationId(props.productLocationId);
        var result = props.posLocation.filter(obj => {
          return obj.id === props.productLocationId;
        });
        if (result.length > 0) {
          var resultProductLocationAfter = posLocationAfter.filter(obj => {
            return obj.id === props.productLocationId;
          });
          if (resultProductLocationAfter.length > 0 || posLocationAfter.length === 0) {
            setProductLocation(result[0]);
            props.setProductLocationId(result[0].id);
          } else {
            setProductLocation(null);
            props.setProductLocationId('');
          }
        }
        if (result.length === 0 && props.posLocation.length > 0 && posLocationAfter.length === 0) {
          setProductLocation(props.posLocation[0]);
          props.setProductLocation(props.posLocation[0].id);
        }
      }
      if (typeof props.listChecked !== 'undefined' && props.listChecked.length) {
        var locationAfter = props.posLocation.filter(function (obj) {
          return props.listChecked.some(function (obj2) {
            return obj.id === obj2;
          });
        });
        setPosLocationAfter(locationAfter);
      }
    }
  }, [props]);

  function handleSetProduct(id: any) {
    props.setProductLocationId(id);
    setOpenProduct(false);
  }

  function handleSetOrder(id: any) {
    props.setOrderLocationId(id);
    setOpenOrder(false);
  }

  return (
    <React.Fragment>
      <div className="card-install-body d-flex flex-row align-items-center w-100">
        <span className="col-md-8">
          Chọn chi nhánh Omni sẽ nhận dữ liệu sản phẩm tạo mới từ Web
          <span className="icon-tooltip" data-tip data-for="config-1-2">
            <HelpIcon />
          </span>
          <ReactTooltip
            id="config-1-2"
            className="tooltip-custom"
            border
            borderColor="#E8EAEB"
            textColor="#FFF"
            backgroundColor="#182537"
            effect="solid"
            delayShow={200}
          >
            <span>Sản phẩm tạo mới trên Web sẽ đẩy tồn kho về chi nhánh Omni được chọn.</span>
          </ReactTooltip>
        </span>
        <div className="col-md-3 no-padding">
          <div className="product-item">
            <div
              className={`list-store d-flex justify-content-between align-items-center mt-2 ${isOpenProduct ? 'list-store-open' : ''
                }`}
              onClick={e => {
                setOpenProduct(!isOpenProduct);
              }}
            >
              <span className="account-name">
                {productLocation != null && productLocation !== '' ? productLocation.label : 'Chưa chọn chi nhánh'}
              </span>
              {isOpenProduct ? <UpGray className="ml-1 icon-per" /> : <DownGray className="ml-1 icon-per" />}
            </div>

            {isOpenProduct ? (
              <div className={`d-flex align-items-center flex-column popover-list-store`} ref={ref1}>
                <div className="d-flex flex-column w-100">
                  {posLocationAfter.map((itm, index) => (
                    <div
                      key={index}
                      className="border-top select-store d-flex align-items-center"
                      onClick={() => handleSetProduct(itm.id)}
                    >
                      {itm.label}
                    </div>
                  ))}
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
      <div className="card-install-body d-flex flex-row align-items-center w-100">
        <span className="col-md-8">
          Chọn chi nhánh Omni sẽ nhận dữ liệu đơn hàng từ Web
          <span className="icon-tooltip" data-tip data-for="config-1-3">
            <HelpIcon />
          </span>
          <ReactTooltip
            id="config-1-3"
            className="tooltip-custom"
            border
            borderColor="#E8EAEB"
            textColor="#FFF"
            backgroundColor="#182537"
            effect="solid"
            delayShow={200}
          >
            <span>Đơn hàng trên Web sẽ được đồng bộ về chi nhánh được chọn</span>
          </ReactTooltip>
        </span>
        <div className="col-md-3 no-padding">
          <div className="order-item">
            <div
              className={`list-store d-flex justify-content-between align-items-center mt-2 ${isOpenOrder ? 'list-store-open' : ''
                }`}
              onClick={e => {
                setOpenOrder(!isOpenOrder);
              }}
            >
              <span className="account-name">
                {orderLocation != null && orderLocation !== '' ? orderLocation.label : 'Chưa chọn chi nhánh'}
              </span>
              {isOpenOrder ? <UpGray className="ml-1 icon-per" /> : <DownGray className="ml-1 icon-per" />}
            </div>

            {isOpenOrder ? (
              <div className={`d-flex align-items-center flex-column popover-list-store`} ref={ref2}>
                <div className="d-flex flex-column w-100">
                  {posLocationAfter.map((itm, index) => (
                    <div
                      key={index}
                      className="border-top select-store d-flex align-items-center"
                      onClick={() => handleSetOrder(itm.id)}
                    >
                      {itm.label}
                    </div>
                  ))}
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default Step1Plus;
