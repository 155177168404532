import React, { useEffect, useRef, useState } from 'react';
import NoData from '../../../shared/no-data/no-data';
import SWButton from '../../../shared/sw-button/SWButton';
import SWebPagination from '../../../shared/sw-pagination/sweb-pagination';
import './table.scss';
import CaretDownIcon from '../../../assets/img/icons/caret_down_icon.svg';
import { itemPerPageModel } from '../../order/data';
import { IDataError } from '../../../shared/model/page-error';
import { formatDate } from '../../../shared/util/number-format';
import PageError from '../../../services/page-error/page-error';
import { useDebouncedEffect, useOnClickOutside } from '../../../shared/util/function';

interface TablePageErrorProps {
  keyword: string;
  filter: string;
}

export default function TablePageError(props: TablePageErrorProps) {
  const { keyword, filter } = props;
  const ref2 = useRef();
  useOnClickOutside(ref2, () => setOpenItemPerPage(false), 'item-per-page');
  const itemPerPages = itemPerPageModel;

  //pagination
  const [page, setPage] = useState<number>(1);
  const [totalPage, setToltalPage] = useState<number>(0);
  const [itemPerPage, setItemPerPage] = useState<number>(20);
  const [totalElements, settotalElements] = useState<number>(0);

  const [loading, setLoading] = useState<boolean>(true);
  const [openItemPerPage, setOpenItemPerPage] = useState<boolean>(false);
  const [data, setData] = useState<IDataError[]>([]);

  const getData = (keyword?: string, filter?: string) => {
    PageError.getListError(itemPerPage, page - 1, keyword ? keyword : null, filter ? filter : null).subscribe(re => {
      setToltalPage(re.total_pages);
      settotalElements(re.total_elements);
      setData(re.content);
      setLoading(false);
    });
  };

  useEffect(() => {
    setPage(1);
    getData(keyword, filter);
  }, [itemPerPage, filter]);

  useEffect(() => {
    setPage(1);
  }, [keyword]);

  useEffect(() => {
    window.scrollTo(0, 0);
    getData(keyword, filter);
  }, [page]);

  useDebouncedEffect(
    () => {
      setPage(1);
      getData(keyword, filter);
    },
    300,
    [keyword]
  );
  const handleClickChoosePage = (num: number) => {
    setItemPerPage(num);
    setOpenItemPerPage(false);
  };

  const switchType = (str: string) => {
    switch (str) {
      case 'order':
        return 'Đơn hàng';
      case 'variant':
        return 'Sản phẩm';
      default:
        return str;
    }
  };

  return (
    <>
      {loading ? (
        ''
      ) : (
        <>
          {data.length ? (
            <div id="sw-table-error">
              <div id="table-order">
                <table className="orders">
                  <thead className="thead-order">
                    <tr>
                      <th style={{ minWidth: 150 }}>Thời gian</th>
                      <th>Đối tượng</th>
                      <th style={{ minWidth: 500 }}>Chi tiết lỗi</th>
                    </tr>
                  </thead>
                  <tbody className="tbody-order">
                    {data.map((error, index) => {
                      return (
                        <tr key={index} className={`row-custom`}>
                          <td>{formatDate(error.created_on)}</td>
                          <td>
                            {switchType(error.target_type)}{' '}
                            <a href={error.original_url} rel="noopener noreferrer" target="_blank" className="_08F">
                              {error.target_name}
                            </a>
                          </td>
                          <td>{error.message}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
              <div className="pagination">
                <div>
                  Từ {page > 1 ? (page - 1) * itemPerPage : 1} đến{' '}
                  {page * itemPerPage < totalElements ? page * itemPerPage : totalElements} trên tổng {totalElements}
                </div>
                <div className="d-flex align-items-center">
                  Hiển thị
                  <div className="item-per-page">
                    <SWButton onClick={() => setOpenItemPerPage(() => !openItemPerPage)}>
                      {itemPerPage}
                      <CaretDownIcon className="icon-drop" />
                    </SWButton>
                    {openItemPerPage ? (
                      <div className="popover-filter" ref={ref2}>
                        {itemPerPages.map((itemPage, index) => (
                          <div
                            key={index}
                            onClick={e => handleClickChoosePage(itemPage)}
                            className={`${itemPerPage === itemPage ? 'active-item-per-page' : ''}`}
                          >
                            {itemPage}
                          </div>
                        ))}
                      </div>
                    ) : (
                      ''
                    )}
                  </div>
                  kết quả
                </div>
                <div>
                  <SWebPagination page={page} setPage={setPage} totalPage={totalPage} />
                </div>
              </div>
            </div>
          ) : (
            <NoData
              title="Không tìm thấy lỗi phù hợp với điều kiện tìm kiếm"
              content="Thử thay đổi điều kiện lọc hoặc từ khóa tìm kiếm"
            />
          )}
        </>
      )}
    </>
  );
}
