import React from 'react';
import { FormGroup, Label, Input } from 'reactstrap';
import { Card, CardHeader, CardBody } from 'components/Card';
import Tip from 'components/Tip';

export default ({ config, onConfigChange }: { config: string; onConfigChange: (v: string) => any }) => {
  return (
    <Card>
      <CardHeader title="Phương thức đồng bộ lần đầu các sản phẩm chung mã SKU trên Omni & Web" />
      <CardBody>
        <FormGroup>
          <FormGroup check>
            <Label check style={{ cursor: 'pointer' }}>
              <Input
                type="radio"
                name="syncType"
                value="web"
                checked={config === 'web'}
                onChange={e => onConfigChange(e.target.value)}
                style={{ cursor: 'pointer' }}
              />
              &nbsp;Lấy thông tin sản phẩm Web làm chuẩn đồng bộ về Omni
              <Tip
                title="Dữ liệu khi liên kết"
                description="Sẽ đẩy toàn bộ các thông tin sản phẩm từ Web về Omni trừ thông tin tồn kho."
              />
            </Label>
          </FormGroup>
          <FormGroup check>
            <Label check style={{ cursor: 'pointer' }}>
              <Input
                type="radio"
                name="syncType"
                value="pos"
                checked={config === 'pos'}
                onChange={e => onConfigChange(e.target.value)}
                style={{ cursor: 'pointer' }}
              />
              &nbsp;Lấy thông tin sản phẩm Omni làm chuẩn đồng bộ lên Web
              <Tip title="Dữ liệu khi liên kết" description="Sẽ đẩy toàn bộ các thông tin sản phẩm từ Omni lên Web." />
            </Label>
          </FormGroup>
          <FormGroup check>
            <Label check style={{ cursor: 'pointer' }}>
              <Input
                type="radio"
                name="syncType"
                value="none"
                checked={config === 'none'}
                onChange={e => onConfigChange(e.target.value)}
                style={{ cursor: 'pointer' }}
              />
              &nbsp;Không đồng bộ thông tin, chỉ liên kết và đẩy tồn kho từ Omni lên Web
              <Tip
                title="Dữ liệu khi liên kết"
                description="Không đồng bộ thông tin giữa hai hệ thống, chỉ liên kết sản phẩm và đẩy tồn kho từ Omni lên Web. Khi một trong hai bên cập nhật thông tin sẽ sang bên còn lại."
              />
            </Label>
          </FormGroup>
        </FormGroup>
      </CardBody>
    </Card>
  );
};
